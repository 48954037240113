import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import useStyles from "./ArItemMasterStyle";
import Button from "@material-ui/core/Button";
import MasterDialogstorageDetill from "./ArItemMasterDialogstorageDetill";
import { modifyData, addData, deleteData, inputCheck, inputCheck1 } from "./ArItemMasterMethod";
import { dialogText1, ArItemMasterConst } from "./ArItemMasterConst";
import API, { graphqlOperation } from "@aws-amplify/api";
import { customerByeMail, } from "../../../graphql/queries";
import { Auth } from "aws-amplify"; import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Autocomplete from '@mui/material/Autocomplete';
import { listArItemMasters} from "../../../graphql/queries";
import TextField from '@mui/material/TextField';
export default function masterDilogDetill(param) {
    const classes = useStyles()
    const [disabledBtn, setDisabledBtn] = useState({
        Btn1: false,
        Btn2: false
    })
    const [dialogstorageDetill, setdialogstorageDetill] = useState(false)
    const [searchProgress, setSearchProgress] = useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [suppCodeList, setSuppCodeList] = useState([])
    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };
    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };
    const handleConfirmOK = async () => {
        setOpenConfirm(false);
    }
    // 品目保管場所詳細画面開けて下さい
    const openDialogstorageDetill = function () {
        setdialogstorageDetill(true)
    }
    // 品目保管場所詳細画面シャットダウン
    const closeDialogstorageDetill = function () {
        setdialogstorageDetill(false);
        param.closeDilogDetill()
    }
    const [dialogText, setDialogText] = useState(dialogText1.dialogText)
    const [isValid, setIsValid] = useState({
        SAPCODE: false,
        GMID: false,
        ItemText: false,
        ARBSuppAgencyCode: false,
        SuppCode: false,
        SuppName: false,
        ItemGrp: false,
        ItemGrpName: false,
        InventoryTier: false,
        OrderAdjustment: false,
        Capacity: false,
        CapacityUnit: false,
        BaseNumUnit: false,
        OrderUnitpriceUnit: false,
        UnitPerOrder: false,
        StorageLoc: false,
        ChilledGoods: false,
        USproduct: false,
        Expiration: false,
        CustomerMOQ: false,
        CustomerLTguidance: false,
        PriceChangeFactor: false,
        StandardLeadTime: false,
        LatestLT: false,
        QuotationReplyLT: false,
        StipulateRestdays: false,
        FreeHoldingdays: false,
        HScode: false,
        ProductCountry: false,
        MFGPlant: false,
        Remark: false,
        StorageTemp: false,
        ProductSizeWDH: false,
        OuterBoxSizeWDH: false,
        DelFlag: false,
    });
    const [DilogDetillDisabled,setDilogDetillDisabled] = useState(ArItemMasterConst.DilogDetillDisabled)
    const [iSDelFlag,setIsDelFlag] = useState(false)
    const dialogTextChange = (e) => {
        setDialogText({ ...dialogText, [e.target.name]: e.target.value })
        if (e.target.value) {
            setIsValid({ ...isValid, [e.target.name]: false })
        }
    }
    const dialogTextDelFlagChange = (e) => {
        if (e.target.value !== "1") {
          e.target.value = ""
        }
        if(!e.target.value){
        setIsDelFlag(true)
        }else{
        setIsDelFlag(false)
        }
        setDialogText({ ...dialogText, [e.target.name]: e.target.value })
    }
    // const [msgText, setMsgText] = React.useState(Object);
    // const handleSetMsgText = (Object) => {
    //     setMsgText(Object);
    // };
    // const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
    // const handleSetMsgBtnOK = (Object) => {
    //     setMsgBtnOK(Object);
    // };
    const [errorcode, setErrorCode] = React.useState(Object);
    const handleSetErrorcode = (Object) => {
        setErrorCode(Object);
    };
    const [msg1Text, setMsg1Text] = React.useState(Object);
    const handleSetMsg1Text = (Object) => {
        setMsg1Text(Object);
    };
    const [msg2Text, setMsg2Text] = React.useState(Object);
    const handleSetMsg2Text = (Object) => {
        setMsg2Text(Object);
    };
    // const [open2, setOpen2] = React.useState(false);
    // const handleClickOpen2 = () => {
    //     setOpen2(true);
    // };
    // const handleCloseNG2 = () => {
    //     setOpen2(false);
    // };
    // const handleCloseOK2 = () => {
    //     setOpen2(false);
    // };
    // const [msg1, setMsgId] = React.useState(Object);
    // const handleSetMsgId = (Object) => {
    //     setMsgId(Object);
    // };
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };
    const dialogTextSuppCodeChange = (e, value) => {
        setDialogText({ ...dialogText, SuppCode: value ? value.split(":")[0] : "",SuppName: value ? value.split(":")[1] : ""})
        let suppCode = isValid.SuppCode
        let suppName = isValid.SuppName
        if (value && value.split(":")[0]) {
            suppCode = false
        }
        if (value && value.split(":")[1]) {
            suppName = false
        }
        setIsValid({ ...isValid, SuppCode : suppCode, SuppName:suppName})
    }
    const dialogOnSubmit = async (upstate) => {
        let ID = ""
        setSearchProgress(true)
        if (upstate === true) {
            let type = ArItemMasterConst.DilogDetill
            if (type === ArItemMasterConst.PROC_ADD || type === ArItemMasterConst.PROC_CADD || type === ArItemMasterConst.PROC_REF) {
                if (!dialogText.SAPCODE) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, SAPCODE: true })
                    return;
                } else {
                    setIsValid({ ...isValid, SAPCODE: false })
                }
                if (!dialogText.GMID) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, GMID: true })
                    return;
                } else {
                    setIsValid({ ...isValid, GMID: false })
                }
                if (!dialogText.ItemText) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, ItemText: true })
                    return;
                } else {
                    setIsValid({ ...isValid, ItemText: false })
                }
                if (!dialogText.ItemGrp) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, ItemGrp: true })
                    return;
                } else {
                    setIsValid({ ...isValid, ItemGrp: false })
                }
                if (!dialogText.ItemGrpName) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, ItemGrpName: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, ItemGrpName: false })
                }
                // if (!dialogText.ARBSuppAgencyCode) {
                //     handleSetErrorcode("エラーコード：1003");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                //     handleClickConfirmOpen();
                //     setIsValid({ ...isValid, ARBSuppAgencyCode: true })
                //     setSearchProgress(false)
                //     return;
                // } else {
                //     setIsValid({ ...isValid, ARBSuppAgencyCode: false })
                // }
                if (!dialogText.SuppCode) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SuppCode: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SuppCode: false })
                }
                if (!dialogText.SuppName) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SuppName: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SuppName: false })
                }
                if (!dialogText.Capacity) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, Capacity: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, Capacity: false })
                }
                if (!dialogText.CapacityUnit) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, CapacityUnit: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, CapacityUnit: false })
                }
                if (!dialogText.BaseNumUnit) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, BaseNumUnit: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, BaseNumUnit: false })
                }
                // if (!dialogText.OrderUnitpriceUnit) {
                //     handleSetErrorcode("エラーコード：1003");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                //     handleClickConfirmOpen();
                //     setIsValid({ ...isValid, OrderUnitpriceUnit: true })
                //     setSearchProgress(false)
                //     return;
                // } else {
                //     setIsValid({ ...isValid, OrderUnitpriceUnit: false })
                // }
                // if (!dialogText.UnitPerOrder) {
                //     handleSetErrorcode("エラーコード：1003");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                //     handleClickConfirmOpen();
                //     setIsValid({ ...isValid, UnitPerOrder: true })
                //     setSearchProgress(false)
                //     return;
                // } else {
                //     setIsValid({ ...isValid, UnitPerOrder: false })
                // }
                if (!dialogText.StorageLoc) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, StorageLoc: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, StorageLoc: false })
                }
                // if (!dialogText.Expiration) {
                //     handleSetErrorcode("エラーコード：1003");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
                //     handleClickConfirmOpen();
                //     setIsValid({ ...isValid, Expiration: true })
                //     setSearchProgress(false)
                //     return;
                // } else {
                //     setIsValid({ ...isValid, Expiration: false })
                // }
            }
            if (type === ArItemMasterConst.PROC_ADD || type === ArItemMasterConst.PROC_CADD) {
                let checkresult = []
                try{
               checkresult = await inputCheck(dialogText,param.useGroupClassification);
            } catch (error) {
                if (error.message === 'No current user') {
                    //ユーザープールに障害が発生した場合
                    console.log('User pool is invalid.Please reauthenticate.');
                    //ログイン画面に戻る
                    signOut();
                } else {
                    //他のエラーを処理する
                    console.log('An error occurred:', error);
                }
            }
                if (checkresult.length > 0) {
                    handleSetErrorcode("エラーコード：1001");
                    handleSetMsg1Text("重複エラー");
                    handleSetMsg2Text("重複している箇所を修正して下さい。");
                    handleClickConfirmOpen()
                    setIsValid({ ...isValid, SAPCODE: true })
                    setSearchProgress(false)
                    return;
                }
                const checkresult1 = await inputCheck1(dialogText,param.useGroupClassification);
                if (checkresult1.length > 0) {
                    handleSetErrorcode("エラーコード：1002");
                    handleSetMsg1Text("重複エラー");
                    handleSetMsg2Text("重複している箇所を修正して下さい。");
                    handleClickConfirmOpen()
                    setIsValid({ ...isValid, GMID: true, ItemText: true, ItemGrp: true })
                    setSearchProgress(false)
                    return;
                }
            }

            let dialoginfo = JSON.parse(JSON.stringify(dialogText))
            switch (type) {
                case ArItemMasterConst.PROC_INQUIRY:
                    break;
                case ArItemMasterConst.PROC_ADD:
                    dialoginfo.DelFlag = ""
                    if(dialoginfo.InventoryTier === ""){
                        dialoginfo.InventoryTier = "サプライヤ確認"
                    }
                    if(dialoginfo.OrderAdjustment === ""){
                        dialoginfo.OrderAdjustment = "サプライヤ確認"
                    }
                    if(dialoginfo.ChilledGoods === ""){
                        dialoginfo.ChilledGoods = "サプライヤ確認"
                    }
                    if(dialoginfo.LatestLT === ""){
                        dialoginfo.LatestLT = "サプライヤ確認"
                    }
                   ID = await addData(dialoginfo)
                    break;
                case ArItemMasterConst.PROC_CADD:
                    dialoginfo.DelFlag = ""
                    if(dialoginfo.InventoryTier === ""){
                        dialoginfo.InventoryTier = "サプライヤ確認"
                    }
                    if(dialoginfo.OrderAdjustment === ""){
                        dialoginfo.OrderAdjustment = "サプライヤ確認"
                    }
                    if(dialoginfo.ChilledGoods === ""){
                        dialoginfo.ChilledGoods = "サプライヤ確認"
                    }
                    if(dialoginfo.LatestLT === ""){
                        dialoginfo.LatestLT = "サプライヤ確認"
                    }
                    ID = await addData(dialoginfo)
                    break;
                case ArItemMasterConst.PROC_REF:
                    ID = await modifyData(dialoginfo,iSDelFlag,param.useGroupClassification)
                    break;
                case ArItemMasterConst.PROC_DEL:
                    dialoginfo.DelFlag = "1"
                    ID = await deleteData(dialoginfo,param.useGroupClassification)
                    break;
                default:
            }
        } else {
            let info = JSON.parse(JSON.stringify(dialogText))
            ID = await modifyData(info,param.useGroupClassification)
        }
        setSearchProgress(false)
        param.closeDilogDetill()
        param.searchBtn(ID)
        param.setDependencefun()
    }
    useEffect(async () => {
        setSearchProgress(true)
        let type = ArItemMasterConst.DilogDetill
        if (type === ArItemMasterConst.PROC_DEL) {
            setDisabledBtn({ ...disabledBtn, Btn1: true })
        }
        if (type === ArItemMasterConst.PROC_INQUIRY) {
            setDisabledBtn({ ...disabledBtn, Btn2: true })
        }
        let user = ""
        try {
            user = await Auth.currentUserInfo();
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        const agencyPriceGroup = await API.graphql(
            graphqlOperation(customerByeMail, {
                Agency_Email: user.attributes.email,
            })
        );
        const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
        if (agentinfo.length > 0) {
            const Permission = agentinfo[0].Permission
            if(type === ArItemMasterConst.PROC_REF && Permission === "2" && dialogText.DelFlag === "1"){
                setDilogDetillDisabled({...DilogDetillDisabled,dialogTextDelFlag:false})
        }
        }
        let ItemMaster = [];
        const list = await API.graphql(graphqlOperation(listArItemMasters, { limit: 10000,filter:{ARBSuppAgencyCode:{eq:param.useGroupClassification}} }));
        if (list.data.listArItemMasters.items && list.data.listArItemMasters.items.length > 0) {
          ItemMaster = list.data.listArItemMasters.items
          let nextToken = list.data.listArItemMasters.nextToken;
          while (nextToken !== null) {
            let InvDataSec = await API.graphql(
              graphqlOperation(listArItemMasters, {
                limit: 10000,
                nextToken: nextToken,
                filter:{ARBSuppAgencyCode:{eq:param.useGroupClassification}}
              }))
            if (InvDataSec.data.listArItemMasters !== null) {
              nextToken = InvDataSec.data.listArItemMasters.nextToken;
              for (
                let i = 0;
                i < InvDataSec.data.listArItemMasters.items.length;
                i++
              ) {
                ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
              }
            }
          }
        }
        // 初期化
        setSuppCodeList([]);
        for (let i = 0; i < ItemMaster.length; i++) {
            if (ItemMaster[i].SuppCode) {
                suppCodeList.push(ItemMaster[i].SuppCode + ":" + ItemMaster[i].SuppName)
            }
        }
        setSuppCodeList(Array.from(new Set(suppCodeList)))


        setSearchProgress(false)
    }, [])
    return (
        <div style={{ width: "1233px" }}>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "210px", height: "25px", borderRadius: "4px", borderColor: isValid.SAPCODE ? "pink" : "#707070" }}
                                value={dialogText.SAPCODE || ""}
                                name="SAPCODE"
                                id="dialogTextSAPCODE"
                                disabled={DilogDetillDisabled.dialogTextSAPCODE}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>Material Code</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "120px", height: "25px", borderRadius: "4px", borderColor: isValid.GMID ? "pink" : "#707070" }}
                                value={dialogText.GMID || ""}
                                name="GMID"
                                id="dialogTextGMID"
                                disabled={DilogDetillDisabled.dialogTextGMID}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "490px", height: "25px", borderRadius: "4px", borderColor: isValid.ItemText ? "pink" : "#707070" }}
                                value={dialogText.ItemText || ""}
                                name="ItemText"
                                id="dialogTextItemText"
                                disabled={DilogDetillDisabled.dialogTextItemText}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目グループ</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "120px", height: "25px", borderRadius: "4px", borderColor: isValid.ItemGrp ? "pink" : "#707070" }}
                                value={dialogText.ItemGrp || ""}
                                name="ItemGrp"
                                id="dialogTextItemGrp"
                                disabled={DilogDetillDisabled.dialogTextItemGrp}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "110px"}}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目グループ名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "480px", height: "25px", borderRadius: "4px", borderColor: isValid.ItemGrpName ? "pink" : "#707070" }}
                                value={dialogText.ItemGrpName || ""}
                                name="ItemGrpName"
                                id="dialogTextItemGrpName"
                                disabled={DilogDetillDisabled.dialogTextItemGrpName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "200px" }}>
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>ARB仕入元代理店コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "150px", height: "25px", borderRadius: "4px", borderColor:"#707070"}}
                                value={dialogText.ARBSuppAgencyCode || ""}
                                name="ARBSuppAgencyCode"
                                id="dialogTextARBSuppAgencyCode"
                                disabled={true}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={suppCodeList}
                                style={{ width: "480px", height: "25px", borderRadius: "4px", marginLeft: "1.8%", }}
                                value={dialogText.SuppCode || ""}
                                name="SuppCode"
                                id="dialogTextSuppCode"
                                disabled={DilogDetillDisabled.dialogTextSuppCode}
                                onChange={(e, value) => { dialogTextSuppCodeChange(e, value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border: isValid.SuppCode ? "1px solid pink" : "1px solid #707070" }} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "5.3%" }}>
                        <label className={classes.textlabelDialog}>
                        <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先名称</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "450px", height: "25px", borderRadius: "4px", borderColor: isValid.SuppName ? "pink" : "#707070" }}
                                value={dialogText.SuppName || ""}
                                name="SuppName"
                                id="dialogTextSuppName"
                                disabled={DilogDetillDisabled.dialogTextSuppName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            {/* <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "120px", height: "25px", borderRadius: "4px", borderColor: isValid.SuppCode ? "pink" : "#707070" }}
                                value={dialogText.SuppCode || ""}
                                id="dialogTextSuppCode"
                                name="SuppCode"
                                disabled={DilogDetillDisabled.dialogTextSuppCode}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}style={{ width: "110px"}}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "480px", height: "25px", borderRadius: "4px", borderColor: isValid.SuppName ? "pink" : "#707070" }}
                                value={dialogText.SuppName || ""}
                                id="dialogTextSuppName"
                                name="SuppName"
                                disabled={DilogDetillDisabled.dialogTextSuppName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div> */}
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>容量</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.Capacity ? "pink" : "#707070" }}
                                value={dialogText.Capacity || ""}
                                name="Capacity"
                                id="dialogTextCapacity"
                                disabled={DilogDetillDisabled.dialogTextCapacity}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>容量単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.CapacityUnit ? "pink" : "#707070" }}
                                value={dialogText.CapacityUnit || ""}
                                name="CapacityUnit"
                                id="dialogTextCapacityUnit"
                                disabled={DilogDetillDisabled.dialogTextCapacityUnit}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>基本数量単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.BaseNumUnit ? "pink" : "#707070" }}
                                value={dialogText.BaseNumUnit || ""}
                                name="BaseNumUnit"
                                id="dialogTextBaseNumUnit"
                                disabled={DilogDetillDisabled.dialogTextBaseNumUnit}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>受注単価単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.OrderUnitpriceUnit ? "pink" : "#707070" }}
                                value={dialogText.OrderUnitpriceUnit || ""}
                                name="OrderUnitpriceUnit"
                                id="dialogTextOrderUnitpriceUnit"
                                disabled={DilogDetillDisabled.dialogTextOrderUnitpriceUnit}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>発注単価単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.UnitPerOrder ? "pink" : "#707070" }}
                                value={dialogText.UnitPerOrder || ""}
                                name="UnitPerOrder"
                                id="dialogTextUnitPerOrder"
                                disabled={DilogDetillDisabled.dialogTextUnitPerOrder}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog} >在庫区分</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "190px", height: "25px", borderRadius: "4px", borderColor: isValid.InventoryTier ? "pink" : "#707070" }}
                                value={dialogText.InventoryTier || ""}
                                name="InventoryTier"
                                id="dialogTextInventoryTier"
                                disabled={DilogDetillDisabled.dialogTextInventoryTier}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>受注調整品</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "190px", height: "25px", borderRadius: "4px", borderColor: isValid.OrderAdjustment ? "pink" : "#707070" }}
                                value={dialogText.OrderAdjustment || ""}
                                name="OrderAdjustment"
                                id="dialogTextOrderAdjustment"
                                disabled={DilogDetillDisabled.dialogTextOrderAdjustment}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "80px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>保管場所</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.StorageLoc ? "pink" : "#707070" }}
                                value={dialogText.StorageLoc || ""}
                                name="StorageLoc"
                                id="dialogTextStorageLoc"
                                disabled={DilogDetillDisabled.dialogTextStorageLoc}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "60px" }}>
                            <span className={classes.textlabelSpanDialog}>冷蔵品</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "150px", height: "25px", borderRadius: "4px", borderColor: isValid.ChilledGoods ? "pink" : "#707070" }}
                                value={dialogText.ChilledGoods || ""}
                                name="ChilledGoods"
                                id="dialogTextChilledGoods"
                                disabled={DilogDetillDisabled.dialogTextChilledGoods}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "60px" }}>
                            <span className={classes.textlabelSpanDialog}>米国品</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "70px", height: "25px", borderRadius: "4px", borderColor: isValid.USproduct ? "pink" : "#707070" }}
                                value={dialogText.USproduct || ""}
                                name="USproduct"
                                id="dialogTextUSproduct"
                                disabled={DilogDetillDisabled.dialogTextUSproduct}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>有効期限</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.Expiration ? "pink" : "#707070" }}
                                value={dialogText.Expiration || ""}
                                name="Expiration"
                                id="dialogTextExpiration"
                                disabled={DilogDetillDisabled.dialogTextExpiration}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>客先MOQ</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.CustomerMOQ ? "pink" : "#707070" }}
                                value={dialogText.CustomerMOQ || ""}
                                name="CustomerMOQ"
                                id="dialogTextCustomerMOQ"
                                disabled={DilogDetillDisabled.dialogTextCustomerMOQ}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>客先LT案内</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.CustomerLTguidance ? "pink" : "#707070" }}
                                value={dialogText.CustomerLTguidance || ""}
                                name="CustomerLTguidance"
                                id="dialogTextCustomerLTguidance"
                                disabled={DilogDetillDisabled.dialogTextCustomerLTguidance}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "180px"}}>
                            <span className={classes.textlabelSpanDialog}>価格変換係数(kg→数量)</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.PriceChangeFactor ? "pink" : "#707070" }}
                                value={dialogText.PriceChangeFactor || ""}
                                name="PriceChangeFactor"
                                id="dialogTextPriceChangeFactor"
                                disabled={DilogDetillDisabled.dialogTextPriceChangeFactor}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog}>標準リードタイム</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.StandardLeadTime ? "pink" : "#707070" }}
                                value={dialogText.StandardLeadTime || ""}
                                name="StandardLeadTime"
                                id="dialogTextStandardLeadTime"
                                disabled={DilogDetillDisabled.dialogTextStandardLeadTime}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "80px"}}>
                            <span className={classes.textlabelSpanDialog}>最新LT</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "150px", height: "25px", borderRadius: "4px", borderColor: isValid.LatestLT ? "pink" : "#707070" }}
                                value={dialogText.LatestLT || ""}
                                name="LatestLT"
                                id="dialogTextLatestLT"
                                disabled={DilogDetillDisabled.dialogTextLatestLT}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>見積回答LT</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.QuotationReplyLT ? "pink" : "#707070" }}
                                value={dialogText.QuotationReplyLT || ""}
                                name="QuotationReplyLT"
                                id="dialogTextQuotationReplyLT"
                                disabled={DilogDetillDisabled.dialogTextQuotationReplyLT}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog}>規定残日数</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.StipulateRestdays ? "pink" : "#707070" }}
                                value={dialogText.StipulateRestdays || ""}
                                name="StipulateRestdays"
                                id="dialogTextStipulateRestdays"
                                disabled={DilogDetillDisabled.dialogTextStipulateRestdays}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>フリー保有日数</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.FreeHoldingdays ? "pink" : "#707070" }}
                                value={dialogText.FreeHoldingdays || ""}
                                name="FreeHoldingdays"
                                id="dialogTextFreeHoldingdays"
                                disabled={DilogDetillDisabled.dialogTextFreeHoldingdays}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog}>HSコード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.HScode ? "pink" : "#707070" }}
                                value={dialogText.HScode || ""}
                                name="HScode"
                                id="dialogTextHScode"
                                disabled={DilogDetillDisabled.dialogTextHScode}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>生産国</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.ProductCountry ? "pink" : "#707070" }}
                                value={dialogText.ProductCountry || ""}
                                name="ProductCountry"
                                id="dialogTextProductCountry"
                                disabled={DilogDetillDisabled.dialogTextProductCountry}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>製造工場</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "230px", height: "25px", borderRadius: "4px", borderColor: isValid.MFGPlant ? "pink" : "#707070" }}
                                value={dialogText.MFGPlant || ""}
                                name="MFGPlant"
                                id="dialogTextMFGPlant"
                                disabled={DilogDetillDisabled.dialogTextMFGPlant}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} >
                            <span className={classes.textlabelSpanDialog}>保管温度</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "230px", height: "25px", borderRadius: "4px", borderColor: isValid.StorageTemp ? "pink" : "#707070" }}
                                value={dialogText.StorageTemp || ""}
                                name="StorageTemp"
                                id="dialogTextStorageTemp"
                                disabled={DilogDetillDisabled.dialogTextStorageTemp}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "230px" }}>
                            <span className={classes.textlabelSpanDialog}>Remark</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "912px", height: "25px", borderRadius: "4px", borderColor: isValid.Remark ? "pink" : "#707070" }}
                                value={dialogText.Remark || ""}
                                name="Remark"
                                id="dialogTextRemark"
                                disabled={DilogDetillDisabled.dialogTextRemark}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "230px" }}>
                            <span className={classes.textlabelSpanDialog}>製品サイズ(縦×横×高さ)</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "912px", height: "25px", borderRadius: "4px", borderColor: isValid.ProductSizeWDH ? "pink" : "#707070" }}
                                value={dialogText.ProductSizeWDH || ""}
                                name="ProductSizeWDH"
                                id="dialogTextProductSizeWDH"
                                disabled={DilogDetillDisabled.dialogTextProductSizeWDH}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "230px" }}>
                            <span className={classes.textlabelSpanDialog}>外装カートンサイズ(縦×横×高さ)</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "912px", height: "25px", borderRadius: "4px", borderColor: isValid.OuterBoxSizeWDH ? "pink" : "#707070" }}
                                value={dialogText.OuterBoxSizeWDH || ""}
                                name="OuterBoxSizeWDH"
                                id="dialogTextOuterBoxSizeWDH"
                                disabled={DilogDetillDisabled.dialogTextOuterBoxSizeWDH}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog} >
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "262px", marginLeft: "880px" }}>
                            <span className={classes.textlabelSpanDialog}>削除フラグ</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "60px", height: "25px", borderRadius: "4px", borderColor: "#707070" }}
                                value={dialogText.DelFlag || ""}
                                name="DelFlag"
                                id="dialogTextDelFlag"
                                disabled={DilogDetillDisabled.dialogTextDelFlag}
                                onChange={(e) => { dialogTextDelFlagChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ position: "fixed", left: "50%", top: "50%", display: searchProgress === true ? "block" : "none" }}>
                <CircularProgress/>
            </div>
            <div style={{ textAlign: "right" }}>
                <Button className="btn" onClick={param.closeDilogDetill} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>戻&nbsp;&nbsp;&nbsp;る
                </Button>
                <Button className="btn" disabled={disabledBtn.Btn1} onClick={() => openDialogstorageDetill()} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>保管場所
                </Button>
                <Button className="btn" disabled={disabledBtn.Btn2} id="dialogTextSubmit" onClick={() => dialogOnSubmit(true)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "red", marginRight: '-10px', borderRadius: '8px', border: "1px solid #000", }}>登録
                </Button>
            </div>
            {/* <Dialog
                open={open2}
                onClose={handleCloseNG2}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {msgText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" autoFocus onClick={handleCloseOK2}>
                        {msgbtnOK}
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
                onClose={handleConfirmClose}
                aria-labelledby="customized-dialog-title"
                open={openConfirm}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        width: "420px",
                        height: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "20px" }} className={classes.textFieldDialog}>
                        {errorcode}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                        {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                        {msg2Text}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleConfirmOK();
                    }} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogstorageDetill}
                maxWidth='xl'
            >
                <DialogTitle>
                    <span style={{ fontWeight: '900' }}>品目保管場所詳細画面</span>
                </DialogTitle>
                <DialogContent>
                    <MasterDialogstorageDetill closeDialogstorageDetill={closeDialogstorageDetill} openDialogstorageDetill={openDialogstorageDetill} ItemCode={dialogText.SAPCODE} ItemName={dialogText.ItemText} UsageCategory={dialogText.ARBSuppAgencyCode} useGroupClassification = {param.useGroupClassification} />
                </DialogContent>
            </Dialog>
        </div>
    )
}