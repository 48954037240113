/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Order.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary Order画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
// import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import Radio from "@material-ui/core/Radio";
// import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
// import Avatar from "@material-ui/core/Avatar";
// import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
import BootstrapTable from "react-bootstrap-table-next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const TAX_RATE = 0.1;
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useLocation } from "react-router-dom";
import userAuth from "../components/userAuth";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import {
  orderByQuoteNumber,
  listArAgencyOrderInfos,
  // arCustomerByAgencyIDContractor,
  arEndUserByReceiptCode,
  arCustomerByAgencyID,
  getArAZCustomerCode,
  arCustomerAddressByOrderRecipientCode,
  listArAgencyOrderInfoByDetailsNumber,
  queryEndUserSpecialPricingsByEndUserCodeIndex,
  queryArInventoryByProductCodeIndex,
  listArcalendars
} from "../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  updateArOrder,
  updateArAgencyOrderInfo,
  updateArCustomerAddress,
} from "../graphql/mutations";
import { useHistory } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import { createArCustomer } from "../graphql/mutations";
import { customerByeMail } from "../graphql/queries";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Select, MenuItem, InputLabel, InputBase, TableHead } from "@material-ui/core";
import { FormControl as FormControlsk } from "@material-ui/core";
import { Box } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  rootl: {
    maxWidth: "100%",
  },
  card1: {
    maxWidth: "50%",
    minWidth: "50%",
    width: "50%",
  },
  card2: {
    maxWidth: "100%",
  },
  table: {
    minWidth: "auto",
    display: "flex",
    justifyContent: "end",
    border: "none",
  },

  textlabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "16ch",
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
    fontWeight: "bold",
  },
  textlabelTT: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "14ch",
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
    fontWeight: "bold",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20ch",
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  textFieldP: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    "text-align": "right",
    align: "right",
  },
  textFieldOrderQuantity: {
    align: "right",
  },

  textKField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    borderBottom: "1px dashed #707070",
    width: "auto",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noframeTableCell: {
    border: "none",
    paddingRight:'0px',
    paddingBottom:'5px'
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: "0px",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

function getStyles(name, contractorGroup, theme) {
  return {
    fontWeight:
      contractorGroup.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 9;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};
const headerStyle = {
  backgroundColor: "white",
  borderBottom: "3px solid #804F29",
};

// function getSteps() {
//   return ["受注受付", "受付処理中", "納期確定", "受注キャンセル"];
// }

const QUERY = "QUERY";
const QUERY_ZA = "QUERY_ZA";
const QUERY_SH = "QUERY_SH";
let orderID = "";
let agencyId = "";
let AgencyName = ""
let addressId = "";
let invoiceSubtotal = "";
let invoiceFax = "";
let invoiceTotal = "";
let invoicePayTotal = "";
let OrderRecipientCodeId = "";

let displayStatus = "none";

let orderReadonly = false;
let displaymemo = false;
let ShippingDataText = "";
const Order = () => {
  ShippingDataText = ""
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  // const steps = getSteps();
  const [ShippingDataHidden, setShippingDataHidden] = React.useState("");
  const [orderNoText, setOrderNo] = React.useState(Object);
  const handleSetOrderNoText = (Object, item) => {
    if (item) {
      item.OrderNumber = Object;
    }
    setOrderNo(Object);
  };
  const [OrderChargeNameText, setOrderChargeNameText] = React.useState(Object);
  const handleSetOrderChargeNameText = (Object) => {
    setOrderChargeNameText(Object);
  };
  const [OrderShipContactNmText, setOrderShipContactNmText] = React.useState(Object);
  const handleSetOrderShipContactNmText = (Object, item) => {
    if (item) {
      item.OrderShipContactNm = Object;
    }
    setOrderShipContactNmText(Object);
  };
  const [OrderRemarksText, setOrderRemarksText] = React.useState(Object);
  const handleSetOrderRemarksText = (Object, item) => {
    if (item) {
      item.OrderRemarks = Object;
    }
    setOrderRemarksText(Object);
  };

  const [ShippingOrderNumberText, setShippingOrderNumberText] = React.useState(Object);
  const handleShippingOrderNumberText = (Object, item) => {
    if (item) {
      item.ShippingOrderNumber = Object;
    }
    setShippingOrderNumberText(Object);
  };

  const [orderNumber2, setOrderNumber2] = React.useState(Object);
  const handleSetOrderNumber2Text = (Object) => {
    setOrderNumber2(Object);
  };
  const [orderDataText, setOrderData] = React.useState(Object);
  const handleSetOrderDataText = (Object) => {
    setOrderData(Object);
  };
  const [mitumoriNoText, setMitumoriNo] = React.useState(Object);
  const handleSetMitumoriNoText = (Object) => {
    setMitumoriNo(Object);
  };
  const [telText, setTel] = React.useState(Object);
  const handleSetTelText = (Object, item) => {
    if (item) {
      item.telText = Object;
    }
    setTel(Object);
  };
  const [CompanyName, setCompanyName] = React.useState(Object);
  const handleSetCompanyName = (Object) => {
    setCompanyName(Object);
  };
  const [RegisteredPerson, setMitumoriName] = React.useState(Object);
  const handleSetRegisteredPerson = (Object) => {
    setMitumoriName(Object);
  };
  const [emailText, setEmail] = React.useState(Object);
  const handleSetEmailText = (Object, item) => {
    if (item) {
      item.emailText = Object;
    }
    setEmail(Object);
  };
  const [shipping, setShipping] = React.useState(Object);
  const handleSetShipping = (Object, item) => {
    if (item) {
      item.ShippingDestination = Object;
    }
    setShipping(Object);
  };
  const [shippingDestinationName1, setShippingDestinationName1] =
    React.useState(Object);
  const handleSetShippingDestinationName1 = (Object, item) => {
    if (item) {
      item.shippingDestinationName = Object;
    }
    setShippingDestinationName1(Object);
  };

  const [shippingDestinationPlaceName, setShippingDestinationPlaceName] =
    React.useState(Object);
  const handleSetShippingDestinationPlaceName = (Object, item) => {
    if (item) {
      item.OrderAddress = Object;
    }
    setShippingDestinationPlaceName(Object);
  };

  const [shippingDestinationPostCode, setShippingDestinationPostCode] =
    React.useState(Object);
  const handleSetShippingDestinationPostCode = (Object, item) => {
    if (item) {
      item.OrderPostCode = Object;
    }
    setShippingDestinationPostCode(Object);
  };

  const [shippingDestinationName2, setShippingDestinationName2] =
    React.useState(Object);
  const handleSetShippingDestinationName2 = (Object, item) => {
    if (item) {
      item.shippingDestinationName2 = Object;
    }
    setShippingDestinationName2(Object);
  };

  const [shippingDestinationArea, setShippingDestinationArea] =
    React.useState(Object);
  const handleSetShippingDestinationArea = (Object, item) => {
    if (item) {
      item.shippingDestinationArea = Object;
    }
    setShippingDestinationArea(Object);
  };

  const [shippingDestinationTel, setShippingDestinationTel] =
    React.useState(Object);
  const handleSetShippingDestinationTel = (Object, item) => {
    if (item) {
      item.OrderTelNo = Object;
    }
    setShippingDestinationTel(Object);
  };
  const [shippingNm, setShippingNm] = React.useState(Object);
  const handleSetShippingNm = (Object, item) => {
    if (item) {
      item.Contractor = contractorId;
      item.ContractorNm = contractorNm;
      item.OrderChargeName = OrderChargeNameText;
      item.ShippingDestinationNm = Object;
    }
    setShippingNm(Object);
  };

  const [contractorId, setcontractorId] = React.useState(Object);
  const [endUserId, setEndUserId] = React.useState(Object);
  const handlecontractorId = (Object) => {
    OrderRecipientCodeId = Object;
    setcontractorId(Object);
    handelEndUserChange(Object);
  };
  const [selectedDate, setSelectedData] = React.useState();
  const handelEndUserChange = async (OrderRecipientCodeId) => {
    const EndUseList = await API.graphql(
      graphqlOperation(arEndUserByReceiptCode, {
        RecipientCode: OrderRecipientCodeId,
      })
    );
    if (EndUseList.data.ArEndUsersByOrderRecipientCode !== null && EndUseList.data.ArEndUsersByOrderRecipientCode.items.length > 0 && !orderReadonly) {
      let EndUserLIstInfo = EndUseList.data.ArEndUsersByOrderRecipientCode.items;
      EndUserLIstInfo = bubbleSort(EndUserLIstInfo);
      setEndUsersOptions({
        type: QUERY_ZA,
        Invs: EndUserLIstInfo,
      });
    }

  };


  const handleEndUserId = (Object, item) => {
    if (item) {
      item.EndUserId = Object;
    }
    setEndUserId(Object);
  };
  const [contractorNm, setcontractorNm] = React.useState(Object);
  const handlecontractorNm = (Object, item) => {
    if (item) {
      item.contractorNm = Object;
    }
    setcontractorNm(Object);
  };
  const [endUserNm, setEndUserNm] = React.useState(Object);
  const handleEndUserNm = (Object, item) => {
    if (item) {
      item.EndUserNm = Object;
    }
    setEndUserNm(Object);
  };
  const [OrderSupplier, setOrderSupplier] = React.useState(Object);
  const handleOrderSupplier = (Object) => {
    setOrderSupplier(Object);
  };
  const [OrderSupplierName, setOrderSupplierName] = React.useState(Object);
  const handleOrderSupplierName = (Object) => {
    setOrderSupplierName(Object);
  };
  const [SalesOfficeName, setGroupName] = React.useState(Object);
  const handleSetSalesOfficeName = (Object) => {
    setGroupName(Object);
  };
  const [ARBSalesRepresentative, setARBSalesRepresentative] =
    React.useState(Object);
  const handleSetARBSalesRepresentative = (Object) => {
    setARBSalesRepresentative(Object);
  };
  const [ProcurementStaff, setOrderPerson] = React.useState(Object);
  const handleSetProcurementStaff = (Object) => {
    setOrderPerson(Object);
  };
  const [error, setError] = React.useState(Object);
  const handleSetError = (Object) => {
    setError(Object);
  };
  const [errorFlg, setErrorFlg] = React.useState(Object);
  const handleSetErrorFlg = (Object) => {
    setErrorFlg(Object);
  };

  // const [RegistrationDate, setRegistrationDate] = React.useState(Object);
  // const handleSetRegistrationDate = (Object) => {
  //   setRegistrationDate(Object);
  // };
  const [OrderDate, setOrderDate] = React.useState(Object);
  const handleSetOrderDate = (Object) => {
    setOrderDate(Object);
  };
  const [EstimatedShippingDate, setEstimatedShippingDate] =
    React.useState(Object);
  const handleSetEstimatedShippingDate = (Object) => {
    setEstimatedShippingDate(Object);
  };
  const [ShipDate, setShipDate] = React.useState(Object);
  const handleSetShipDate = (Object) => {
    setShipDate(Object);
  };
  const [DeliveryYtDate, setDeliveryYtDate] = React.useState(Object);
  const handleSetDeliveryYtDate = (Object) => {
    setDeliveryYtDate(Object);
  };
  const [DeliveryDate, setDeliveryDate] = React.useState(Object);
  const handleSetDeliveryDate = (Object) => {
    setDeliveryDate(Object);
  };
  const [flg, setInvoiceSubtotal] = React.useState(Object);
  const handleSetflg = (Object) => {
    setInvoiceSubtotal(Object);
  };
  const OrderCancelClick = async()=>{
    setOpen3(true);
  }
  const handleCancelOrder = async ()=>{
    handleClose3();
    const result = await API.graphql(
      graphqlOperation(updateArAgencyOrderInfo, {
        input: {
          id: orderinfo[0].id,
          CancelFlag:"1"
        }
      })
    );
    setOrderCancelFlag("1");
    sendCanCelEmail();
  }
  const sendCanCelEmail = async ()=>{
    const bodyContent = {
      templete: "a1",
      subject: `【${orderinfo[0].OrderDetailsNumber}】キャンセル申請通知`,
      msgUrl: global.ArGlob.HostIpAddress + orderinfo[0].QuoteNumber,
      // linkUrls:msgurls,
      txtEnd: "",
      receiveUser: "",
      message:
        "営業アシスタント様<br><br>【" +
        orderinfo[0].OrderDetailsNumber +
        " 】にキャンセル申請がありました。内容をご確認ください。",
    };
    const body = JSON.stringify(bodyContent);
    const requestOptions = {
      method: "POST",
      body,
    };

   const response = await fetch(global.ArGlob.EmailEndPoint, requestOptions)
   console.log("response",response);
   history.push({
    pathname: "/Orderlist",
    state: { QuoteNumber: orderinfo[0].QuoteNumber },
  });
  }

  const [msg1, setMsgId] = React.useState(Object);
  const handleSetMsgId = (Object) => {
    setMsgId(Object);
  };
  const [title, setTitle] = React.useState(Object);
  const handleSetTitle = (Object) => {
    setTitle(Object);
  };
  const [msgText, setMsgText] = React.useState(Object);
  const handleSetMsgText = (Object) => {
    setMsgText(Object);
  };
  const [buttonText, setButtonText] = React.useState(Object);
  const handleSetButtonText = (Object) => {
    setButtonText(Object);
  };

  const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
  const handleSetMsgBtnOK = (Object) => {
    setMsgBtnOK(Object);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleCloseNG2 = () => {
    setOpen2(false);
  };
  const handleCloseOK2 = () => {
    setOpen2(false);
  };
  const [orderinfo, setOrderinfo] = useState([]);
  const [addressList, setAddress] = useState([]);
  const [OrderCancelFlag,setOrderCancelFlag] = useState("");

  function setQ(id, num) {
    for (let j = 0; j < orderinfo.length; j++) {
      if (orderinfo[j].id === id) {
        orderinfo[j].OrderQuantity = num;
        break;
      }
    }
  }
  const handleSetCount = (row, Object) => {
    if (Object < 0) {
      return;
    }
    //    if (Object > Quantity) {
    //      return;
    //    }
    for (let j = 0; j < orderinfo.length; j++) {
      if (orderinfo[j].id === row.id) {
        orderinfo[j].OrderQuantity = Object;
        break;
      }
    }
    let postageSum = 0;
    for (let j = 0; j < orderinfo.length; j++) {
      if (
        parseFloat(orderinfo[j].OrderQuantity) *
        parseFloat(moneytonum(orderinfo[j].Wholes_Price)) >
        0 &&
        parseFloat(orderinfo[j].OrderQuantity) *
        parseFloat(moneytonum(orderinfo[j].Wholes_Price)) <=
        7000
      ) {
        postageSum += 1200;
      }
    }
    let sum = 0;
    for (let j = 0; j < orderinfo.length; j++) {
      sum =
        sum +
        parseInt(orderinfo[j].OrderQuantity) *
        parseInt(moneytonum(orderinfo[j].Wholes_Price));
    }
    invoiceSubtotal = numtomoney(sum, 0);
    invoiceFax = numtomoney(Math.round((sum + postageSum) * TAX_RATE), 0);
    invoiceTotal = numtomoney(postageSum, 0);
    invoicePayTotal = numtomoney(
      Math.round(sum + (sum + postageSum) * 0 + postageSum),
      0
    );
    handleSetflg(invoicePayTotal);
  };
  function moneytonum(val) {
    let num = val.trim();
    let ss = num.toString();
    if (ss.length === 0) {
      return "0";
    }
    return ss.replace(/,/g, "");
  }
  function numtomoney(number, toFixed) {
    let str = [];
    if (!number) return 0;
    if (typeof number === "number") number = String(number);
    let tempNumber = number.split(".");
    number = tempNumber[0];
    if (number.length <= 3) {
      if (tempNumber[1]) {
        number += "." + tempNumber[1].slice(0, toFixed);
      }
      return number;
    }
    number
      .split("")
      .reverse()
      .forEach((item, index) => {
        if (index !== 0 && index % 3 === 0) {
          str.push(",", item);
        } else {
          str.push(item);
        }
      });
    number = str.reverse().join("");
    if (tempNumber[1]) {
      number += "." + tempNumber[1].slice(0, toFixed);
    }
    return number;
  }
  const handleSelectedDate = async (e,item)=>{
    item.selectedDate = e;
    setSelectedData(e)
    handleSetOrderDataText(moment(e).format("YYYY-MM-DD"));
  }
  const [IsWorkday, setIsWorkday] = useState([])
  const disableWeekends = (date)=> {
    return IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === date.getTime())
}
const getIsWorkday = async () => {
  let holidays = [];
  const listArcalendarsList = await API.graphql(
    graphqlOperation(listArcalendars, {
      filter: { IsWorkday: { eq: "0" } },
      limit: 10000,
    }))
  if (listArcalendarsList.data.listArcalendars !== null) {
    let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
    holidays = listArcalendarsList.data.listArcalendars.items;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(listArcalendars, {
          filter: { IsWorkday: { eq: "0" } },
          limit: 10000,
          nextToken: nextToken,
        }))
      nextToken = InvDataSec.data.listArcalendars.nextToken;
      for (
        let i = 0;
        i < InvDataSec.data.listArcalendars.items.length;
        i++
      ) {
        holidays.push(InvDataSec.data.listArcalendars.items[i]);
      }
    }
  }
  return holidays;
}
  useEffect(() => {
    async function listInvData() {
      orderReadonly = false;
      displaymemo = false;
      let QuoteNumber = location.state.QuoteNumber;
      const OrderDetailsNo = location.state.OrderDetailsNumber;
      if (location.state.readOnly) {
        orderReadonly = true;
      }
      handleSetOrderNoText("");
      handleSetOrderNumber2Text("");
      // handleSetRegistrationDate('');
      handleSetOrderDate("");
      handleSetEstimatedShippingDate("");
      handleSetShipping("");
      handleSetShippingNm("");
      handleSetShipDate("");
      handleSetDeliveryYtDate("");
      handleSetDeliveryDate("");
      handleSetOrderNumber2Text("");
      handleSetMitumoriNoText(QuoteNumber);
      handleSetTelText("");
      handleSetCompanyName("");
      handleSetRegisteredPerson("");
      handleSetEmailText("");
      handleSetSalesOfficeName("");
      handleSetProcurementStaff("");
      handleSetARBSalesRepresentative("");
      handleSetShippingDestinationName1("");
      handleSetShippingDestinationPlaceName("");
      handleSetShippingDestinationPostCode("");
      handleSetShippingDestinationName2("");
      handleSetShippingDestinationArea("");
      handleSetShippingDestinationTel("");
      handleSetOrderShipContactNmText("");
      handleSetOrderRemarksText("");
      handleShippingOrderNumberText("");
      handleSetOrderChargeNameText("");
      setShippingDataHidden("");
      setEndUserGroupHidden("");
      const orderByQuoteNumberIngo = await API.graphql(
        graphqlOperation(orderByQuoteNumber, { QuoteNumber: QuoteNumber })
      );
      let historyDate;
      if (orderByQuoteNumberIngo.data.orderByQuoteNumber !== null) {
        const order = orderByQuoteNumberIngo.data.orderByQuoteNumber["items"];
        if (order.length > 0) {
          // let aa = moment(order[0].RegistrationDate).utcOffset(0).format('YYYY-MM-DD');
          historyDate = moment(order[0].DesiredDeliveryDate).format("YYYY-MM-DD");
          orderID = order[0].id;
          handleSetOrderDataText(historyDate);
          setOrderNo(order[0].OrderNumber)
          //担当者名
          setOrderChargeNameText(order[0].OrderChargeName)
          //注文備考
          setOrderRemarksText(order[0].OrderRemarks)
          //出荷先
          setShippingDataHidden(order[0].ShippingDestination);
          handleSetShippingDestinationName1(order[0].ShippingDestinationNm);
          handleSetShippingDestinationPlaceName(order[0].OrderAddress);
          handleSetShippingDestinationTel(order[0].OrderTelNo);
          handleSetShipping(order[0].ShippingDestination);
          handleSetShippingNm(order[0].ShippingDestinationNm);
          handleSetShippingDestinationPostCode(order[0].OrderPostCode);
          //出荷先担当者名
          setOrderShipContactNmText(order[0].OrderShipContactNm)
          //出荷先注文番号
          setShippingOrderNumberText(order[0].ShippingOrderNumber)
          //出荷備考
          setOinput(order[0].OrderShippingNotes)
          //エンドユーザー
          setEndUserGroupHidden(order[0].EndUserId)
          handleEndUserId(order[0].EndUserId);
          handleEndUserNm(order[0].EndUserNm);
          if (order[0].Contractor !== null && order[0].Contractor !== undefined) {
              //代理店ID
              handlecontractorId(order[0].Contractor);
              //代理店名
              handlecontractorNm(order[0].ContractorNm);
              let obj = [];
              let name = getGroupName(order[0].GroupName)
              obj.push(order[0].Contractor + ":" + order[0].ContractorNm + name);
              setContractorGroup(obj);
              setContractor(order[0].Contractor + ":" + order[0].ContractorNm + name);
         }
        }
      }
      let order = []
      if (OrderDetailsNo) {
        const listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfoByDetailsNumber, { OrderDetailsNumber: OrderDetailsNo }));
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber !== null) {
          order =
            listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber["items"];
        }
        if(order.length > 0){
          const orderStatus = order[0].OrderStatus;
          const auth = await userAuth();
          //　代理店　ログイン
          if(auth === "1" && (orderStatus === "3" || orderStatus === "4")){
            let OrderCancelFlag = "0"
            if(order[0].CancelFlag){
              OrderCancelFlag = order[0].CancelFlag;
            }
            if(order[0].ShipmentStatus === "N"){
              OrderCancelFlag = ""
            }
            setOrderCancelFlag(OrderCancelFlag)
          }
        }
      } else {
        let listArAgencyOrderInfo = await API.graphql(
          graphqlOperation(listArAgencyOrderInfos, { QuoteNumber: QuoteNumber })
        );
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
          order =
            listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];
        }
      }
      if (order.length > 0) {
        for (let i = 0; i < order.length - 1; i++) {
          for (let j = 0; j < order.length - 1 - i; j++) {
            if (order[j].Product_id < order[j + 1].Product_id) {
              let temp = order[j];
              order[j] = order[j + 1];
              order[j + 1] = temp;
            }
          }
        }
       let EnduserListInfos = []
        order.forEach(async item => {
          const historyDate = moment(item.DesiredDeliveryDate)
            .utcOffset(0)
            .format("YYYY-MM-DD");
          item.selectedDate = historyDate;
          if (orderReadonly) {
            item.ShippingData = item.ShippingDestination + ":" + item.ShippingDestinationNm;
            item.endUserGroup = item.EndUserId + ":" + item.EndUserNm;
            //代理店ID
            handlecontractorId(item.Agency_id);
            //代理店名
            handlecontractorNm(item.AgencyName);
            let obj = [];
            let name = getGroupName(item.GroupName)
            obj.push(item.Agency_id + ":" + item.AgencyName + name);
            setContractorGroup(obj);
            setContractor(item.Agency_id + ":" + item.AgencyName + name);
          }
          // if (item.ShippingDestination)
          else {
            item.ShippingData = item.ShippingDestination + ":" + item.ShippingDestinationNm;
            item.endUserGroup = item.EndUserId + ":" + item.EndUserNm;
          }
          EnduserListInfos.push({EndUserCode:item.EndUserId,Name1:item.EndUserNm})
          //エンドユーザー特価設定
          //&& item.ShippingDestination
          if (item.EndUserId) {
            const Enbuserlist = await API.graphql(graphqlOperation(queryEndUserSpecialPricingsByEndUserCodeIndex, { EndUserCode: item.EndUserId }));
            if (Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex) {
              if (Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex.items.length !== 0) {
                const EnduserlistData = Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex.items;
                EnduserlistData.forEach(itemEndUser => {
                  if(itemEndUser.ItemCode === item.Product_Code && itemEndUser.OrderCode === (item.Contractor ? item.Contractor : item.Agency_id)){
                    item.Wholes_Price = numtomoney(itemEndUser.Price)
                  }
                });
              }
            }
          }
        });
        await specialDeal(order)
        let useAuths = await userAuth();
        if(useAuths === "4" || useAuths === "3" || useAuths === "2"){
         orderReadonly = true
        }
        if(useAuths === "0"){
          displaymemo = true
         }
        if(orderReadonly){
          setEndUsersOptions({
            type: QUERY_ZA,
            Invs: EnduserListInfos,
          });
        }
        let IsWorkdays = await getIsWorkday()
        setIsWorkday(IsWorkdays)
        setOrderinfo(order);
      }
      getAddressList();
    }

    listInvData();
  }, []);

  const theme = useTheme();
  const [contractorGroup, setContractor] = React.useState("");
  const [endUserGroup, setEndUserGroup] = React.useState("");
  const [ShippingData, setShippingData] = React.useState("");
  const [endUserGroupHidden, setEndUserGroupHidden] = React.useState("");


  const initialcontractor = { Invs: [] };
  // const initialSupplicant = {Invs:[]};
  // const initialPayerList = {Invs:[]};
  const handleContractorChange = (event) => {
    setContractor(event.target.value);
    let Contractor_temp = event.target.value;
    let ContractorID = Contractor_temp.split(":");
    handlecontractorId(ContractorID[0]);
    handlecontractorNm(ContractorID[1]);
  };

  const handleEndUserChange = async (event, item) => {
    if (item) {
      item.endUserGroup = event.target.value;
    }
    setEndUserGroup(event.target.value);
    let EndUser_temp = event.target.value;
    let EndUserId = EndUser_temp.split(":");
    handleEndUserId(EndUserId[0], item);
    handleEndUserNm(EndUserId[1], item);

    try {
      //エンドユーザー特価設定
      const Enbuserlist = await API.graphql(graphqlOperation(queryEndUserSpecialPricingsByEndUserCodeIndex, { EndUserCode: item.EndUserId }));
      let agencyIdTemp = contractorGroup && contractorGroup.split(":")[0]
      if (Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex) {
        if (Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex.items.length !== 0) {
          const EnduserlistData = Enbuserlist.data.queryEndUserSpecialPricingsByEndUserCodeIndex.items;
          let hasSpecialPrice = false;
          EnduserlistData.forEach(itemEndUser => {
            if(itemEndUser.ItemCode === item.Product_Code && itemEndUser.OrderCode === (agencyIdTemp ? agencyIdTemp : agencyId)){
              item.Wholes_Price = numtomoney(itemEndUser.Price)
              hasSpecialPrice = true;
            }
          });
          if(!hasSpecialPrice){
            const WholesPriceList = await API.graphql(graphqlOperation(queryArInventoryByProductCodeIndex, { Product_Code: item.Product_Code, Agency_Price_Group: item.Agency_Price_Group }));
            if (WholesPriceList.data.queryArInventoryByProductCodeIndex) {
              if (WholesPriceList.data.queryArInventoryByProductCodeIndex.items.length !== 0) {
                let WholesPrice = WholesPriceList.data.queryArInventoryByProductCodeIndex.items[0];
                item.Wholes_Price = WholesPrice.Wholes_Price
              }
            }
          }

        } else {
          const WholesPriceList = await API.graphql(graphqlOperation(queryArInventoryByProductCodeIndex, { Product_Code: item.Product_Code, Agency_Price_Group: item.Agency_Price_Group }));
          if (WholesPriceList.data.queryArInventoryByProductCodeIndex) {
            if (WholesPriceList.data.queryArInventoryByProductCodeIndex.items.length !== 0) {
              let WholesPrice = WholesPriceList.data.queryArInventoryByProductCodeIndex.items[0];
              item.Wholes_Price = WholesPrice.Wholes_Price
            }
          }
        }
      } else {
        const WholesPriceList = await API.graphql(graphqlOperation(queryArInventoryByProductCodeIndex, { Product_Code: item.Product_Code, Agency_Price_Group: item.Agency_Price_Group }));
        if (WholesPriceList.data.queryArInventoryByProductCodeIndex) {
          if (WholesPriceList.data.queryArInventoryByProductCodeIndex.items.length !== 0) {
            let WholesPrice = WholesPriceList.data.queryArInventoryByProductCodeIndex.items[0];
            item.Wholes_Price = WholesPrice.Wholes_Price
          }
        }
      }
      await specialDeal(orderinfo)
    } catch (err) {
      console.log(err)
    }
    setOrderinfo(JSON.parse(JSON.stringify(orderinfo)));
  };

  function bubbleSort(arr) {
    const max = arr.length - 1;
    for (let j = 0; j < max; j++) {
      let done = true;
      for (let i = 0; i < max - j; i++) {
        if (arr[i].Name1.localeCompare(arr[i + 1].Name1) > 0) {
          let temp = arr[i];
          arr[i] = arr[i + 1];
          arr[i + 1] = temp;
          done = false;
        }
      }
      if (done) {
        break;
      }
    }
    return arr;
  }

  const reducercontractor = (state, action) => {
    let obj = [];
    let index = "";
    switch (action.type) {
      case QUERY:
        for (let i = 0; i < action.Invs.length; i++) {
          obj.push(action.Invs[i].CustomerCode + ":" + action.Invs[i].Name1);
          if (i === 0) {
            handlecontractorId(action.Invs[0].CustomerCode);
            handlecontractorNm(action.Invs[0].Name1);
          }
        }
        setContractor(obj[0]);
        return { ...state, Invs: obj };
      case QUERY_ZA:
        // obj.push(":None");
        index = "";
        for (let i = 0; i < action.Invs.length; i++) {
          if (endUserGroupHidden === action.Invs[i].EndUserCode) {
            index = i;
          }
          obj.push(action.Invs[i].EndUserCode + ":" + action.Invs[i].Name1);
          // if (i === 0) {
          //   handleEndUserId(action.Invs[0].EndUserCode);
          //   handleEndUserNm(action.Invs[0].Name1);
          // }
        }
        if (index !== "") {
          setEndUserGroup(obj[index]);
        }

        return { ...state, Invs: obj };
      case QUERY_SH:
        index = "";
        for (let i = 0; i < action.Invs.length; i++) {
          if (ShippingDataHidden === action.Invs[i].CustomerReceiptCode) {
            index = i;
          }
          obj.push(
            action.Invs[i].CustomerReceiptCode + ":" + action.Invs[i].Name1
          );
        }
        if (index !== "") {
          setShippingData(obj[index]);
        }
        return { ...state, Invs: obj };
      default:
        return state;
    }
  };
  const [selectContractorOptions, setContractorGroup] = React.useState([]);
  // let [selectContractorOptions, setContractorGroup] = useReducer(
  //   reducercontractor,
  //   initialcontractor
  // );
  let [endUserOptions, setEndUsersOptions] = useReducer(
    reducercontractor,
    initialcontractor
  );
  let [ShipingOptions, setShipingOptions] = useReducer(
    reducercontractor,
    initialcontractor
  );
  // let [selectSupplicantList, setSupplicantList] = useReducer(reducercontractor, initialSupplicant);
  // let [selectPayerListList, setPayerListList] = useReducer(reducercontractor, initialPayerList);
  let userAuths = "";
  const getGroupName = (name)=>{
    let val = ""
 if(name !== null && name !== undefined && name){
  val = " " + name
 }
 return val
  }
  const getAddressList = async () => {
    userAuths = await userAuth();
    if (userAuths === "1") {
        const user = await Auth.currentUserInfo();
        const agencyPriceGroup = await API.graphql(
          graphqlOperation(customerByeMail, { Agency_Email: user.attributes.email })
          );
          const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
          if (agentinfo.length > 0) {
            agencyId = agentinfo[0].Agency_id;

            AgencyName = agentinfo[0].Agency_Name;
            //登録者
            handleSetRegisteredPerson(AgencyName);
            //会社名
            handleSetCompanyName(AgencyName);
            //営業所名
            handleSetSalesOfficeName(agentinfo[0].Company_Name);
            //代理店ID
            handlecontractorId(agencyId);
            //代理店名
            handlecontractorNm(AgencyName);
            let obj = [];
            let name = getGroupName(agentinfo[0].Company_Name)
            obj.push(agencyId + ":" + AgencyName + name);
            setContractorGroup(obj);
            setContractor(agencyId + ":" + AgencyName + name);
        }
    }
    if (userAuths !== "1") {
      agencyId = location.state.AgencyID;
    }
    // const ContractorList = await API.graphql(
    //   graphqlOperation(arCustomerByAgencyIDContractor, {
    //     CustomerCodeKey: agencyId,
    //     filter: { AccounKey: { eq: "SP" } },
    //   })
    // );
    OrderRecipientCodeId = agencyId;

    // if (ContractorList.data.arCustomerByAgencyID.items.length > 0) {
    //   let ContractorListInfo = ContractorList.data.arCustomerByAgencyID.items;
    //   ContractorListInfo = bubbleSort(ContractorListInfo);
    //   setContractorGroup({
    //     type: QUERY,
    //     Invs: ContractorListInfo,
    //   });
    //   OrderRecipientCodeId =
    //     ContractorList.data.arCustomerByAgencyID.items[0].CustomerCode;
    // }

    if(!orderReadonly){
      const EndUseList = await API.graphql(
        graphqlOperation(arEndUserByReceiptCode, {
          RecipientCode: OrderRecipientCodeId,
        })
      );
      if (EndUseList.data.ArEndUsersByOrderRecipientCode.items.length > 0) {
        let EndUserInfoLIst = EndUseList.data.ArEndUsersByOrderRecipientCode.items;
        EndUserInfoLIst = bubbleSort(EndUserInfoLIst);
        setEndUsersOptions({
          type: QUERY_ZA,
          Invs: EndUserInfoLIst,
        });
      }
    }
    let listAddressInfo = await API.graphql(
      graphqlOperation(arCustomerAddressByOrderRecipientCode, {
        OrderRecipientCode: OrderRecipientCodeId,
      })
    );
    if (listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex !== null) {
      let listCustomerAddressList = listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex.items;
      listCustomerAddressList = bubbleSort(listCustomerAddressList);
      setShipingOptions({
        type: QUERY_SH,
        Invs: listCustomerAddressList,
      });
      // const address =
      //   listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex[
      //   "items"
      //   ];
      setAddress(listCustomerAddressList);

    }
  };
  // dialog
  const [open1, setOpen1] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);


  // dialog 項目 Start
  const [CustomerAccountGroup, setCustomerAccountGroup] =
    React.useState(Object);
  const txtChangedCustomerAccountGroup = (Object) => {
    setCustomerAccountGroup(Object);
  };
  const [HonorificTitleKeyDialog, setHonorificTitleKeyDialog] =
    React.useState(Object);
  const txtChangedHonorificTitleKeyDialog = (Object) => {
    setHonorificTitleKeyDialog(Object);
  };
  const [Name1Dialog, setName1Dialog] = React.useState(Object);
  const txtChangedName1Dialog = (Object) => {
    setName1Dialog(Object);
  };
  const [Name2Dialog, setName2Dialog] = React.useState(Object);
  const txtChangedName2Dialog = (Object) => {
    setName2Dialog(Object);
  };
  const [Name3Dialog, setName3Dialog] = React.useState(Object);
  const txtChangedName3Dialog = (Object) => {
    setName3Dialog(Object);
  };
  const [SearchTerm1Dialog, setSearchTerm1Dialog] = React.useState(Object);
  const txtChangedSearchTerm1Dialog = (Object) => {
    setSearchTerm1Dialog(Object);
  };
  const [PlaceNameDialog, setPlaceNameDialog] = React.useState(Object);
  const txtChangedPlaceNameDialog = (Object) => {
    setPlaceNameDialog(Object);
  };
  const [PostCodeDialog, setPostCodeDialog] = React.useState(Object);
  const txtChangedPostCodeDialog = (Object) => {
    setPostCodeDialog(Object);
  };

  const [CountryCodeDialog, setCountryCodeDialog] = React.useState(Object);
  const txtChangedCountryCodeDialog = (Object) => {
    setCountryCodeDialog(Object);
  };

  const [AreaDialog, setAreaDialog] = React.useState(Object);
  const txtChangedAreaDialog = (Object) => {
    setAreaDialog(Object);
  };

  const [LanguageCodeDialog, setLanguageCodeDialog] = React.useState(Object);
  const txtChangedLanguageCodeDialog = (Object) => {
    setLanguageCodeDialog(Object);
  };

  const [FirstPhoneNumberDialog, setFirstPhoneNumberDialog] =
    React.useState(Object);
  const txtChangedFirstPhoneNumberDialog = (Object) => {
    setFirstPhoneNumberDialog(Object);
  };


  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleChange1 = (value, item) => {
    item.ShippingData = value;
    setShippingData(value);
    if (value === "") {
      handleSetShippingDestinationName1("", item);
      handleSetShippingDestinationPlaceName("", item);
      handleSetShippingDestinationTel("", item);
      return;
    }
    let id = value.split(":");
    for (let j = 0; j < addressList.length; j++) {
      if (addressList[j].CustomerReceiptCode === id[0]) {
        handleSetTelText(addressList[j].PhoneNumber, item);
        handleSetEmailText(addressList[j].EmailAddress, item);
        handleSetShipping(addressList[j].CustomerReceiptCode, item);
        handleSetShippingNm(addressList[j].Name1, item);

        handleSetShippingDestinationName1(addressList[j].Name2 + addressList[j].Name3 + addressList[j].Name4, item);
        let PostCodeText = "";
        if (addressList[j].PostCode.length > 3) {
          PostCodeText = addressList[j].PostCode.substring(0, 3) + "-" + addressList[j].PostCode.substring(3);
        }
        handleSetShippingDestinationPlaceName(
          PostCodeText + "　" + addressList[j].PlaceName + addressList[j].city + addressList[j].PlaceName3, item
        );
        handleSetShippingDestinationPostCode(addressList[j].PostCode, item);
        handleSetShippingDestinationName2(addressList[j].Name2, item);
        handleSetShippingDestinationArea(addressList[j].Area, item);
        handleSetShippingDestinationTel(addressList[j].Tel, item);
        break;
      }
    }
    setOrderinfo(JSON.parse(JSON.stringify(orderinfo)));
  };
  function orderClick(saveOrder) {
    updateArOrders(saveOrder);
  }
  function goback() {
    gobackUpdate();
  }
  const gobackUpdate = async () => {
    //一時保存処理
    await API.graphql(
      graphqlOperation(updateArOrder, {
        input: {
          id: orderID,
          QuoteNumber: location.state.QuoteNumber /*登録番号*/,
          Insertperson: RegisteredPerson /*登録者*/,
          OrderNumber: orderNoText /*発注番号*/,
          ChouNumber: orderNumber2 /*注文番号 */,
          OrderPerson: ProcurementStaff /*調達担当者*/,
          ChouDate: OrderDate /*注文日*/,
          CompanyName: CompanyName /*会社名 */,
          EstimatedShippingDate: EstimatedShippingDate /*出荷予定日 */,
          ShipDate: ShipDate /*出荷日 */,
          GroupName: SalesOfficeName /*営業所名 */,
          ARBSalesRepresentative: ARBSalesRepresentative /*ARB担当営業 */,
          DeliveryYtDate: DeliveryYtDate /*納品予定日 */,
          DeliveryDate: DeliveryDate /*納品日 */,
          TelNo: telText,
          Email: emailText,
          InvoicePayTotal: invoicePayTotal,
          Contractor: contractorId ? contractorId : agencyId,
          ContractorNm: contractorNm ? contractorNm : AgencyName,
          EndUserId: endUserId,
          EndUserNm: endUserNm,
          ShippingDestination: shipping,
          ShippingDestinationNm: shippingNm,
          comments: oInput,
          OrderChargeName: OrderChargeNameText, /*担当者名 */
          OrderRemarks: OrderRemarksText, /*注文備考 */
          OrderShipContactNm: OrderShipContactNmText, /*出荷先担当者名 */
          OrderShippingNotes: oInput, /*出荷備考 */
          OrderTelNo: shippingDestinationTel,
          OrderAddress: shippingDestinationPlaceName,
          OrderPostCode: shippingDestinationPostCode,
          ShippingOrderNumber: ShippingOrderNumberText, /*出荷先注文番号 */
        },
      })
    );
    updateArAgencyInfos("1", (updateStatus) => {
      if (updateStatus) {
        history.push({
          pathname: "/InventorySearch",
          state: { QuoteNumber: location.state.QuoteNumber, DesiredDeliveryDate: orderDataText },
        });
      }
    });
  }

  const updateArAgencyInfos = async (updatStatus, callback) => {
    for (let j = 0; j < orderinfo.length; j++) {
      let item = orderinfo[j];
      let hopedateValue = moment(item.selectedDate).utcOffset(9).format('YYYY-MM-DD');
      if (hopedateValue.length > 17) {
        hopedateValue = hopedateValue + "T00:00.000Z";
      } else if (hopedateValue.length > 0) {
        hopedateValue = hopedateValue + "T00:00:00.000Z";
      } else {
        hopedateValue = "";
      }
      const result = await API.graphql(
        graphqlOperation(updateArAgencyOrderInfo, {
          input: {
            id: orderinfo[j].id,
            Agency_id: orderinfo[j].Agency_id,
            QuoteNumber: location.state.QuoteNumber,
            Product_Code: orderinfo[j].Product_Code,
            OrderQuantity: parseFloat(orderinfo[j].OrderQuantity),
            GroupName: SalesOfficeName /*営業所名 */,
            Contractor: contractorId ? contractorId : agencyId,
            ContractorNm: contractorNm ? contractorNm : AgencyName,
            EndUserId: item.EndUserId,
            EndUserNm: item.EndUserNm,
            ShippingDestination: item.ShippingDestination,
            ShippingDestinationNm: item.ShippingDestinationNm,
            comments: item.OrderShippingNotes,
            OrderChargeName: OrderChargeNameText,
            OrderRemarks: item.OrderRemarks,
            OrderShipContactNm: item.OrderShipContactNm, /*出荷先担当者名 */
            OrderShippingNotes: item.OrderShippingNotes, /*出荷備考 */
            OrderTelNo: item.OrderTelNo,
            OrderAddress: item.OrderAddress,
            OrderPostCode: item.OrderPostCode,
            ShippingOrderNumber: item.ShippingOrderNumber, /*出荷先注文番号 */
            OrderStatus: updatStatus /*登録完了 一時保存*/,
            OrderNumber: item.OrderNumber /* 発注番号 */,
            DesiredDeliveryDate: hopedateValue

          },
        })
      );
    }
    callback(true);
  }

  const updateArOrders = async (saveOrder) => {

    if (!saveOrder) {
      if (orderDataText === "") {
        handleSetMsgId("エラー");
        handleSetMsgText("希望納期を入力してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
      }
      if (ShippingData === "") {
        handleSetMsgId("エラー");
        handleSetMsgText("出荷先を選択してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
      }
      if (endUserGroup === "") {
        handleSetMsgId("エラー");
        handleSetMsgText("エンドユーザーを選択してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
      }

      for (const index in orderinfo) {
        const item = orderinfo[index];
        if (!item.ShippingDestination) {
          handleSetMsgId("エラー");
          handleSetMsgText("出荷先を選択してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          return;
        }
        else if (!item.EndUserId) {
          handleSetMsgId("エラー");
          handleSetMsgText("エンドユーザーを選択してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          return;
        }
      }

    }

    let date2;
    if (orderDataText.length > 17) {
      date2 = orderDataText + "T00:00.000Z";
    } else if (orderDataText.length > 0) {
      date2 = orderDataText + "T00:00:00.000Z";
    } else {
      date2 = "";
    }
    date2 = date2.replaceAll("/", "-")
    let updatStatus = saveOrder ? "1" : "2"; //1：一時保存 2：登録完了
    await API.graphql(
      graphqlOperation(updateArOrder, {
        input: {
          id: orderID,
          OrderStatus: updatStatus /*登録完了 一時保存*/,
          QuoteNumber: location.state.QuoteNumber /*登録番号*/,
          Insertperson: RegisteredPerson /*登録者*/,
          OrderNumber: orderNoText /*発注番号*/,
          DesiredDeliveryDate: date2 /*希望納期日 */,
          ChouNumber: orderNumber2 /*注文番号 */,
          OrderPerson: ProcurementStaff /*調達担当者*/,
          RegistrationDate: new Date() /*登録日*/,
          ChouDate: OrderDate /*注文日*/,
          CompanyName: CompanyName /*会社名 */,
          EstimatedShippingDate: EstimatedShippingDate /*出荷予定日 */,
          ShipDate: ShipDate /*出荷日 */,
          GroupName: SalesOfficeName /*営業所名 */,
          ARBSalesRepresentative: ARBSalesRepresentative /*ARB担当営業 */,
          DeliveryYtDate: DeliveryYtDate /*納品予定日 */,
          DeliveryDate: DeliveryDate /*納品日 */,
          TelNo: telText,
          Email: emailText,
          InvoicePayTotal: invoicePayTotal,
          Contractor: contractorId ? contractorId : agencyId,
          ContractorNm: contractorNm ? contractorNm : AgencyName,
          EndUserId: endUserId,
          EndUserNm: endUserNm,
          ShippingDestination: shipping,
          ShippingDestinationNm: shippingNm,
          comments: oInput,
          OrderChargeName: OrderChargeNameText, /*担当者名 */
          OrderRemarks: OrderRemarksText, /*注文備考 */
          OrderShipContactNm: OrderShipContactNmText, /*出荷先担当者名 */
          OrderShippingNotes: oInput, /*出荷備考 */
          OrderTelNo: shippingDestinationTel,
          OrderAddress: shippingDestinationPlaceName,
          OrderPostCode: shippingDestinationPostCode,
          ShippingOrderNumber: ShippingOrderNumberText, /*出荷先注文番号 */
        },
      })
    );
    //詳細な価格を変更するにはボタンをクリックしてください
    orderinfo.map(async (item) => {
      try {
        await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
          input: {
            id: item.id,
            Agency_id: item.Agency_id,
            Product_Code: item.Product_Code,
            Wholes_Price: item.Wholes_Price
          },
        }))
      } catch (err) {
        console.log(err)
      }
    })
    updateArAgencyInfos(updatStatus, (updateStatus) => {
      if (updatStatus) {
        if (saveOrder) {
          history.push({
            pathname: "/Orderlist",
            state: { QuoteNumber: location.state.QuoteNumber },
          });
        } else {
          history.push({
            pathname: "/OrderConfirmation",
            state: { QuoteNumber: location.state.QuoteNumber },
          });
        }
      }
    });
  };
  function txtChangedOrderNo(e, item) {
    handleSetOrderNoText(e.target.value, item);
  }
  function txtChangedOrderShipContactNmText(e, item) {
    handleSetOrderShipContactNmText(e.target.value, item);
  }
  function txtChangedShippingOrderNumberText(e, item) {
    handleShippingOrderNumberText(e.target.value, item);
  }
  function txtChangedOrderChargeNameText(e) {
    handleSetOrderChargeNameText(e.target.value);
  }
  function txtChangedOrderRemarksText(e, item) {
    handleSetOrderRemarksText(e.target.value, item);
  }
  function txtChangedOrderData(e) {
    handleSetOrderDataText(e.target.value);
  }
  const [state, setState] = React.useState({
    checkedB: true,
  });
  const [oInput, setOinput] = React.useState("");
  function changeOinput(event, item) {
    if (item) {
      item.OrderShippingNotes = event.target.value;
    }
    setOinput(event.target.value);
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  function toPoint(percent) {
    let str = percent.replace("%", "");
    str = str / 100;
    return str;
  }
  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
      sort: true,
      editable: false,
      headerStyle,
    },
    {
      dataField: "Product_Code",
      text: "品目",
      sort: true,
      editable: false,
      style: { margin: "0px", width: "200px" },
      headerStyle,
    },
    {
      dataField: "Product_Name_j",
      text: "品名",
      sort: true,
      editable: false,
      style: { margin: "0px", width: "700px" },
      headerStyle,
    },
    {
      dataField: "biko",
      text: "備考",
      sort: true,
      editable: false,
      style: { margin: "0px", width: "150px" },
      headerStyle,
      formatter: (cellContent, row) => {
        if (row.Wholes_Rate !== "-") {
          return <span></span>;
        } else {
          return <span>{row.Wholes_Price}</span>;
        }
      },
    },
    {
      dataField: "OrderQuantity",
      text: "数量",
      type: "number",
      align: "right",
      style: { margin: "0px", width: "100px" },
      sort: true,
      headerStyle,
    }, {
      dataField: "Wholes_Price",
      text: "御注文金額",
      sort: true,
      align: "right",
      editable: false,
      style: { margin: "0px", width: "160px" },
      headerStyle,
      formatter: (cellContent, row) => {
        if (row.Wholes_Rate === "-") {
          return <span>-</span>;
        } else {
          return <span>
            {numtomoney(
              Math.round(
                parseFloat(row.OrderQuantity) *
                parseFloat(moneytonum(row.Wholes_Price))
              )
            )}
          </span>;
        }

      },
    },
  ];
  //特価設定
  const specialDeal = async function (order) {
    let postageSum = 0;
    const res = order.map(async (item) => {
      try {
        const OrderQuantity = item.OrderQuantity ? parseFloat(item.OrderQuantity) : 0
        let sum = OrderQuantity * Number(moneytonum(item.Wholes_Price))
        let postage = ((OrderQuantity * Number(moneytonum(item.Wholes_Price))) > 0 && (OrderQuantity * Number(moneytonum(item.Wholes_Price)) <= 7000)) ? 1200 : 0
        await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
          input: {
            id: item.id,
            Agency_id: item.Agency_id,
            Product_Code: item.Product_Code,
            invoiceSubtotal: sum,
            invoiceTotal: postage,
            invoiceFax: (sum + postage) * TAX_RATE,
            invoicePayTotal: sum + (sum + postage) * 0 + postage,
            unitPrice: Math.round((sum + (sum + postage) * 0 + postage) / parseInt(item.OrderQuantity)),
          },
        }))
        return { Postage: postage }
      } catch (err) {
        console.log(err)
      }
    })
    const result = await Promise.all(res)
    result.map((item) => {
      postageSum += item.Postage
    })
    let sum = 0;
    for (let j = 0; j < order.length; j++) {
      sum = sum + (parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].Wholes_Price)))
    }
    invoiceSubtotal = numtomoney(sum, 0);
    invoiceTotal = numtomoney(postageSum, 0);
    invoicePayTotal = numtomoney(Math.round(sum + (sum + postageSum) * 0 + postageSum), 0);
    invoiceFax = numtomoney(Math.round((sum + postageSum) * TAX_RATE), 0);
    handleSetflg(invoicePayTotal);
  }
  return (
    <Container
      style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
    >

      <div
        style={{
          borderLeft: "4px solid #804F29",
          marginTop: "1rem",
          paddingLeft: "4px",
          marginBottom: "1rem",
          display:'flex',
          justifyContent:'space-between'
        }}
      >
        <h4>
          注文登録
        </h4>
        <Button variant="contained" color="primary"
        hidden={!OrderCancelFlag || OrderCancelFlag === "1"}
        disabled ={OrderCancelFlag && OrderCancelFlag === "1"}
        onClick={OrderCancelClick}>
         注文キャンセル
        </Button>
      </div>

      <Grid style={{ backgroundColor: "white", padding: "1em", border: '3px solid #804F29' }}>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text className={classes.textlabel} id="basic-name">
                登録番号
              </InputGroup.Text>
              <FormControl
                autocomplate="off"
                placeholder="納品請求書の宛名にご指定がある場合ご入力下さい"
                aria-label=""
                aria-describedby="basic-name"
                id="mitumoriName"
                className={classes.textField}
                value={location.state.QuoteNumber}
                onChange={(e) => {
                  txtChangedOrderChargeNameText(e);
                }}
                readOnly={true}
                maxLength="20"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "flex", alignItems: "left" }}>
              {/* <span className="">受注先：　　　　</span> */}
              <InputGroup.Text className={classes.textlabel} id="basic-name">
                発注先名
              </InputGroup.Text>
              <FormControlsk style={{ width: "360px" }}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contractorGroup}
                  onChange={handleContractorChange}
                  MenuProps={MenuProps}
                  autoWidth
                  disabled={orderReadonly}
                  input={<BootstrapInput />}
                >
                  {selectContractorOptions.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, contractorGroup, theme)}
                    >
                      {name.split(":")[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControlsk>
            </div>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text className={classes.textlabel} id="basic-name">
                担当者名
              </InputGroup.Text>
              <FormControl
                autocomplate="off"
                placeholder="納品請求書の宛名にご指定がある場合ご入力下さい"
                aria-label=""
                aria-describedby="basic-name"
                id="mitumoriName"
                className={classes.textField}
                value={OrderChargeNameText}
                onChange={(e) => {
                  txtChangedOrderChargeNameText(e);
                }}
                disabled={orderReadonly}
                maxLength="20"
              />
            </InputGroup>
          </Col>
        </Row>
      </Grid>

      <Row
        style={{
          // borderLeft: "4px solid #804F29",
          marginTop: "1rem",
          paddingLeft: "4px",
          // marginBottom: "1rem",
        }}
      >
        <h4>
        </h4>
      </Row>
      <Grid
        style={{
          backgroundColor: "white",
          padding: "1em",
          //paddingRight: "2em",
          //marginTop: "0.5",
        }}
      >
        <Row>
          {
            orderinfo.map(item => {
              return (
                <TableContainer key={item.id} style={{ marginTop: 10 }}>
                  <Table style={{ border: '3px solid #804F29' }}>
                    <TableHead>
                      <TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="center">品目</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="center" >品名</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="center" >数量</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="center" >価格</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow style={{ borderBottom: '3px solid #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="center" >{item.Product_Code}</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }}>{item.Product_Name_j}</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="right" >{item.OrderQuantity}</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} align="right" >{item.Wholes_Price}</TableCell>
                      </TableRow>
                      <TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>受注番号</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29',padding:'4px' }} colSpan={3}>
                          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                          <span style={{marginLeft:'20px'}}>{item.OrderDetailsNumber}</span>
                        <span style={{marginRight:'100px',backgroundColor:'#ff0000',color:'white',padding:'10px',borderRadius:'5px'}}
                        hidden = {item.CancelFlag !== "1" && item.ShipmentStatus !== "N"}
                        >
                        {item.ShipmentStatus === "N" ? "注文キャンセル済" : item.CancelFlag === "1" ? "注文キャンセル処理中" : ""}
                        </span>
                          </div>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: '1px dashed #804F29' }}> 発注先注文番号</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }}>
                          <FormControl
                            autocomplate="off"
                            aria-label=""
                            aria-describedby="basic-name"
                            id="orderNo"
                            className={classes.textField}
                            value={item.OrderNumber}
                            style={{ width: '70vh' }}
                            onChange={(e) => {
                              txtChangedOrderNo(e, item);
                            }}
                            maxLength="20"
                            disabled={orderReadonly}
                          />
                        </TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }}> 希望納期</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }}>  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                          <DatePicker
                            className={classes.datePicker}
                            disablePast
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            id="hopedate"
                            label=""
                            helperText=""
                            error={false}
                            value={item.selectedDate ? item.selectedDate : selectedDate}
                            onChange={(e) => {
                              handleSelectedDate(e,item)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            readOnly={orderReadonly}
                            shouldDisableDate={disableWeekends}
                          />
                        </MuiPickersUtilsProvider></TableCell>
                      </TableRow>
                      <TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>発注先備考</TableCell>
                        <TableCell colSpan={3}><FormControl
                          autocomplate="off"
                          placeholder="弊社へのご連絡事項がございます場合ご入力ください。　例)同一ロットでお願いします"
                          aria-label=""
                          aria-describedby="basic-name"
                          id="groupName"
                          className={classes.textField}
                          style={{ width: '70vh' }}
                          value={item.OrderRemarks}
                          onChange={(e) => {
                            txtChangedOrderRemarksText(e, item);
                          }}
                          maxLength="120"
                          disabled={orderReadonly}
                        /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: '1px dashed #804F29' }}><p style={{ width: "60px", height: "30px", textAlign: "center", lineHeight: "30px", background: "rgb(236, 180, 69)", color: "white" }}>必須</p>出荷先</TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                          <div>
                            <Box sx={{ width: '500px' }}>
                              {/* <FormHelperText>登録が無い場合は新規申請して下さい</FormHelperText> */}
                              <FormHelperText>登録が無い場合は新規申請して下さい</FormHelperText>
                              <FormControlsk fullWidth>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                  // placeholder="ご登録が無い場合はマスタ管理より新規申請下さい"
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={item.ShippingData}
                                  // onChange={handleChange1}
                                  onChange={(e) => {
                                    handleChange1(
                                      e.target.value, item
                                    );
                                  }}
                                  input={<BootstrapInput />}
                                  MenuProps={MenuProps}
                                  autoWidth
                                  disabled={orderReadonly}
                                >
                                  <MenuItem value="">
                                    <em>　</em>
                                  </MenuItem>
                                  {ShipingOptions.Invs.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, ShippingData, theme)}
                                    >
                                      {name.split(":")[1]}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControlsk>
                            </Box>
                            <div style={{
                              width: '70vh',
                              marginTop: 10,
                              backgroundColor: "white",
                              // paddingLeft: "2rem",
                              borderTop: "4px solid #ECB445",
                              borderBottom: "4px solid #ECB445",
                              borderRight: "4px solid #ECB445",
                              borderLeft: "40px solid #ECB445",
                            }}>
                              <InputGroup className="mb-3">
                                <InputGroup.Text
                                  className={classes.textlabel}
                                  id="basic-name"
                                >
                                  出荷先名
                                </InputGroup.Text>
                                <FormControl
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby="basic-name"
                                  id="mitumoriNo"
                                  className={classes.textField}
                                  value={item.ShippingDestinationNm}
                                  //onChange={(e) => { shippingDestinationName1() }}
                                  readOnly={true}
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text
                                  className={classes.textlabel}
                                  id="basic-name"
                                >
                                  住　　所
                                </InputGroup.Text>
                                <FormControl
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby="basic-name"
                                  id="orderNo"
                                  className={classes.textField}
                                  value={item.OrderAddress}
                                  // onChange={(e) => { txtChangedOrderNo(e) }
                                  readOnly={true}
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text
                                  className={classes.textlabel}
                                  id="basic-name"
                                >
                                  電話番号
                                </InputGroup.Text>
                                <FormControl
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby="basic-name"
                                  id="orderNo"
                                  className={classes.textField}
                                  value={item.OrderTelNo}
                                  //onChange={(e) => { txtChangedOrderNo(e) }}
                                  readOnly={true}
                                  defaultValue=""
                                />
                              </InputGroup>
                            </div>

                          </div>
                        </TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: '1px dashed #804F29' }}>
                          出荷先注文番号
                        </TableCell>
                        <TableCell colSpan={3} style={{ border: '1px dashed #804F29' }}>
                          <div style={{ display: 'flex' }}>
                            <FormControl
                              autocomplate="off"
                              placeholder="発注先注文番号と異なる場合はご入力下さい"
                              aria-label=""
                              aria-describedby="basic-name"
                              id="EstimatedShippingDate"
                              className={classes.textKField}
                              value={item.ShippingOrderNumber}
                              onChange={(e) => {
                                txtChangedShippingOrderNumberText(e, item);
                              }}
                              style={{ minWidth: '30%' }}
                              disabled={orderReadonly}
                              maxLength="20"
                            />
                            <InputGroup className="mb-3">
                              <InputGroup.Text className={classes.textKField} id="basic-name">
                                出荷先担当者名
                              </InputGroup.Text>
                              <FormControl
                                autocomplate="off"
                                placeholder="納品ご指定がある場合ご入力下さい"
                                aria-label=""
                                aria-describedby="basic-name"
                                id="OrderShipContactNmid"
                                className={classes.textKField}
                                value={item.OrderShipContactNm}
                                onChange={(e) => {
                                  txtChangedOrderShipContactNmText(e, item);
                                }}
                                maxLength="40"
                                disabled={orderReadonly}
                              />
                            </InputGroup>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>出荷備考</TableCell>
                        <TableCell colSpan={3}><FormControl
                          autocomplate="off"
                          placeholder="送り状備考に記載希望内容　　例)午前着にして下さい。"
                          aria-label=""
                          aria-describedby="basic-name"
                          id="groupName"
                          className={classes.textField}
                          style={{ width: '70vh' }}
                          value={item.OrderShippingNotes}
                          onChange={(e) => {
                            changeOinput(e, item);
                          }}
                          maxLength="30"
                          disabled={orderReadonly}
                        /></TableCell>
                      </TableRow>
                      <TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>
                          <p style={{ width: "50px", height: "30px", textAlign: "center", lineHeight: "30px", background: "rgb(236, 180, 69)", color: "white" }}>必須</p>エンドユーザー
                        </TableCell>
                        <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                          <Box sx={{ width: 500 }}>
                            <FormHelperText>登録が無い場合は新規申請して下さい</FormHelperText>
                            <FormControlsk fullWidth>
                              <InputLabel id="demo-simple-select-label"></InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={item.endUserGroup}
                                label=""
                                onChange={(e) => {
                                  handleEndUserChange(e, item)
                                }}
                                input={<BootstrapInput />}
                                MenuProps={MenuProps}
                                // autoWidth
                                disabled={orderReadonly}
                              >
                                <MenuItem value="">
                                  <em>　</em>
                                </MenuItem>
                                {endUserOptions.Invs.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, endUserGroup, theme)}
                                  >
                                    {name.split(":")[1]}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControlsk>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {displaymemo && (<TableRow style={{ border: '1px dashed #804F29'}}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>社内メモ</TableCell>
                        <TableCell colSpan={3}><FormControl
                          autocomplate="off"
                          placeholder=""
                          aria-label=""
                          aria-describedby="basic-name"
                          id="CompanMemo"
                          className={classes.textField}
                          style={{ width: '70vh' }}
                          value={item.CompanMemo}
                          maxLength="30"
                          disabled={orderReadonly}
                        /></TableCell>
                      </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            })
          }
        </Row>


      </Grid>
      <Row>
        <Col>
          <Row>
            <h4
              style={{
                // color: "black",
                // borderLeft: "4px solid #804F29",
                // paddingLeft: "5px",
                // marginTop: "1rem",
                // marginTop: "1rem",
                paddingLeft: "4px",
                // marginBottom: "1rem",
                color: "black",
              }}
            >
            </h4>
          </Row>
          <Row style={{marginBottom: "2rem", marginTop: '30px',backgroundColor: "white",padding: "1rem",paddingRight: 0}}>
            {/* <Col xs={12} style={{ padding: 0 }}>
                <BootstrapTable
                  className={classes.table1}
                  bootstrap4
                  keyField="id"
                  data={orderinfo}
                  columns={columns}
                />
              </Col> */}
              <Col style={{padding: "1rem"}}>
                  {orderinfo.length > 0 && orderinfo[0].CreateFrom ? <div>
                    <TableContainer>
                    <TableBody >
                      <TableRow >
                        <TableCell className={classes.noframeTableCell}>指定LOTNo</TableCell>
                        <TableCell className={classes.noframeTableCell} >：{orderinfo[0]['f_SpecifiedLotNo']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>同一Lot区分</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_SameLotClassification'] === '1' ? '同一LOT指定' : orderinfo[0]['f_SameLotClassification']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>指定納期</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{moment(orderinfo[0].DesiredDeliveryDate).format("YYYY-MM-DD")}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>特価管理No</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_SpecialpPriceManagementNo']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>発注備考</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_OrderNotes']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>連絡事項</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_Notifications']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先郵便番号</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryPostCode']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先住所</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DropShippingAddress']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先電話番号</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryTel']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先FAX番号</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryFax']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先受取人</TableCell>
                        <TableCell className={classes.noframeTableCell}> ：{orderinfo[0]['f_DropShippingRecipient']}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noframeTableCell}>直送先名</TableCell>
                        <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryName']}</TableCell>
                      </TableRow>
                    </TableBody>
                    </TableContainer>
                  </div> : <></>}
              </Col>
      <Col style={{ backgroundColor: "white" }}>
              <TableContainer>
                <Table
                  className={classes ? classes.table : undefined}
                  aria-label="spanning table"
                  style={{ border: "none" }}
                >
                  <TableBody>
                    <TableRow>
                      <div>
                      </div>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        製品合計（税抜）
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {invoiceSubtotal}円
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <div>
                      </div>
                      <TableCell
                        style={{
                          borderBottom: "1px solid gray",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        送料／手数料（税抜）
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "1px solid gray",
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {invoiceTotal}円
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        消費税（10%）
                      </TableCell> */}
                      {/* <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {invoiceFax}円
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <div>
                        <span style={{ color: "red", 'font-size': '12px' }}>※単価が「ー」となっている品目は御見積商品となり、<br></br>
                          該当商品が含まれている場合は、合計金額が表示されません。</span>
                      </div>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        注文合計（税抜）
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {invoicePayTotal}円
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <div>
                      </div>
                      <TableCell>
                        消費税については請求書にてご確認ください。
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid>
                <Row style={{ marginBottom: "1em" }}>
                  <Col xs={12} md={8} style={{ display: "none" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedB}
                          onChange={handleChange}
                          name="checkedB"
                          color="secondary"
                        />
                      }
                      label="Admin専用ボックス与信確認しないチェックボックス"
                    />
                  </Col>
                </Row>
                <div hidden={flg}></div>
              </Grid>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "end",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          xs={12}
          align="right"
        >
          <Button
            className="btn btn-dark "
            size="large"
            disabled={orderReadonly}
            style={{
              marginLeft: "10px",
            }}
            onClick={() => { goback(); }}
          >
            {" "}
            商品選択へ戻る
          </Button>
          <Button
            className="btn btn-dark"
            size="large"
            disabled={orderReadonly}
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              orderClick(true);
              // window.location.reload();
            }}
          >
            一時保存
          </Button>
          <Button
            className="btn btn-danger "
            size="large"
            disabled={orderReadonly}
            style={{
              marginLeft: "10px",
            }}
            hidden={addressList.length === 0}
            // hidden={addressList.length === 0} TODO:fix hidden after test
            onClick={() => {
              orderClick(false);
            }}
          >
            登録依頼
          </Button>
        </Col>
      </Row>

      <Dialog
        open={open2}
        onClose={handleCloseNG2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOK2} color="primary" autoFocus>
            {msgbtnOK}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          注文をキャンセルしますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary" autoFocus>
          キャンセル
          </Button>
          <Button onClick={handleCancelOrder} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </Container >
  );
};

export default Order;
   /* eslint-enable no-unused-vars */
