
export const queryArInventoryByProductCodeIndex = /* GraphQL */ `
  query queryArInventoryByProductCodeIndex(
    $Agency_Price_Group: String!
    $Product_Code: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArInventoryByProductCodeIndex(
      Agency_Price_Group: $Agency_Price_Group
      Product_Code: $Product_Code
      first: $limit
      after: $nextToken
    ) {
      items {
        Admin_Agency_Price_Group
        Admin_Group
        Agency_Price_Group
        Basic_Quantity_Unit
        Campaign_partition_rate
        Campaign_price
        Capacity_Display
        Capacity_Unit
        Capacity_Value
        Catarog_Price
        Category1
        Category2
        Delivery_Term
        Expiration_date
        LawsRegulations
        OrderQuantity
        Plant_Name
        Price
        Product_Code
        Product_Group1
        Product_Group2
        Product_Name_e
        Product_Name_j
        Purchasing_Document_Type
        Purchasing_Organization
        SalesGroup
        Quantity
        Sales_Document_Type
        Salesperson
        Segment
        SegmentContent
        Storage_Location
        Storage_Location_U
        Storage_Temp
        Supplier
        Supplier2
        Supplier_LotNo
        Tax_Code
        TempInventoryQuantity
        Wharehouse_Name
        Wholes_Price
        Wholes_Rate
        createdAt
        id
        lastupdate
        purchasing_Group
        updatedAt
      }
      nextToken
    }
  }
`;

export const customerByeMail = /* GraphQL */ `
  query customerByeMail(
    $Agency_Email: String
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByeMail(
      Agency_Email: $Agency_Email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Agency_id
        SubId
        Agency_Price_Group
        Agency_Name
        Company_Name
        Agency_Email
        User_Name
        DepartmentName
        FirstName
        Tel
        Kana_Name_Sei
        Kana_Name_Mei
        Address
        PostCode
        Fax
        Permission
        AssigneeCode
        SalesGroup
        UsageSystem
        UsageCategory
      }
      nextToken
    }
  }
`;

export const listArCampaigns = /* GraphQL */ `
  query ListArCampaigns(
    $id: ID
    $filter: ModelArCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArCampaigns(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Title
        Term_From
        Term_To
        Wholes_Rate_Condision
        Text
        Discription
        Notification
        Remarks
        filePDF
        PDFurl
        updatedAt
        createdAt
        UserAuth
        fileName
      }
      nextToken
    }
  }
`;


export const agencyGroupSortList = /* GraphQL */ `
  query AgencyGroupSortList(
    $Agency_Price_Group: ID
    $lastupdate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AgencyGroupSortList(
      Agency_Price_Group: $Agency_Price_Group
      lastupdate: $lastupdate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Product_Code
        Supplier
        Supplier2
        Product_Name_j
        Quantity
        Capacity_Display
        Catarog_Price
        Wholes_Rate
        Wholes_Price
        Delivery_Term
        Storage_Temp
        Capacity_Unit
        Capacity_Value
        Product_Name_e
        Plant_Name
        Wharehouse_Name
        Category1
        Category2
        Product_Group1
        Product_Group2
        Supplier_LotNo
        Expiration_date
        lastupdate
        updatedAt
        createdAt
        Campaign_partition_rate
        Campaign_price
        LawsRegulations
        OrderQuantity
        TempInventoryQuantity
        SalesGroup
        Purchasing_Document_Type
	      Purchasing_Organization
	      purchasing_Group
	      Tax_Code
	      Storage_Location
        Storage_Location_U
        Basic_Quantity_Unit
        Sales_Document_Type
        Salesperson
        Segment
        Agency_Price_Group
        Price
      }
      nextToken
    }
  }
`;

export const agencyGroupSortLastupdate = /* GraphQL */ `
  query AgencyGroupSortList(
    $Agency_Price_Group: ID
    $lastupdate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AgencyGroupSortList(
      Agency_Price_Group: $Agency_Price_Group
      lastupdate: $lastupdate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        lastupdate
      }
      nextToken
    }
  }
`;

export const AdminAgencyPriceGroupSortList = /* GraphQL */ `
  query AdminAgencyPriceGroupSortList(
    $Admin_Group: String
    $sortDirection: ModelSortDirection
    $filter: ModelArInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdminAgencyPriceGroupSortList(
      Admin_Group: $Admin_Group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Admin_Agency_Price_Group
      }
      nextToken
    }
  }
`;

export const orderByQuoteNumber = /* GraphQL */ `
  query orderByQuoteNumber(
    $QuoteNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelArOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByQuoteNumber(
      QuoteNumber: $QuoteNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      OrderStatus
      QuoteNumber
      AgencyID
      AgencyEmail
      CompanyName
      AgencyName
      Insertperson
      OrderNumber
      DesiredDeliveryDate
      ChouNumber
      OrderPerson
      RegistrationDate
      ChouDate
      CompanyName
      EstimatedShippingDate
      ShipDate
      GroupName
      ARBSalesRepresentative
      DeliveryYtDate
      DeliveryDate
      TelNo
      Email
      InvoicePayTotal
      Contractor
      ShippingDestination
      ContractorNm
      ShippingDestinationNm
      comments
      ShippingBlock
      EndUserId
      EndUserNm
      OrderChargeName
      OrderRemarks
      OrderShipContactNm
      OrderShippingNotes
      OrderTelNo
      OrderAddress
      OrderPostCode
      ShippingOrderNumber
      OutputFlag
      }
      nextToken
    }
  }
`;


export const listArAgencyOrderInfos = /* GraphQL */ `
  query listArAgencyOrderInfos(
    $QuoteNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArAgencyOrderInfos(
      QuoteNumber: $QuoteNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Agency_id
        QuoteNumber
        Product_Code
        Product_Name_j
        OrderQuantity
        Wholes_Rate
        Wholes_Price
        Campaign_price
        SalesGroup
        Quantity
        Product_id
        delflg
        OutputFlag
        DetailNo
        Purchasing_Document_Type
        Purchasing_Organization
        purchasing_Group
        Supplier
        Tax_Code
        Storage_Location
        Sales_Document_Type
        Storage_Location_U
        Basic_Quantity_Unit
        Salesperson
        SalespersonName
        Segment
        Agency_Price_Group
        OrderDetailsNumber
	      RegistrationDate
	      AgencyName
	      AgencyEmail
	      GroupName
	      DesiredDeliveryDate
      	OrderNumber
      	ShippingBlock
      	ShippingDestination
      	ShippingDestinationNm
	      Contractor
	      ContractorNm
	      EndUserId
	      EndUserNm
	      CompanyName
	      OrderRemarks
	      OrderShippingNotes
	      OrderTelNo
	      OrderAddress
	      OrderPostCode
	      ShippingOrderNumber
	      OrderChargeName
        OrderStatus
	      OrderShipContactNm
	      comments
        ShippingInvoice
        ShipmentQuantity
        invoiceSubtotal
         campaignPriceTotal
         EndUserPriceTotal
         quantityPriceTotal
         invoiceFax
         invoicePayTotal
         unitPrice
         invoiceTotal
SalesUnitPriceScale
PurchUnitPriceScale
ShortestRefTime
RegQty
BulkSales
DTStockTier
DTNormalLT
FukuiSeinoLT
DropDeliveryTime
SalesType
UnitPriceAftDis
InHouseRe
FukuiWarehouseCSVRe
PurchOrderRe
TorayEDIRe
PriceTypePurchase
Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
EndUserIdDirectDelivery
SubId
TorayShipCode
Plant
SalesRepresentative
User_Name
AssigneeCode
      }
      nextToken
    }
  }
`;
export const listArAgencyOrderInfosEmd = /* GraphQL */ `
  query listArAgencyOrderInfosEmd(
    $QuoteNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArAgencyOrderInfosEmd(
      QuoteNumber: $QuoteNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Product_Code
        OrderQuantity
        Product_id
        DesiredDeliveryDate
        SelectContractor
        SelectContractorNm
      }
      nextToken
    }
  }
`;
export const listArAgencyOrderInfoByDetailsNumber = /* GraphQL */ `
  query listArAgencyOrderInfoByDetailsNumber(
    $OrderDetailsNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArAgencyOrderInfoByDetailsNumber(
      OrderDetailsNumber: $OrderDetailsNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Agency_id
        QuoteNumber
        Product_Code
        Product_Name_j
        OrderQuantity
        Wholes_Rate
        Wholes_Price
        Campaign_price
        SalesGroup
        Quantity
        Product_id
        delflg
        OutputFlag
        DetailNo
        Purchasing_Document_Type
        Purchasing_Organization
        purchasing_Group
        Supplier
        Tax_Code
        Storage_Location
        Sales_Document_Type
        Storage_Location_U
        Basic_Quantity_Unit
        Salesperson
        SalespersonName
        Segment
        Agency_Price_Group
        OrderDetailsNumber
	      RegistrationDate
	      AgencyName
	      AgencyEmail
	      GroupName
	      DesiredDeliveryDate
      	OrderNumber
      	ShippingBlock
      	ShippingDestination
      	ShippingDestinationNm
	      Contractor
	      ContractorNm
	      EndUserId
	      EndUserNm
	      CompanyName
	      OrderRemarks
	      OrderShippingNotes
	      OrderTelNo
	      OrderAddress
	      OrderPostCode
	      ShippingOrderNumber
	      OrderChargeName
        OrderStatus
	      OrderShipContactNm
	      comments
        ShippingInvoice
        ShipmentQuantity
        invoiceSubtotal
        campaignPriceTotal
        EndUserPriceTotal
        quantityPriceTotal
        invoiceFax
        invoicePayTotal
        unitPrice
        invoiceTotal
        CancelFlag
        ShipmentStatus
        f_SpecifiedLotNo
        f_SameLotClassification
        f_SpecialpPriceManagementNo
        f_OrderNotes
        f_Notifications
        f_DirectDeliveryPostCode
        f_DropShippingAddress
        f_DirectDeliveryTel
        f_DirectDeliveryFax
        f_DropShippingRecipient
        f_DirectDeliveryName
        CreateFrom
        SalesUnitPriceScale
        PurchUnitPriceScale
        ShortestRefTime
        RegQty
        BulkSales
        DTStockTier
        DTNormalLT
        FukuiSeinoLT
        DropDeliveryTime
        SalesType
        UnitPriceAftDis
        InHouseRe
        FukuiWarehouseCSVRe
        PurchOrderRe
        TorayEDIRe
        PriceTypePurchase
        Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
EndUserIdDirectDelivery
SubId
TorayShipCode
Plant
SalesRepresentative
User_Name
AssigneeCode
      }
      nextToken
    }
  }
`;
export const listArAgencyOrderInfoByAgencyId = /* GraphQL */ `
  query listArAgencyOrderInfoByAgencyId(
    $Agency_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArAgencyOrderInfoByAgencyId(
      Agency_id: $Agency_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Agency_id
        QuoteNumber
        Product_Code
        Product_Name_j
        OrderQuantity
        Wholes_Rate
        Wholes_Price
        Campaign_price
        SalesGroup
        Quantity
        Product_id
        delflg
        OutputFlag
        DetailNo
        Purchasing_Document_Type
        Purchasing_Organization
        purchasing_Group
        Supplier
        Tax_Code
        Storage_Location
        Sales_Document_Type
        Storage_Location_U
        Basic_Quantity_Unit
        Salesperson
        SalespersonName
        Segment
        Agency_Price_Group
        OrderDetailsNumber
	      RegistrationDate
	      AgencyName
	      AgencyEmail
	      GroupName
	      DesiredDeliveryDate
        DeliveryDate
      	OrderNumber
      	ShippingBlock
      	ShippingDestination
      	ShippingDestinationNm
	      Contractor
	      ContractorNm
	      EndUserId
	      EndUserNm
	      CompanyName
	      OrderRemarks
	      OrderShippingNotes
	      OrderTelNo
	      OrderAddress
	      OrderPostCode
	      ShippingOrderNumber
	      OrderChargeName
        OrderStatus
	      OrderShipContactNm
	      comments
        ShippingInvoice
        ShipmentQuantity
        invoiceSubtotal
         campaignPriceTotal
         EndUserPriceTotal
         quantityPriceTotal
         invoiceFax
         invoicePayTotal
         unitPrice
         invoiceTotal
         ShipmentStatus
         SalesUnitPriceScale
PurchUnitPriceScale
ShortestRefTime
RegQty
BulkSales
DTStockTier
DTNormalLT
FukuiSeinoLT
DropDeliveryTime
SalesType
UnitPriceAftDis
InHouseRe
FukuiWarehouseCSVRe
PurchOrderRe
TorayEDIRe
PriceTypePurchase
Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
EndUserIdDirectDelivery
SubId
TorayShipCode
Plant
SalesRepresentative
User_Name
AssigneeCode
      }
      nextToken
    }
  }
`;
export const queryArBulkOrderRegHistoriesByAgencyCodeIndex = /* GraphQL */ `
  query queryArBulkOrderRegHistoriesByAgencyCodeIndex(
    $AgencyCode: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArBulkOrderRegHistoriesByAgencyCodeIndex(
      AgencyCode: $AgencyCode
      first: $limit
      after: $nextToken
    ) {
      items {
      AgencyCode
      AgencyName
      ConversionState
      CustomerReceiptCode
      DeliveryDate
      EndUserCode
      FileID
      FileName
      OrderQuantity
      ProductCodeAgency
      ProductCodeSales
      UploadDateTime
      id
      ProStatus
OrderDetailsNumber
    }
      nextToken
    }
  }
`;
export const listEndUserSpecialPricings = /* GraphQL */ `
  query listEndUserSpecialPricings (
    $filter: TableEndUserSpecialPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEndUserSpecialPricings (
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ItemCode
        OrderCode
        Price
        EndUserCode
        EndUserWholesRate
        id
      }
      nextToken
    }
}
`;

export const queryEndUserSpecialPricingsByEndUserCodeIndex = /* GraphQL */ `
  query queryEndUserSpecialPricingsByEndUserCodeIndex(
    $EndUserCode: String!,
    $limit: Int
    $nextToken: String
  ) {
    queryEndUserSpecialPricingsByEndUserCodeIndex(
      EndUserCode:$EndUserCode
      first: $limit
      after: $nextToken
    ) {
      items {
        ItemCode
        OrderCode
        Price
        EndUserCode
        EndUserWholesRate
        id
      }
      nextToken
    }
  }
`;


export const queryArBulkOrderRegHistoriesByFIleIDIndex = /* GraphQL */ `
  query queryArBulkOrderRegHistoriesByFIleIDIndex(
    $FileID: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArBulkOrderRegHistoriesByFIleIDIndex(
      FileID: $FileID
      first: $limit
      after: $nextToken
    ) {
      items {
        AgencyCode
        AgencyName
        ConversionState
        CustomerReceiptCode
        DeliveryDate
        EndUserCode
        FileID
        FileName
        OrderQuantity
        ProductCodeAgency
        ProductCodeSales
        UploadDateTime
        id
        ProStatus
OrderDetailsNumber
      }
      nextToken
    }
  }
`;
export const listArBulkOrderRegHistories = /* GraphQL */ `
  query listArBulkOrderRegHistories(
    $filter: TableArBulkOrderRegHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArBulkOrderRegHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        AgencyCode
        AgencyName
        ConversionState
        CustomerReceiptCode
        DeliveryDate
        EndUserCode
        FileID
        FileName
        OrderQuantity
        ProductCodeAgency
        ProductCodeSales
        UploadDateTime
        id
        ProStatus
        OrderDetailsNumber
      }
      nextToken
    }
  }
`;

export const arCustomerByAgencyIDContractor = /* GraphQL */ `
  query arCustomerByAgencyID(
    $CustomerCodeKey: ID, 
    $sortDirection: ModelSortDirection
    $filter: ModelArCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    arCustomerByAgencyID(
      CustomerCodeKey: $CustomerCodeKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ){
    items {
      id
      CustomerCode
      Name1
    }
    nextToken
  }
}
`;

export const arCustomerByAgencyID = /* GraphQL */ `
  query arCustomerByAgencyID(
    $CustomerCodeKey: ID, 
    $sortDirection: ModelSortDirection
    $filter: ModelArCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    arCustomerByAgencyID(
      CustomerCodeKey: $CustomerCodeKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ){
    items {
      id
      CustomerCodeKey
      AccounKey
      AccountingCode
      AddressTmeZone
      AllocationTohierarchy
      AnnualSales
      Area
      CountryCode
      CustomerAccountGroup
      CustomerCode
      ExportControlListConfirmationDate
      ExportControlSDNCheckDate
      ExportControlTDODate
      FirstPhoneNumber
      HonorificTitleKey
      InternationalCheckDigit
      InternationalPart1
      InternationalPart2
      LanguageCode
      Name1
      Name2
      Name3
      NonMilitaryUse
      NumberOfEmployees
      PlaceName
      PostCode
      Registrationyear
      SalesYear
      SearchTerm1
      TransactionClassification
    }
    nextToken
  }
}
`;
// 受取人コードを使用して配送先住所情報を検索する
export const arCustomerAddressByOrderRecipientCode = /* GraphQL */ `
  query queryArCustomerAddressesByOrderRecipientCodeIndex(
    $OrderRecipientCode: String!
    $first: Int
    $after: String
  ) {
    queryArCustomerAddressesByOrderRecipientCodeIndex(
      OrderRecipientCode: $OrderRecipientCode
      first: $first
      after: $after
    ){
    items {
      id
      CustomerReceiptCode
      Name1
      Name2
      Name3
      Name4
      SearchTerm1
      PostCode
      AreaCode
      Area
      PlaceName
      PlaceName3
      city
      Tel
      Fax
      TransactionClassification
      OrderRecipientCode
      OrderRecipientName
      OutFlag
      CustomerCodeKey
      TorayShipCode
TorayShipMasterName2
TorayShipMasterName3
TorayShipMasterName4

    }
  }
}
`;
// 受取人コードを使用して配送先住所情報を検索する
export const arEndUserByReceiptCode = /* GraphQL */ `
  query ArEndUsersByOrderRecipientCode(
    $RecipientCode: String!
    $first: Int
    $after: String
  ) {
    ArEndUsersByOrderRecipientCode(
      OrderRecipientCode: $RecipientCode
      first: $first
      after: $after
    ){
    items {
      EndUserCode
      Name1
      Name2
      Name3
      Name4
      OrderRecipientCode
      OrderRecipientName
      SearchTerm
      id
      TorayEndUser
  TorayEndUserBranchName
  TorayEndUserName
    }
  }
}
`;


export const listArEndUsers = /* GraphQL */ `
  query listArEndUsers(
    $filter: TableArEndUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArEndUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        EndUserCode
        Name1
        Name2
        Name3
        Name4
        OrderRecipientCode
        OrderRecipientName
        SearchTerm
        id
        TorayEndUser
  TorayEndUserBranchName
  TorayEndUserName
      }
      nextToken
    }
  }
`;


export const getArAZCustomerCode = /* GraphQL */ `
  query getArAZCustomerCode($id: ID!) {
    getArAZCustomerCode(id: $id) {
        id
        CustomerCodeNumber
    }
  }
`;
export const getArAgencyByAgencyid = /* GraphQL */ `
  query getArAgencyByAgencyid(
    $Agency_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelArAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getArAgencyByAgencyid(
      Agency_id: $Agency_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Agency_id
        SubId
        Agency_Price_Group
        Agency_Name
        Company_Name
        Agency_Email
        id
        User_Name
        Tel
      }
      nextToken
    }
  }
`;






export const listAllArAgencyOrderInfo = /* GraphQL */ `
  query listAllArAgencyOrderInfo(
    $filter: ModelArAgencyOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllArAgencyOrderInfo(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Agency_id
        QuoteNumber
        Product_Code
        Product_Name_j
        OrderQuantity
        Wholes_Rate
        Wholes_Price
        Campaign_price
        SalesGroup
        Quantity
        Product_id
        delflg
        OutputFlag
        DetailNo
        Purchasing_Document_Type
        Purchasing_Organization
        purchasing_Group
        Supplier
        Tax_Code
        Storage_Location
        Sales_Document_Type
        Storage_Location_U
        Basic_Quantity_Unit
        Salesperson
        SalespersonName
        Segment
        Agency_Price_Group
        OrderDetailsNumber
	      RegistrationDate
	      AgencyName
	      AgencyEmail
	      GroupName
        DeliveryDate
	      DesiredDeliveryDate
      	OrderNumber
      	ShippingBlock
      	ShippingDestination
      	ShippingDestinationNm
	      Contractor
	      ContractorNm
	      EndUserId
	      EndUserNm
	      CompanyName
	      OrderRemarks
	      OrderShippingNotes
	      OrderTelNo
	      OrderAddress
	      OrderPostCode
	      ShippingOrderNumber
	      OrderChargeName
        OrderStatus
	      OrderShipContactNm
	      comments
        ShippingInvoice
        ShipmentQuantity
        invoiceSubtotal
        campaignPriceTotal
        EndUserPriceTotal
        quantityPriceTotal
        invoiceFax
        invoicePayTotal
        unitPrice
        invoiceTotal
        ShipmentStatus
        SalesUnitPriceScale
PurchUnitPriceScale
ShortestRefTime
RegQty
BulkSales
DTStockTier
DTNormalLT
FukuiSeinoLT
DropDeliveryTime
SalesType
UnitPriceAftDis
InHouseRe
FukuiWarehouseCSVRe
PurchOrderRe
TorayEDIRe
PriceTypePurchase
Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
EndUserIdDirectDelivery
SubId
TorayShipCode
Plant
SalesRepresentative
User_Name
AssigneeCode
      }
      nextToken
    }
  }
`;

export const listAllArAgency = /* GraphQL */ `
  query listAllArAgency(
      $filter: ModelArAgencyFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listAllArAgency(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
    items {
      Agency_Email
      Agency_Name
      Agency_Price_Group
      Agency_id
      Company_Name
      SubId
      id
      User_Name
      Tel
    }
    nextToken
  }
}
`;

export const listArCustomerAddresses = /* GraphQL */ `
  query listArCustomerAddresses(
    $filter: TableArCustomerAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArCustomerAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
  items {
    id
    city
    TransactionClassification
    Tel
    SearchTerm1
    PostCode
    PlaceName3
    PlaceName
    OutFlag
    OrderRecipientCode
    CustomerReceiptCode
    OrderRecipientName
    Name4
    Name3
    Name2
    Name1
    Fax
    CustomerCodeKey
    Area
    AreaCode
    TorayShipCode
TorayShipMasterName2
TorayShipMasterName3
TorayShipMasterName4

  }
  nextToken
}
}
`;
export const queryArPriceInfosByQueryIDIndex = /* GraphQL */ `
query queryArPriceInfosByQueryIDIndex(
    $QueryId: String!
    $limit: Int
    $filter: TableArPriceInfoFilterInput
    $nextToken: String
  ) {
    queryArPriceInfosByQueryIDIndex(
      filter: $filter
      QueryId:$QueryId 
      first: $limit
      after: $nextToken
    ) {
  items {
    id
    ItemCode
    MaterialCode
    ItemName
    SuppName
    SuppCode
    SuppGroup
    OrderName
    DestName
    EndUserName
    Capacity
    CapacityUnit
    PriceUnit
    RegQty
    PriceTypePurchase
    SalesUnitPriceScale1
    SalesUnitPriceScale2
    SalesUnitPriceScale3
    SalesUnitPriceScale4
    SalesUnitPriceScale5
    SalesUnitPriceUnitScale1
    SalesUnitPriceUnitScale2
    SalesUnitPriceUnitScale3
    SalesUnitPriceUnitScale4
    SalesUnitPriceUnitScale5
    PurchUnitPriceScale1
    PurchUnitPriceScale2
    PurchUnitPriceScale3
    PurchUnitPriceScale4
    PurchUnitPriceScale5
    PurchUnitPriceUnitScale1
    PurchUnitPriceUnitScale2
    PurchUnitPriceUnitScale3
    PurchUnitPriceUnitScale4
    PurchUnitPriceUnitScale5
    BulkSales
    ActualFlag
    ExpirationStart
    Expired
    HistoryNum
    OrderQuantity
    OrderReceiptCode
    ShipmentCode
    EUARBmgmtCode
    Remarks
    RecordSalesDate
    RecordSalesApplicable
    PurchaseAccountDate
    AcctForPurchasesApplicableble
    SalesDocType
    BulkShippingFee
    CoolDeliveryCharge
    SeqNo
    TorayEndUser
    TorayEndUserName
    UseGroupClassification
    OrdQty
  }
  nextToken
}
}
`;
export const queryArPriceInfosByQueryIDIndex2 = /* GraphQL */ `
query queryArPriceInfosByQueryIDIndex(
    $QueryId: String!
    $limit: Int
    $filter: TableArPriceInfoFilterInput
    $nextToken: String
  ) {
    queryArPriceInfosByQueryIDIndex(
      QueryId:$QueryId 
      first: $limit
      filter: $filter
      after: $nextToken
    ) {
  items {
    id
    ItemCode
    ItemName
  }
  nextToken
}
}
`;
export const queryArPriceInfosByQueryIDIndex4 = /* GraphQL */ `
query queryArPriceInfosByQueryIDIndex(
    $QueryId: String!
    $limit: Int
    $filter: TableArPriceInfoFilterInput
    $nextToken: String
  ) {
    queryArPriceInfosByQueryIDIndex(
      QueryId:$QueryId 
      first: $limit
      filter: $filter
      after: $nextToken
    ) {
  items {
    id
    TorayEndUser
    TorayEndUserName
  }
  nextToken
}
}
`;
export const listArOrderRecipients = /* GraphQL */ `
query listArOrderRecipients(
  $filter: TableArOrderRecipientFilterInput,
    $limit: Int
    $nextToken: String
  ) {
    listArOrderRecipients(
      filter:$filter
      limit: $limit
      nextToken: $nextToken
    ) {
  items {
    OrderReceiptCode
    OrderRecipientName3
    OrderRecipientName2
    OrderRecipientName4
    UsageSuspendReason
    id
  }
  nextToken
}
}
`;
export const queryArPriceInfosByQueryIDIndex3 = /* GraphQL */ `
query queryArPriceInfosByQueryIDIndex(
    $QueryId: String!
    $limit: Int
    $filter: TableArPriceInfoFilterInput
    $nextToken: String
  ) {
    queryArPriceInfosByQueryIDIndex(
      filter: $filter
      QueryId:$QueryId 
      first: $limit
      after: $nextToken
    ) {
  items {
    AcctForPurchasesApplicableble
    ActualFlag
    BulkSales
    BulkShippingFee
    Capacity
    CapacityUnit
    CoolDeliveryCharge
    DTDelOrderRec
    DestName
    EUARBmgmtCode
    EndUserName
    ExpirationStart
    Expired
    HistoryNum
    ItemCode
    ItemName
    LastOrderDate
    MaterialCode
    OrdQty
    OrderName
    OrderQuantity
    OrderReceiptCode
    PriceTypePurchase
    PriceTypeSales
    PriceUnit
    PurchUnitPriceScale1
    PurchUnitPriceScale2
    PurchUnitPriceScale3
    PurchUnitPriceScale4
    PurchUnitPriceScale5
    PurchUnitPriceUnitScale1
    PurchUnitPriceUnitScale3
    PurchUnitPriceUnitScale2
    PurchUnitPriceUnitScale4
    PurchUnitPriceUnitScale5
    PurchaseAccountDate
    PurchaseRevPrice
    PurchaseRevRate
    QueryId
    RecordSalesApplicable
    RecordSalesDate
    RegQty
    Remarks
    SalesDocType
    SalesRevPrice
    SalesRevRate
    SalesUnitPriceScale1
    SalesUnitPriceScale3
    SalesUnitPriceScale2
    SalesUnitPriceScale4
    SalesUnitPriceScale5
    SalesUnitPriceUnitScale1
    SalesUnitPriceUnitScale2
    SalesUnitPriceUnitScale3
    SalesUnitPriceUnitScale4
    SalesUnitPriceUnitScale5
    SalespersonCode
    SeqNo
    ShipmentCode
    SuppCode
    SuppGroup
    SuppName
    TorayEndUser
    TorayEndUserName
    UpDailyPayment
    UpTime
    UseGroupClassification
    WebOrderNumber
    id
  }
  nextToken
}
}
`;
export const queryArPriceInfosByQueryIdSeqNoIndex = /* GraphQL */ `
query queryArPriceInfosByQueryIdSeqNoIndex(
    $QueryId: String
    $limit: Int
    $filter: TableArPriceInfoFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    queryArPriceInfosByQueryIdSeqNoIndex(
      QueryId:$QueryId 
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
  items {
    AcctForPurchasesApplicableble
    ActualFlag
    BulkSales
    BulkShippingFee
    Capacity
    CapacityUnit
    CoolDeliveryCharge
    DTDelOrderRec
    DestName
    EUARBmgmtCode
    EndUserName
    ExpirationStart
    Expired
    HistoryNum
    ItemCode
    ItemName
    LastOrderDate
    MaterialCode
    OrdQty
    OrderName
    OrderQuantity
    OrderReceiptCode
    PriceTypePurchase
    PriceTypeSales
    PriceUnit
    PurchUnitPriceScale1
    PurchUnitPriceScale2
    PurchUnitPriceScale3
    PurchUnitPriceScale4
    PurchUnitPriceScale5
    PurchUnitPriceUnitScale1
    PurchUnitPriceUnitScale3
    PurchUnitPriceUnitScale2
    PurchUnitPriceUnitScale4
    PurchUnitPriceUnitScale5
    PurchaseAccountDate
    PurchaseRevPrice
    PurchaseRevRate
    QueryId
    RecordSalesApplicable
    RecordSalesDate
    RegQty
    Remarks
    SalesDocType
    SalesRevPrice
    SalesRevRate
    SalesUnitPriceScale1
    SalesUnitPriceScale3
    SalesUnitPriceScale2
    SalesUnitPriceScale4
    SalesUnitPriceScale5
    SalesUnitPriceUnitScale1
    SalesUnitPriceUnitScale2
    SalesUnitPriceUnitScale3
    SalesUnitPriceUnitScale4
    SalesUnitPriceUnitScale5
    SalespersonCode
    SeqNo
    ShipmentCode
    SuppCode
    SuppGroup
    SuppName
    TorayEndUser
    TorayEndUserName
    UpDailyPayment
    UpTime
    UseGroupClassification
    WebOrderNumber
    id
  }
  nextToken
}
}
`;
export const queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex = /* GraphQL */ `
query queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex(
    $OrderReceiptCode: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex(
      OrderReceiptCode:$OrderReceiptCode
      first: $limit
      after: $nextToken
    ) {
  items {
    AgencyType
      AgencyTypeName
      OrderReceiptCode
      OrderRecipientName
      id
  }
}
}
`;
export const queryMaterialStgLocsByItemCodeIndex = /* GraphQL */ `
query queryMaterialStgLocsByItemCodeIndex(
    $ItemCode: String!
    $UsageCategory: String!
    $limit: Int
    $nextToken: String
  ) {
    queryMaterialStgLocsByItemCodeIndex(
      ItemCode:$ItemCode
      UsageCategory : $UsageCategory 
      first: $limit
      after: $nextToken
    ) {
  items {
    ItemCode
    Plant
    PlantName
    SalesDocType
    SalesGroup
    SalesGroupName
    StorageLoc
    StorageLocText
    Text
    id
    UsageCategory
  }
}
}
`;
export const queryMaterialStgLocsSalesDocType = /* GraphQL */ `
query queryMaterialStgLocsSalesDocType(
    $ItemCode: String!
    $filter: TableMaterialStgLocFilterInput,
    $limit: Int
    $nextToken: String
  ) {
    queryMaterialStgLocsSalesDocType(
      ItemCode:$ItemCode
      filter:$filter
      first: $limit
      after: $nextToken
    ) {
  items {
    ItemCode
    SalesDocType
    SalesGroup
    SalesGroupName
    StorageLoc
    StorageLocText
    Text
    id
    Plant
    PlantName
    UsageCategory
  }
}
}
`;
export const listMaterialStgLocs = /* GraphQL */ `
query listMaterialStgLocs(
  $filter: TableMaterialStgLocFilterInput,
    $limit: Int
    $nextToken: String
  ) {
    listMaterialStgLocs(
      filter:$filter
      limit: $limit
      nextToken: $nextToken
    ) {
  items {
    ItemCode
    SalesDocType
    SalesGroup
    SalesGroupName
    StorageLoc
    StorageLocText
    Text
    id
    Plant
    PlantName
  }
  nextToken
}
}
`;
export const queryArItemMastersBySAPCODEIndex = /* GraphQL */ `
query queryArItemMastersBySAPCODEIndex(
    $SAPCODE: String!
    $limit: Int
    $filter:TableArItemMasterFilterInput
    $nextToken: String
  ) {
    queryArItemMastersBySAPCODEIndex(
      SAPCODE:$SAPCODE
      first: $limit
      filter:$filter
      after: $nextToken
    ) {
      items {
        SAPCODE
        StorageLoc
        ItemGrp
        BaseNumUnit
        InventoryTier
        LatestLT
        QuotationReplyLT
        id
      }
}
}
`;
export const queryArCustomerAddressesByCustomerReceiptCodeIndex = /* GraphQL */ `
query queryArCustomerAddressesByCustomerReceiptCodeIndex(
    $CustomerReceiptCode: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArCustomerAddressesByCustomerReceiptCodeIndex(
      CustomerReceiptCode:$CustomerReceiptCode
      first: $limit
      after: $nextToken
    ) {
      items {
        PostCode
        id
        PlaceName3
        PlaceName
        Tel
        city
        CustomerReceiptCode
        Name1
        TorayShipCode
TorayShipMasterName2
TorayShipMasterName3
TorayShipMasterName4
      }
}
}
`;
export const listArcalendars = /* GraphQL */ `
query listArcalendars(
  $filter: TableArcalendarFilterInput,
    $limit: Int
    $nextToken: String
  ) {
    listArcalendars(
      filter:$filter
      limit: $limit
      nextToken: $nextToken
    ) {
  items {
    Date
    IsWorkday
  }
  nextToken
}
}
`;
export const queryArOrderRecipientItemSegGdcsByItemCodeIndex = /* GraphQL */ `
query queryArOrderRecipientItemSegGdcsByItemCodeIndex(
    $ItemCode: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArOrderRecipientItemSegGdcsByItemCodeIndex(
      ItemCode:$ItemCode
      first: $limit
      after: $nextToken
    ) {
      items {
      	id
	OrderRecipientCode
	OrderRecipientName
	ItemCode
	SalesGroup
	SalesGroupName
	Market
	MarketName
	Segment
	SegmentName
	CommodityGrpText2
	ItemGroup
	ProductGroups
}
nextToken
}
}
`;
export const queryArFukuiWHSeinoLTSByPostCodeIndex = /* GraphQL */ `
query queryArFukuiWHSeinoLTSByPostCodeIndex(
    $PostCode: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArFukuiWHSeinoLTSByPostCodeIndex(
      PostCode:$PostCode
      first: $limit
      after: $nextToken
    ) {
      items {
        PostCode
        leadTime
      }
      nextToken
}
}
`;
export const listArSalesRepresentatives = /* GraphQL */ `
  query listArSalesRepresentatives(
    $filter: TableArSalesRepresentativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArSalesRepresentatives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        SalespersonEmail
        Salesperson
        SalespersonName
      }
      nextToken
    }
  }
`;
export const listArItemMasters = /* GraphQL */ `
  query listArItemMasters(
  $filter: TableArItemMasterFilterInput
  $limit: Int
  $nextToken: String
) {
  listArItemMasters(
  filter:$filter
  limit: $limit
  nextToken: $nextToken
) {
  items{
    ARBSuppAgencyCode
    BaseNumUnit
    Capacity
    CapacityUnit
    ChilledGoods
    DelFlag
    CustomerLTguidance
    CustomerMOQ
    Expiration
    FreeHoldingdays
    GMID
    HScode
    InventoryTier
    ItemGrp
    ItemGrpName
    ItemText
    LatestLT
    MFGPlant
    OrderAdjustment
    OrderUnitpriceUnit
    OuterBoxSizeWDH
    PriceChangeFactor
    ProductCountry
    ProductSizeWDH
    QuotationReplyLT
    Remark
    SAPCODE
    StandardLeadTime
    StipulateRestdays
    StorageLoc
    StorageTemp
    SuppCode
    SuppName
    USproduct
    UnitPerOrder
    id
   }
  nextToken
  }
}
`;
export const listArPriceInfos = /* GraphQL */ `
  query listArPriceInfos(
  $filter: TableArPriceInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listArPriceInfos(
  filter:$filter
  limit: $limit
  nextToken: $nextToken
) {
  items{
      AcctForPurchasesApplicableble
      ActualFlag
      BulkSales
      BulkShippingFee
      Capacity
      CapacityUnit
      CoolDeliveryCharge
      DTDelOrderRec
      DestName
      EUARBmgmtCode
      EndUserName
      ExpirationStart
      Expired
      HistoryNum
      ItemCode
      ItemName
      LastOrderDate
      MaterialCode
      OrdQty
      OrderName
      OrderQuantity
      OrderReceiptCode
      PriceTypePurchase
      PriceTypeSales
      PriceUnit
      PurchUnitPriceScale1
      PurchUnitPriceScale2
      PurchUnitPriceScale3
      PurchUnitPriceScale4
      PurchUnitPriceScale5
      PurchUnitPriceUnitScale1
      PurchUnitPriceUnitScale2
      PurchUnitPriceUnitScale3
      PurchUnitPriceUnitScale4
      PurchUnitPriceUnitScale5
      PurchaseAccountDate
      PurchaseRevPrice
      PurchaseRevRate
      QueryId
      RecordSalesApplicable
      RecordSalesDate
      RegQty
      Remarks
      SalesDocType
      SalesRevPrice
      SalesRevRate
      SalesUnitPriceScale1
      SalesUnitPriceScale2
      SalesUnitPriceScale3
      SalesUnitPriceScale4
      SalesUnitPriceScale5
      SalesUnitPriceUnitScale1
      SalesUnitPriceUnitScale2
      SalesUnitPriceUnitScale3
      SalesUnitPriceUnitScale4
      SalesUnitPriceUnitScale5
      SalespersonCode
      SeqNo
      ShipmentCode
      SuppCode
      SuppGroup
      SuppName
      TorayEndUser
      TorayEndUserName
      UpDailyPayment
      UpTime
      UseGroupClassification
      WebOrderNumber
      id
   }
  nextToken
  }
}
`;
export const queryArcalendarIsWorkday = /* GraphQL */ `
query queryArcalendarIsWorkday(
    $IsWorkday: String!
    $limit: Int
    $nextToken: String
  ) {
    queryArcalendarIsWorkday(
      IsWorkday:$IsWorkday
      first: $limit
      after: $nextToken
    ) {
      items {
        Date
        IsWorkday
      }
      nextToken
}
}
`;