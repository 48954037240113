/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Layout.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Layout画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
//  */
import { BrowserRouter as Router, Link } from "react-router-dom";
import userAuth from "../components/userAuth";
// import {Row as Rows} from "react-bootstrap";
import React, { useEffect, useReducer, useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
// import { agencyGroupSortLastupdate, customerByeMail } from "../graphql/queries";
import { customerByeMail } from "../graphql/queries";
/*  AWS標準設定 with Login Auth. */

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);
import "../index.css";
import {
  // UserOutlined,
  // BellOutlined,
  // HomeOutlined,
  TableOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  DownOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Dropdown } from "antd";
import logo from "../components/logo.svg";
// import { listArCampaigns } from "../graphql/queries";
// import NotFound from "../pages/NotFound";

const { Header, Content, Sider, Footer } = Layout;

// Layout Auth Customize -- SingOut
const signOut = async () => {
  await Auth.signOut();
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
  document.location.reload();
};
const QUERY = "QUERY";
// const initialInventoryState1 = { lastupdate: "" };
// const initialUserEmail = { email: "" };
const initialUserNm = { agencyName: "" };
const initialFirstName = { firstName: "" };
// const reducer1 = (state, action) => {
//   switch (action.type) {
//     case QUERY:
//       return { ...state, lastupdate: action.Invs };
//     default:
//       return state;
//   }
// };
// const reducerUser = (state, action) => {
//   switch (action.type) {
//     case QUERY:
//       return { ...state, email: action.Invs };
//     default:
//       return state;
//   }
// };
const reducerUserNm = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, agencyName: action.Invs };
    default:
      return state;
  }
};
const reducerFirstName = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, firstName: action.Invs };
    default:
      return state;
  }
};
const Layouts = ({ children }) => {
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }



  // const [user, setUser] = useReducer(reducerUser, initialUserEmail);
  const [agency, setAgencyName] = useReducer(reducerUserNm, initialUserNm);
  const [agencyNm, setFirstName] = useReducer(reducerFirstName, initialFirstName);
  const menu = (
    <Menu
      theme="light"
      items={[
        // {
        //   key: "1",
        //   label: (
        //     <span>{agency.agencyName}</span>
        //   ),
        // },
        {
          key: "2",
          danger: true,
          label: (
            <Link to="" rel="signout" onClick={signOut}>
              サインアウト
            </Link>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );
  let itemsList = [];
  const [items, setItems] = useState(itemsList);
  useEffect(() => {
    async function listInvData() {
      const userAuths = await userAuth();
      // const apiData = await API.graphql({ query: listArCampaigns });
      // let ArCampaignslength = 0;
      // if (apiData.data.listArCampaigns !== null) {
      //   ArCampaignslength = apiData.data.listArCampaigns["items"].length;
      // }
      if (userAuths === "1") {
        // 代理店
        itemsList = [
          getItem(<Link to="/Home">HOME</Link>, "HOME",),
          getItem("注文管理", "注文管理", <ShoppingCartOutlined />, [
            getItem(<Link to="/InventorySearch">注文登録</Link>, "3"),
            getItem(<Link to="/OrderList">注文一覧</Link>, "2"),
          ]),
          getItem("ユーザー管理", "ユーザー", <TeamOutlined />, [
            getItem(<Link to="/UserList">ユーザー一覧</Link>, "4"),
          ]),
          getItem("マスター管理", "マスター", <TableOutlined />, [
            getItem(<Link to="/Agent">出荷先一覧</Link>, "Shipping"),
          ]),
          // getItem(
          //   <Link to="/Home">お知らせ</Link>,
          //   "10",
          //   <Badge size="small" count={1}>
          //     <BellOutlined style={{ color: "#ababab" }} />
          //   </Badge>
          // ),
        ];
      } else if (userAuths === "4" || userAuths === "3" || userAuths === "2") {
        // 代理店
        itemsList = [
          getItem(<Link to="/Home">HOME</Link>, "HOME",),
          getItem("注文管理", "注文管理", <ShoppingCartOutlined />, [
            getItem(<Link to="/OrderList">注文一覧</Link>, "2"),
            getItem(<Link to="/InventorySearchEmd">注文登録(Si)</Link>, "3"),
          ]),
          getItem("ユーザー管理", "ユーザー", <TeamOutlined />, [
            getItem(<Link to="/UserList">ユーザー一覧</Link>, "4"),
          ]),
          getItem("マスター管理", "マスター", <TableOutlined />, [
            getItem(<Link to="/Agent">出荷先一覧</Link>, "Shipping"),
            getItem(<Link to="/EndUserSpecialPricing">エンドユーザー特価設定</Link>),
            getItem(<Link to="/ArPriceInfo">価格表一覧(Si)</Link>, "6"),
            getItem("商品管理", "マスタ",<></>,[
              getItem(<Link to="/ArItemMaster">品目一覧(Si)</Link>, "7"),
            ]),
          ]),
          // getItem(
          //   <Link to="/Home">お知らせ</Link>,
          //   "10",
          //   <Badge size="small" count={1}>
          //     <BellOutlined style={{ color: "#ababab" }} />
          //   </Badge>
          // ),
        ];
      }else {
        // 営業者
        itemsList = [
          // getItem(<Link to="/Home">HOME</Link>, "HOME",
          //   <Badge size="medium" count={ArCampaignslength}>
          //     <BellOutlined style={{ color: "#ababab" }} />
          //   </Badge>),
          getItem(<Link to="/Home">HOME</Link>, "HOME",),
          getItem("注文管理", "注文管理", <ShoppingCartOutlined />, [
            getItem(<Link to="/OrderList">注文一覧</Link>, "2"),
            getItem(<Link to="/InventorySearch">商品管理</Link>, "3"),
          ]),
          getItem("ユーザー管理", "ユーザー", <TeamOutlined />, [
            getItem(<Link to="/UserList">ユーザー一覧</Link>, "4"),
          ]),
          getItem("マスター管理", "マスター", <TableOutlined />, [
            getItem(<Link to="/Agent">出荷先一覧</Link>, "Shipping"),
            getItem(<Link to="/EndUserSpecialPricing">エンドユーザー特価設定</Link>),
          ]),
          // getItem(
          //   <Link to="/Home">お知らせ</Link>,
          //   "10",
          //   <Badge size="small" count={1}>
          //     <BellOutlined style={{ color: "#ababab" }} />
          //   </Badge>
          // ),
        ];
      }
      setItems(itemsList);
      // get the Login user infomation. Set to user
      // const { accessToken } = await Auth.currentSession();
      // const cognitogroups = accessToken.payload['cognito:groups'];
      // const userAuth = cognitogroups[0];
      // const agencyPriceGroup = await API.graphql(graphqlOperation(customerByeMail, { Agency_Email: userAuth }));
      const user = await Auth.currentUserInfo();
      const emailAgent = await API.graphql(
        graphqlOperation(customerByeMail, {
          Agency_Email: user.attributes.email,
        })
      );
      // setUser({ type: QUERY, Invs: user.attributes.email });
      const agentinfo = emailAgent.data.customerByeMail["items"];
      if (agentinfo.length > 0) {
        setAgencyName({ type: QUERY, Invs: agentinfo[0].Agency_Name });
        // let firsetnametext = agentinfo[0].FirstName === null ? "" : agentinfo[0].FirstName;
        let UserNametext = agentinfo[0].User_Name === null ? "" : agentinfo[0].User_Name;
        // setFirstName({ type: QUERY, Invs: firsetnametext + " " + UserNametext });
        setFirstName({ type: QUERY, Invs: UserNametext });
        // const agentGroupID = agentinfo[0].Agency_Price_Group;
        // const InvData = await API.graphql(
        //   graphqlOperation(agencyGroupSortLastupdate, {
        //     Agency_Price_Group: agentGroupID,
        //     sortDirection: "DESC",
        //     limit: 1,
        //   })
        // );
        // let yymmdd = moment(
        //   InvData.data.AgencyGroupSortList.items[0]?.lastupdate
        // )
        //   .utcOffset(9)
        //   .format("YYYY年MM月DD日 HH時mm分");
        // setInv1({ type: QUERY, Invs: yymmdd });
      }
    }
    listInvData();
  }, []);

  const [current, setCurrent] = useState("1");

  const onClick = (e) => {
    setCurrent(e.key);
  };
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Router>
      <Layout>
        <Header
          breakpoint="lg"
          style={{
            backgroundColor: "white",
            display: "inline-flex",
            justifyContent: "space-between",
            padding: 0,
            height: "80px",
          }}
        >
          <Row style={{ padding: "0.5rem" }}>
            <Link to="/">
              <img src={logo} className="logo" />
            </Link>
          </Row>
          <Row style={{ flexFlow: "nowrap" }}>
            <Link to="/Contact" className="top">
              {
                <svg
                  style={{ marginRight: "0.5rem" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="18.28"
                  viewBox="0 0 25 18.28"
                >
                  <g
                    id="Group_1439"
                    data-name="Group 1439"
                    transform="translate(-20.784 -18.287)"
                  >
                    <path
                      id="Path_287"
                      data-name="Path 287"
                      d="M43.364,18.787H23.2a1.926,1.926,0,0,0-1.92,1.92v13.44a1.914,1.914,0,0,0,.589,1.376l8.755-8.1-8.755,8.1a1.91,1.91,0,0,0,1.331.544h20.16a1.911,1.911,0,0,0,1.332-.544l-8.755-8.1,8.755,8.1a1.913,1.913,0,0,0,.588-1.376V20.707A1.926,1.926,0,0,0,43.364,18.787Z"
                      fill="none"
                      stroke="#262626"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_288"
                      data-name="Path 288"
                      d="M34.553,28.711,44.7,19.331a1.911,1.911,0,0,0-1.332-.544H23.2a1.91,1.91,0,0,0-1.331.544l10.143,9.38A1.921,1.921,0,0,0,34.553,28.711Z"
                      fill="none"
                      stroke="#262626"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              }
              お問い合わせ
            </Link>
            <Link to="/manual" className="top">
              <svg
                style={{ marginRight: "0.5rem" }}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="19.462"
                viewBox="0 0 25 19.462"
              >
                <g
                  id="Group_1443"
                  data-name="Group 1443"
                  transform="translate(-24.543 -79.069)"
                >
                  <path
                    id="Path_289"
                    data-name="Path 289"
                    d="M37.043,98.031h-12V81.416H26.89V95.262h7.384A2.769,2.769,0,0,1,37.043,98.031ZM47.2,81.416V95.262H39.813a2.77,2.77,0,0,0-2.77,2.769h12V81.416Z"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <g id="Group_1440" data-name="Group 1440">
                    <path
                      id="Path_290"
                      data-name="Path 290"
                      d="M37.043,82.339V98.031a2.769,2.769,0,0,0-2.769-2.769H26.89V79.569h7.384A2.769,2.769,0,0,1,37.043,82.339Z"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                  <g id="Group_1442" data-name="Group 1442">
                    <path
                      id="Path_291"
                      data-name="Path 291"
                      d="M37.043,82.339V98.031a2.77,2.77,0,0,1,2.77-2.769H47.2V79.569H39.813A2.77,2.77,0,0,0,37.043,82.339Z"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <g id="Group_1441" data-name="Group 1441">
                      <rect
                        id="Rectangle_1226"
                        data-name="Rectangle 1226"
                        width="4.615"
                        height="0.046"
                        transform="translate(39.813 83.239)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <rect
                        id="Rectangle_1227"
                        data-name="Rectangle 1227"
                        width="4.615"
                        height="0.046"
                        transform="translate(39.813 86.008)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <rect
                        id="Rectangle_1228"
                        data-name="Rectangle 1228"
                        width="4.615"
                        height="0.046"
                        transform="translate(39.813 88.777)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              マニュアル
            </Link>
            <Dropdown overlay={menu} placement="bottomLeft">
              <div
                style={{ backgroundColor: "#001529", color: "#afb6bc" }}
                className="navUser"
              >
                <Link
                  to="/"
                  style={{
                    color: "#afb6bc",
                    textDecoration: "none",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  {/* <UserOutlined
                    style={{
                      backgroundColor: "white",
                      padding: "1em",
                      borderRadius: "100%",
                      margin: "1em",
                    }}
                  /> */}
                  {/* {user.email} */}
                  {/* <Rows>
                    <Col>
                    </Col>
                  </Rows> */}
                  　{agencyNm.firstName}　　{agency.agencyName}
                  <DownOutlined style={{ paddingRight: "1em" }} />
                </Link>
              </div>
            </Dropdown>
            <Menu />
          </Row>
        </Header>
      </Layout>
      <Layout
        style={{ minHeight: "100vh", maxWidth: "100vw", overflow: "auto" }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          theme="dark"
          width="220px"
          breakpoint="lg"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
        >
          <Menu
            theme="dark"
            onClick={onClick}
            mode="inline"
            defaultSelectedKeys={["/"]}
            items={items}
            selectedKeys={[current]}
          />
        </Sider>
        <Layout>
          <Content
            style={{
              margin: "30px 15px 0",
              maxWidth: "100%",
            }}
          >
            <Row
              style={{
                display: "block",
                textAlign: "right",
                fontSize: "12px",
                justifyContent: "space-around",
              }}
            >
              {/* {user.email} */}
            </Row>
            {children}
          </Content>
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "white",
              display: "inline-flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "black", fontSize: "12px" }}>
              <Link to="/terms" style={{ color: "black" }}>
                利用規約{" "}
              </Link>
              <Link to="/privacy" style={{ color: "black" }}>
                ｜免責事項{" "}
              </Link>
            </div>
            <p style={{ color: "#999999", fontSize: "12px" }}>
            Copyright©ARBROWN Co.,jp Ltd,All right reserved.Ver 2.0
            </p>
          </Footer>
        </Layout>
      </Layout>
    </Router >
  );
};

export default Layouts;
