/* eslint-disable no-unused-vars */
import API, { graphqlOperation } from "@aws-amplify/api";
import { createArPriceInfoInput, updateArPriceInfoInput} from "../../../graphql/mutations";
import { queryArPriceInfosByQueryIDIndex,queryArPriceInfosByQueryIdSeqNoIndex,listArOrderRecipients,listArItemMasters, listArEndUsers, listArCustomerAddresses} from "../../../graphql/queries";
import { dialogText1, ArPriceInfoConst, dialogText2, DilogDetillDisabled } from "./ArPriceInfoConst";
import moment from "moment";
import Amplify, { Auth } from "aws-amplify";
function checkedMasterlist() {
  const checkedMasterlist = {
    UseGroupClassification: document.getElementById("UseGroupClassification").checked,
    itemCode: document.getElementById("itemCode").checked,
    MaterialCode: document.getElementById("MaterialCode").checked,
    ItemName: document.getElementById("ItemName").checked,
    SalesDocType: document.getElementById("SalesDocType").checked,
    suppCode: document.getElementById("suppCode").checked,
    SuppName: document.getElementById("SuppName").checked,
    SuppGroup: document.getElementById("SuppGroup").checked,
    OrderReceiptCode: document.getElementById("OrderReceiptCode").checked,
    OrderName: document.getElementById("OrderName").checked,
    shipmentCode: document.getElementById("shipmentCode").checked,
    DestName: document.getElementById("DestName").checked,
    EUARBmgmtCode: document.getElementById("EUARBmgmtCode").checked,
    endUserName: document.getElementById("endUserName").checked,
    TorayEndUser: document.getElementById("TorayEndUser").checked,
    TorayEndUserName: document.getElementById("TorayEndUserName").checked,
    Capacity: document.getElementById("Capacity").checked,
    CapacityUnit: document.getElementById("CapacityUnit").checked,
    PriceUnit: document.getElementById("PriceUnit").checked,
    RegQty: document.getElementById("RegQty").checked,
    OrdQty: document.getElementById("OrdQty").checked,
    DTDelOrderRec: document.getElementById("DTDelOrderRec").checked,
    PriceTypeSales: document.getElementById("PriceTypeSales").checked,
    PriceTypePurchase: document.getElementById("PriceTypePurchase").checked,
    SalesUnitPriceScale1: document.getElementById("SalesUnitPriceScale1").checked,
    SalesUnitPriceScale2: document.getElementById("SalesUnitPriceScale2").checked,
    SalesUnitPriceScale3: document.getElementById("SalesUnitPriceScale3").checked,
    SalesUnitPriceScale4: document.getElementById("SalesUnitPriceScale4").checked,
    SalesUnitPriceScale5: document.getElementById("SalesUnitPriceScale5").checked,
    SalesUnitPriceUnitScale1: document.getElementById("SalesUnitPriceUnitScale1").checked,
    SalesUnitPriceUnitScale2: document.getElementById("SalesUnitPriceUnitScale2").checked,
    SalesUnitPriceUnitScale3: document.getElementById("SalesUnitPriceUnitScale3").checked,
    SalesUnitPriceUnitScale4: document.getElementById("SalesUnitPriceUnitScale4").checked,
    SalesUnitPriceUnitScale5: document.getElementById("SalesUnitPriceUnitScale5").checked,
    PurchUnitPriceScale1: document.getElementById("PurchUnitPriceScale1").checked,
    PurchUnitPriceScale2: document.getElementById("PurchUnitPriceScale2").checked,
    PurchUnitPriceScale3: document.getElementById("PurchUnitPriceScale3").checked,
    PurchUnitPriceScale4: document.getElementById("PurchUnitPriceScale4").checked,
    PurchUnitPriceScale5: document.getElementById("PurchUnitPriceScale5").checked,
    PurchUnitPriceUnitScale1: document.getElementById("PurchUnitPriceUnitScale1").checked,
    PurchUnitPriceUnitScale2: document.getElementById("PurchUnitPriceUnitScale2").checked,
    PurchUnitPriceUnitScale3: document.getElementById("PurchUnitPriceUnitScale3").checked,
    PurchUnitPriceUnitScale4: document.getElementById("PurchUnitPriceUnitScale4").checked,
    PurchUnitPriceUnitScale5: document.getElementById("PurchUnitPriceUnitScale5").checked,
    BulkSales: document.getElementById("BulkSales").checked,
    BulkShippingFee: document.getElementById("BulkShippingFee").checked,
    CoolDeliveryCharge: document.getElementById("CoolDeliveryCharge").checked,
    SalespersonCode: document.getElementById("SalespersonCode").checked,
    LastOrderDate: document.getElementById("LastOrderDate").checked,
    WebOrderNumber: document.getElementById("WebOrderNumber").checked,
    ExpirationStart: document.getElementById("ExpirationStart").checked,
    Expired: document.getElementById("Expired").checked,
    RecordSalesDate: document.getElementById("RecordSalesDate").checked,
    RecordSalesApplicable: document.getElementById("RecordSalesApplicable").checked,
    PurchaseAccountDate: document.getElementById("PurchaseAccountDate").checked,
    AcctForPurchasesApplicableble: document.getElementById("AcctForPurchasesApplicableble").checked,
    SalesRevRate: document.getElementById("SalesRevRate").checked,
    SalesRevPrice: document.getElementById("SalesRevPrice").checked,
    PurchaseRevRate: document.getElementById("PurchaseRevRate").checked,
    PurchaseRevPrice: document.getElementById("PurchaseRevPrice").checked,
    ActualFlag: document.getElementById("ActualFlag").checked,
    HistoryNum: document.getElementById("HistoryNum").checked,
    Remarks: document.getElementById("Remarks").checked,
    UpDailyPayment: document.getElementById("UpDailyPayment").checked,
    UpTime: document.getElementById("UpTime").checked,
    SeqNo: document.getElementById("SeqNo").checked,
  }
  sessionStorage.setItem("displayItemSettingPriceObj", JSON.stringify(checkedMasterlist))
  return checkedMasterlist
}
const sortByItemCode = (a, b)=> {
  if (!a.ItemCode) {
    return 1;
  } else if (!b.ItemCode) {
    return 1;
  } else {
    return a.ItemCode.localeCompare(b.ItemCode)
  }
}
const sortBySuppName = (a, b)=> {
  if (!a.SuppName) {
    return 1;
  } else if (!b.SuppName) {
    return 1;
  } else {
    return a.SuppName.localeCompare(b.SuppName)
  }
}
const sortBySuppGroup = (a, b)=> {
  if (a.SuppGroup === null || a.SuppGroup === undefined || a.SuppGroup === "") {
    return 1;
  } else if (b.SuppGroup === null || b.SuppGroup === undefined || b.SuppGroup === "") {
    return 1;
  } else {
    return a.SuppGroup.localeCompare(b.SuppGroup);
  }
}
const sortByOrderReceiptCode = (a, b)=> {
  if (a.OrderReceiptCode === null || a.OrderReceiptCode === undefined || a.OrderReceiptCode === "") {
    return 1;
  } else if (b.OrderReceiptCode === null || b.OrderReceiptCode === undefined || b.OrderReceiptCode === "") {
    return 1;
  } else {
    return a.OrderReceiptCode.localeCompare(b.OrderReceiptCode);
  }
}
const sortByShipmentCode = (a, b)=> {
  if (a.ShipmentCode === null || a.ShipmentCode === undefined || a.ShipmentCode === "") {
    return 1;
  } else if (b.ShipmentCode === null || b.ShipmentCode === undefined || b.ShipmentCode === "") {
    return 1;
  } else {
    return a.ShipmentCode.localeCompare(b.ShipmentCode);
  }
}
const sortByEUARBmgmtCode = (a, b)=> {
  if (a.EUARBmgmtCode === null || a.EUARBmgmtCode === undefined || a.EUARBmgmtCode === "") {
    return 1;
  } else if (b.EUARBmgmtCode === null || b.EUARBmgmtCode === undefined || b.EUARBmgmtCode === "") {
    return 1;
  } else {
    return a.EUARBmgmtCode.localeCompare(b.EUARBmgmtCode);
  }
}
const multiSort = (arr, ...sortFuncs)=> {
  return arr.sort((a, b) => {
    for (const func of sortFuncs) {
      const result = func(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  });
}
async function searchData(searchValue,useGroupClassification) {
  let arr = [];
  let obj = {};
  let dataAll = [];
  let filters = {UseGroupClassification:{ne: null}}
  let result = Object.prototype.hasOwnProperty.call(searchValue, "ItemCode")
  if(result){
    if(!searchValue.ItemCode){
      filters = {UseGroupClassification:{eq: useGroupClassification}}
    }else{
      filters = {UseGroupClassification:{ne: null}}
    }
  }else{
    filters = {UseGroupClassification:{eq: useGroupClassification}}
  }

  const ArPriceInfos = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIdSeqNoIndex, {QueryId: "P", limit: 10000, sortDirection: "ASC",filter:filters}));
  if (ArPriceInfos.data.queryArPriceInfosByQueryIdSeqNoIndex !== null) {
    let nextToken = ArPriceInfos.data.queryArPriceInfosByQueryIdSeqNoIndex.nextToken;
    dataAll = ArPriceInfos.data.queryArPriceInfosByQueryIdSeqNoIndex.items
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIdSeqNoIndex, {
        QueryId: "P", limit: 10000, sortDirection: "ASC",nextToken: nextToken,filter:filters
      }));
      nextToken = InvDataSec.data.queryArPriceInfosByQueryIdSeqNoIndex.nextToken;
      for (let i = 0; i < InvDataSec.data.queryArPriceInfosByQueryIdSeqNoIndex.items.length; i++) {
        dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIdSeqNoIndex.items[i]);
      }
    }
  }
  obj = searchValue;
  dataAll.forEach(item => {
    let flag = true
    if (Object.keys(obj).length !== 0) {
      for (let name in obj) {
        if (obj[name] !== "") {
          if (item[name]) {
            if (!(item[name]).includes(obj[name])) {
              flag = false;
              break;
            }
          } else {
            flag = false
            break;
          }
        }
      }
    }
    if (flag === true) {
      arr.push(item)
    }
  })
  arr = multiSort(arr, sortBySuppName,sortByItemCode,sortBySuppGroup,sortByOrderReceiptCode,sortByShipmentCode,sortByEUARBmgmtCode);
  return arr
}
const signOut = async () => {
  await Auth.signOut();
  try {
      await Auth.signOut();
  } catch (error) {
      console.log("error signing out: ", error);
  }
  document.location.reload();
};
async function openDilogDetill(type, setdilogDetill, data,orderselect_row,useGroupClassification) {
  const procType = type
  if (type !== ArPriceInfoConst.PROC_ADD && orderselect_row.length === 0) {
    return
  }
  ArPriceInfoConst.DilogDetill = procType
  let dialogText
  try {
   dialogText = await getSelectTableData(type, data,orderselect_row)
    } catch (error) {
        if (error.message === 'No current user') {
          //ユーザープールに障害が発生した場合
            console.log('User pool is invalid.Please reauthenticate.');
          //ログイン画面に戻る
            signOut();
        } else {
          //他のエラーを処理する
            console.log('An error occurred:', error);
        }
    }
  switch (procType) {
    case ArPriceInfoConst.PROC_ADD:
      ArPriceInfoConst.DilogDetillDisabled = DilogDetillDisabled.add
      dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
      dialogText1.dialogText.UseGroupClassification = useGroupClassification
      break;
    case ArPriceInfoConst.PROC_CADD:
      ArPriceInfoConst.DilogDetillDisabled = DilogDetillDisabled.add
      dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
      dialogText1.dialogText.UseGroupClassification = useGroupClassification
      dialogText1.dialogText.SeqNo = ""
      dialogText1.dialogText.UpDailyPayment = ""
      dialogText1.dialogText.UpTime = ""
      dialogText1.dialogText.LastOrderDate = ""
      dialogText1.dialogText.WebOrderNumber = ""
      break;
    case ArPriceInfoConst.PROC_REF:
      ArPriceInfoConst.DilogDetillDisabled = DilogDetillDisabled.modify
      dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
      dialogText1.dialogText.UseGroupClassification = useGroupClassification
      break;
    case ArPriceInfoConst.PROC_DEL:
      ArPriceInfoConst.DilogDetillDisabled = DilogDetillDisabled.delete
      dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
      break;
    default:
  }
  setdilogDetill(true)
}
async function getSelectTableData(type, data,orderselect_row) {
  let obj = {}
  if (orderselect_row.length !== 0 && type !== ArPriceInfoConst.PROC_ADD) {
    let item = await data.map((item) => {
      return item.id === orderselect_row[0] ? item : ""
    }).filter(Boolean)
    obj = item[0]
  } else {
    obj = dialogText2
  }
  return obj
}
async function addData(data) {
  try {
    const results = await queriesDataMethods(createArPriceInfoInput, data)
    return results
} catch (error) {
    if (error.message === 'No current user') {
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
    } else {
        //他のエラーを処理する
        console.log('An error occurred:', error);
    }
}
}
async function modifyData(data) {
  try {
  const results = await API.graphql(graphqlOperation(updateArPriceInfoInput, {
    input: {
      UseGroupClassification: data.UseGroupClassification,
      ItemCode: data.ItemCode,
      MaterialCode: data.MaterialCode,
      ItemName: data.ItemName,
      SalesDocType: data.SalesDocType,
      SuppCode: data.SuppCode,
      SuppName: data.SuppName,
      SuppGroup: data.SuppGroup,
      OrderReceiptCode: data.OrderReceiptCode,
      OrderName: data.OrderName,
      ShipmentCode: data.ShipmentCode,
      DestName: data.DestName,
      EUARBmgmtCode: data.EUARBmgmtCode,
      EndUserName: data.EndUserName,
      TorayEndUser: data.TorayEndUser,
      TorayEndUserName: data.TorayEndUserName,
      Capacity: data.Capacity,
      CapacityUnit: data.CapacityUnit,
      PriceUnit: data.PriceUnit,
      RegQty: data.RegQty,
      OrdQty: data.OrdQty,
      DTDelOrderRec: data.DTDelOrderRec,
      PriceTypeSales: data.PriceTypeSales,
      PriceTypePurchase: data.PriceTypePurchase,
      SalesUnitPriceScale1: data.SalesUnitPriceScale1,
      SalesUnitPriceScale2: data.SalesUnitPriceScale2,
      SalesUnitPriceScale3: data.SalesUnitPriceScale3,
      SalesUnitPriceScale4: data.SalesUnitPriceScale4,
      SalesUnitPriceScale5: data.SalesUnitPriceScale5,
      SalesUnitPriceUnitScale1: data.SalesUnitPriceUnitScale1,
      SalesUnitPriceUnitScale2: data.SalesUnitPriceUnitScale2,
      SalesUnitPriceUnitScale3: data.SalesUnitPriceUnitScale3,
      SalesUnitPriceUnitScale4: data.SalesUnitPriceUnitScale4,
      SalesUnitPriceUnitScale5: data.SalesUnitPriceUnitScale5,
      PurchUnitPriceScale1: data.PurchUnitPriceScale1,
      PurchUnitPriceScale2: data.PurchUnitPriceScale2,
      PurchUnitPriceScale3: data.PurchUnitPriceScale3,
      PurchUnitPriceScale4: data.PurchUnitPriceScale4,
      PurchUnitPriceScale5: data.PurchUnitPriceScale5,
      PurchUnitPriceUnitScale1: data.PurchUnitPriceUnitScale1,
      PurchUnitPriceUnitScale2: data.PurchUnitPriceUnitScale2,
      PurchUnitPriceUnitScale3: data.PurchUnitPriceUnitScale3,
      PurchUnitPriceUnitScale4: data.PurchUnitPriceUnitScale4,
      PurchUnitPriceUnitScale5: data.PurchUnitPriceUnitScale5,
      BulkSales: data.BulkSales,
      BulkShippingFee: data.BulkShippingFee,
      CoolDeliveryCharge: data.CoolDeliveryCharge,
      SalespersonCode: data.SalespersonCode,
      LastOrderDate: data.LastOrderDate,
      WebOrderNumber: data.WebOrderNumber,
      ExpirationStart:moment(data.ExpirationStart).utcOffset(9).format('YYYYMMDD'),
      Expired: data.Expired ? moment(data.Expired).utcOffset(9).format('YYYYMMDD') : "",
      RecordSalesDate:moment(data.RecordSalesDate).utcOffset(9).format('YYYYMMDD'),
      RecordSalesApplicable:data.RecordSalesApplicable,
      PurchaseAccountDate: moment(data.PurchaseAccountDate).utcOffset(9).format('YYYYMMDD'),
      AcctForPurchasesApplicableble: data.AcctForPurchasesApplicableble,
      SalesRevRate: data.SalesRevRate,
      SalesRevPrice: data.SalesRevPrice,
      PurchaseRevRate: data.PurchaseRevRate,
      PurchaseRevPrice: data.PurchaseRevPrice,
      ActualFlag: data.ActualFlag,
      OrderQuantity: data.OrderQuantity,
      HistoryNum: data.HistoryNum,
      Remarks: data.Remarks,
      UpDailyPayment: data.UpDailyPayment,
      UpTime: data.UpTime,
      SeqNo: data.SeqNo,
      QueryId: "P",
      id: data.id
    }
  }));
  return results
}catch (error) {
    if (error.message === 'No current user') {
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
    } else {
        //他のエラーを処理する
        console.log('An error occurred:', error);
    }
}
}
async function deleteData(data) {
  try {
  const results = await API.graphql(graphqlOperation(updateArPriceInfoInput, {
    input: {
      ActualFlag: data.ActualFlag,
      id: data.id
    }
  }));
  return results
} catch (error) {
  if (error.message === 'No current user') {
      //ユーザープールに障害が発生した場合
      console.log('User pool is invalid.Please reauthenticate.');
      //ログイン画面に戻る
      signOut();
  } else {
      //他のエラーを処理する
      console.log('An error occurred:', error);
  }
}
}
async function queriesDataMethods(ArItemMaster, data) {
  const results = await API.graphql(graphqlOperation(ArItemMaster, {
    input: {
      UseGroupClassification: data.UseGroupClassification,
      ItemCode: data.ItemCode,
      MaterialCode: data.MaterialCode,
      ItemName: data.ItemName,
      SalesDocType: data.SalesDocType,
      SuppCode: data.SuppCode,
      SuppName: data.SuppName,
      SuppGroup: data.SuppGroup,
      OrderReceiptCode: data.OrderReceiptCode,
      OrderName: data.OrderName,
      ShipmentCode: data.ShipmentCode,
      DestName: data.DestName,
      EUARBmgmtCode: data.EUARBmgmtCode,
      EndUserName: data.EndUserName,
      TorayEndUser: data.TorayEndUser,
      TorayEndUserName: data.TorayEndUserName,
      Capacity: data.Capacity,
      CapacityUnit: data.CapacityUnit,
      PriceUnit: data.PriceUnit,
      RegQty: data.RegQty,
      OrdQty: data.OrdQty,
      DTDelOrderRec: data.DTDelOrderRec,
      PriceTypeSales: data.PriceTypeSales,
      PriceTypePurchase: data.PriceTypePurchase,
      SalesUnitPriceScale1: data.SalesUnitPriceScale1,
      SalesUnitPriceScale2: data.SalesUnitPriceScale2,
      SalesUnitPriceScale3: data.SalesUnitPriceScale3,
      SalesUnitPriceScale4: data.SalesUnitPriceScale4,
      SalesUnitPriceScale5: data.SalesUnitPriceScale5,
      SalesUnitPriceUnitScale1: data.SalesUnitPriceUnitScale1,
      SalesUnitPriceUnitScale2: data.SalesUnitPriceUnitScale2,
      SalesUnitPriceUnitScale3: data.SalesUnitPriceUnitScale3,
      SalesUnitPriceUnitScale4: data.SalesUnitPriceUnitScale4,
      SalesUnitPriceUnitScale5: data.SalesUnitPriceUnitScale5,
      PurchUnitPriceScale1: data.PurchUnitPriceScale1,
      PurchUnitPriceScale2: data.PurchUnitPriceScale2,
      PurchUnitPriceScale3: data.PurchUnitPriceScale3,
      PurchUnitPriceScale4: data.PurchUnitPriceScale4,
      PurchUnitPriceScale5: data.PurchUnitPriceScale5,
      PurchUnitPriceUnitScale1: data.PurchUnitPriceUnitScale1,
      PurchUnitPriceUnitScale2: data.PurchUnitPriceUnitScale2,
      PurchUnitPriceUnitScale3: data.PurchUnitPriceUnitScale3,
      PurchUnitPriceUnitScale4: data.PurchUnitPriceUnitScale4,
      PurchUnitPriceUnitScale5: data.PurchUnitPriceUnitScale5,
      BulkSales: data.BulkSales,
      BulkShippingFee: data.BulkShippingFee,
      CoolDeliveryCharge: data.CoolDeliveryCharge,
      SalespersonCode: data.SalespersonCode,
      LastOrderDate: data.LastOrderDate,
      WebOrderNumber: data.WebOrderNumber,
      ExpirationStart:moment(data.ExpirationStart).utcOffset(9).format('YYYYMMDD'),
      Expired: data.Expired ? moment(data.Expired).utcOffset(9).format('YYYYMMDD') : "",
      RecordSalesDate:moment(data.RecordSalesDate).utcOffset(9).format('YYYYMMDD'),
      RecordSalesApplicable:data.RecordSalesApplicable,
      PurchaseAccountDate: moment(data.PurchaseAccountDate).utcOffset(9).format('YYYYMMDD'),
      AcctForPurchasesApplicableble: data.AcctForPurchasesApplicableble,
      SalesRevRate: data.SalesRevRate,
      SalesRevPrice: data.SalesRevPrice,
      PurchaseRevRate: data.PurchaseRevRate,
      PurchaseRevPrice: data.PurchaseRevPrice,
      ActualFlag: data.ActualFlag,
      OrderQuantity: data.OrderQuantity,
      HistoryNum: data.HistoryNum,
      Remarks: data.Remarks,
      UpDailyPayment: data.UpDailyPayment,
      UpTime: data.UpTime,
      SeqNo: data.SeqNo,
      QueryId: "P",
    }
  }));
  return results
}
async function inputCheck(dialogText,useGroupClassification) {
  let dataAll = [];
  let arr = []
  let arr1 = []
  const listArOrder = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIDIndex, {
    QueryId: "P",
    limit: 10000,
  }));
  if (listArOrder.data.queryArPriceInfosByQueryIDIndex.items && listArOrder.data.queryArPriceInfosByQueryIDIndex.items.length > 0) {
    dataAll = listArOrder.data.queryArPriceInfosByQueryIDIndex.items
    let nextToken = listArOrder.data.queryArPriceInfosByQueryIDIndex.nextToken;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(queryArPriceInfosByQueryIDIndex, {
          QueryId: "P",
          limit: 10000,
          nextToken: nextToken
        }))
      if (InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
        nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
          i++
        ) {
          dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
        }
      }
    }
  }
  arr = JSON.parse(JSON.stringify(dataAll))
 dataAll.map((item) => {
    if (item.ItemCode === dialogText.ItemCode &&
      item.OrderReceiptCode === dialogText.OrderReceiptCode &&
      item.shipmentCode === dialogText.shipmentCode &&
      item.EUARBmgmtCode === dialogText.EUARBmgmtCode &&
      item.MaterialCode === dialogText.MaterialCode &&
      item.SalesDocType === dialogText.SalesDocType &&
      item.SuppName === dialogText.SuppName &&
      item.SuppGroup === dialogText.SuppGroup &&
      item.UseGroupClassification === useGroupClassification
    ) {
      if (item.ActualFlag === "0" || item.ActualFlag === "1") {
        arr1.push(item)
      }
    }
  })
  return { dataAll: arr1, arr: arr }
}

async function queryArPrice(useGroupClassification) {
  let dataAll = [];
  let arr = []
  let arr1 = []

  let seqnolist = []
  const listArOrder = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIDIndex, {
    QueryId: "P",
    limit: 10000,
    filter:{UseGroupClassification:{eq:useGroupClassification}}
  }));
  if (listArOrder.data.queryArPriceInfosByQueryIDIndex !== null) {
    dataAll = listArOrder.data.queryArPriceInfosByQueryIDIndex.items
    let nextToken = listArOrder.data.queryArPriceInfosByQueryIDIndex.nextToken;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(queryArPriceInfosByQueryIDIndex, {
          QueryId: "P",
          limit: 10000,
          nextToken: nextToken,
          filter:{UseGroupClassification:{eq:useGroupClassification}}
        }))
      if (InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
        nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
          i++
        ) {
          dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
        }
      }
    }
  }
  arr = JSON.parse(JSON.stringify(dataAll))
  dataAll.map((item) => {
    let newitem = {};
    newitem.ItemCode = item.ItemCode
    newitem.MaterialCode = item.MaterialCode
    newitem.ItemName = item.ItemName
    newitem.OrderReceiptCode = item.OrderReceiptCode
    newitem.SuppGroup = item.SuppGroup
    newitem.ShipmentCode = item.ShipmentCode
    newitem.EUARBmgmtCode = item.EUARBmgmtCode
    newitem.HistoryNum = item.HistoryNum
    newitem.SeqNo = item.SeqNo
    newitem.ActualFlag = item.ActualFlag
    arr1.push(newitem)
    seqnolist.push(item.SeqNo)
   })
   return { dataAll: arr1, arr: arr,seqnolist:seqnolist }
}
const getOrderRecipient = async () => {
  let OrderRecipientDataAll = []
  const OrderRecipientList = await API.graphql(
    graphqlOperation(listArOrderRecipients, {
      limit: 10000,
    }))
  if (OrderRecipientList.data.listArOrderRecipients !== null) {
    let nextToken = OrderRecipientList.data.listArOrderRecipients.nextToken;
    OrderRecipientDataAll = OrderRecipientList.data.listArOrderRecipients.items;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(listArOrderRecipients, {
          limit: 10000,
          nextToken: nextToken,
        }))
      if (InvDataSec.data.listArOrderRecipients !== null) {
        nextToken = InvDataSec.data.listArOrderRecipients.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArOrderRecipients.items.length;
          i++
        ) {
          OrderRecipientDataAll.push(InvDataSec.data.listArOrderRecipients.items[i]);
        }
      }
    }
  }
  return OrderRecipientDataAll
}
const getlistArItemMasters = async (useGroupClassification) => {
  let ItemMaster = [];
  const list = await API.graphql(graphqlOperation(listArItemMasters, { limit: 10000,filter:{ARBSuppAgencyCode:{eq:useGroupClassification},DelFlag:{ne:"1"}} }));
  if (list.data.listArItemMasters.items && list.data.listArItemMasters.items.length > 0) {
    ItemMaster = list.data.listArItemMasters.items
    let nextToken = list.data.listArItemMasters.nextToken;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(listArItemMasters, {
          limit: 10000,
          nextToken: nextToken,
          filter:{ARBSuppAgencyCode:{eq:useGroupClassification},DelFlag:{ne:"1"}}
        }))
      if (InvDataSec.data.listArItemMasters !== null) {
        nextToken = InvDataSec.data.listArItemMasters.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArItemMasters.items.length;
          i++
        ) {
          ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
        }
      }
    }
  }
  return ItemMaster
}
const getlistArEndUsers = async () => {
  let EndUserInfoLIst = []
  const EndUseList = await API.graphql(
    graphqlOperation(listArEndUsers, { limit: 10000 })
  );
  if (EndUseList.data.listArEndUsers !== null) {
    EndUserInfoLIst = EndUseList.data.listArEndUsers.items;
    let nextToken = EndUseList.data.listArEndUsers.nextToken;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(listArEndUsers, {
          limit: 10000,
          nextToken: nextToken,
        }))
      if (InvDataSec.data.listArEndUsers !== null) {
        nextToken = InvDataSec.data.listArEndUsers.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArEndUsers.items.length;
          i++
        ) {
          EndUserInfoLIst.push(InvDataSec.data.listArEndUsers.items[i]);
        }
      }
    }
  }
  return EndUserInfoLIst
}
const getlistArCustomerAddresses = async () => {
  let listCustomerAddressList = []
  let listAddressInfo = await API.graphql(
    graphqlOperation(listArCustomerAddresses, { limit: 10000 })
  );
  if (listAddressInfo.data.listArCustomerAddresses !== null) {
    listCustomerAddressList = listAddressInfo.data.listArCustomerAddresses.items;
    let nextToken = listAddressInfo.data.listArCustomerAddresses.nextToken;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(listArCustomerAddresses, {
          limit: 10000,
          nextToken: nextToken,
        }))
      if (InvDataSec.data.listArCustomerAddresses !== null) {
        nextToken = InvDataSec.data.listArCustomerAddresses.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArCustomerAddresses.items.length;
          i++
        ) {
          listCustomerAddressList.push(InvDataSec.data.listArCustomerAddresses.items[i]);
        }
      }
    }
  }
  return listCustomerAddressList
}
// const getPriceinfo = async () => {
//   let dataAll = []
//   const listArOrder = await API.graphql(graphqlOperation(listArPriceInfos, { limit: 10000 }));
//   if (listArOrder.data.listArPriceInfos.items && listArOrder.data.listArPriceInfos.items.length > 0) {
//     dataAll = listArOrder.data.listArPriceInfos.items
//     let nextToken = listArOrder.data.listArPriceInfos.nextToken;
//     while (nextToken !== null) {
//       let InvDataSec = await API.graphql(
//         graphqlOperation(listArPriceInfos, {
//           limit: 10000,
//           nextToken: nextToken,
//         }))
//       if (InvDataSec.data.listArPriceInfos !== null) {
//         nextToken = InvDataSec.data.listArPriceInfos.nextToken;
//         for (
//           let i = 0;
//           i < InvDataSec.data.listArPriceInfos.items.length;
//           i++
//         ) {
//           dataAll.push(InvDataSec.data.listArPriceInfos.items[i]);
//         }
//       }
//     }
//   }
//   return dataAll
// }
export {
  // getPriceinfo,
  getOrderRecipient,
  getlistArItemMasters,
  getlistArEndUsers,
  getlistArCustomerAddresses,
  checkedMasterlist,
  searchData,
  openDilogDetill,
  inputCheck,
  deleteData,
  modifyData,
  addData,
  queryArPrice
}