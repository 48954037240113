import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import useStyles from "./ArItemMasterStyle";
import Button from "@material-ui/core/Button";
import API, { graphqlOperation } from "@aws-amplify/api";
import { customerByeMail,queryMaterialStgLocsSalesDocType} from "../../../graphql/queries";
import { Auth } from "aws-amplify"; import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {createMaterialStgLoc,updateMaterialStgLoc}from "../../../graphql/mutations"
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
export default function MasterDialogstorageDetill(param) {
    const classes = useStyles()
    const [searchProgress, setSearchProgress] = useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };
    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };
    const handleConfirmOK = async () => {
        setOpenConfirm(false);
    }
    const [errorcode, setErrorCode] = React.useState(Object);
    const handleSetErrorcode = (Object) => {
        setErrorCode(Object);
    };
    const [msg1Text, setMsg1Text] = React.useState(Object);
    const handleSetMsg1Text = (Object) => {
        setMsg1Text(Object);
    };
    const [msg2Text, setMsg2Text] = React.useState(Object);
    const handleSetMsg2Text = (Object) => {
        setMsg2Text(Object);
    };
    const [ItemStorageVal, setItemStorageVal] = useState({
        ItemCode: param.ItemCode,
        Text: param.ItemName,
        Plant: "",
        PlantName: "",
        StorageLoc: "",
        StorageLocText: "",
        SalesDocType: "",
        SalesGroup: "",
        SalesGroupName: "",
        id:param.id,
        UsageCategory:param.UsageCategory,
    })
    const [isValid, setIsValid] = useState({
        ItemCode: false,
        Text: false,
        Plant: false,
        PlantName: false,
        StorageLoc: false,
        StorageLocText: false,
        SalesDocType: false,
        SalesGroup: false,
        SalesGroupName: false,
    })
    const [disabledBtn,setDisabledBtn] = useState(false)
    const [isOk,setIsOk] = useState(false)
    const onChangeVal = (e) => {
        setItemStorageVal({ ...ItemStorageVal, [e.target.name]: e.target.value })
        if (e.target.value) {
            setIsValid({ ...isValid, [e.target.name]: false })
        }
    }
    useEffect(async()=>{
        setSearchProgress(true)
        if(param.UsageCategory !== param.useGroupClassification){
            setDisabledBtn(true)
        }
            let StgLocationlist = []

          let StgLocationInfo = await API.graphql(graphqlOperation(queryMaterialStgLocsSalesDocType, { ItemCode:ItemStorageVal.ItemCode, filter: {Text:{ eq: ItemStorageVal.Text },UsageCategory:{ eq:param.useGroupClassification}}}));
              if (StgLocationInfo.data.queryMaterialStgLocsSalesDocType !== null && StgLocationInfo.data.queryMaterialStgLocsSalesDocType["items"].length > 0) {
                StgLocationInfo.data.queryMaterialStgLocsSalesDocType["items"].forEach((item) => {
                  StgLocationlist.push(item)
                });
              }
        if(StgLocationlist.length > 0){
            setIsOk(true)
            setItemStorageVal({...ItemStorageVal,
                UsageCategory:StgLocationlist[0].UsageCategory,
                ItemCode: StgLocationlist[0].ItemCode,
                Text:StgLocationlist[0].Text,
                Plant: StgLocationlist[0].Plant,
                PlantName: StgLocationlist[0].PlantName,
                StorageLoc: StgLocationlist[0].StorageLoc,
                StorageLocText: StgLocationlist[0].StorageLocText,
                SalesDocType: StgLocationlist[0].SalesDocType,
                SalesGroup: StgLocationlist[0].SalesGroup,
                SalesGroupName: StgLocationlist[0].SalesGroupName,
                id:StgLocationlist[0].id})
        }
        setSearchProgress(false)
    },[])
    const inputCheckinfo = async (data)=>{
        let StgLocationlist = []
        let StgLocationInfo = await API.graphql(graphqlOperation(queryMaterialStgLocsSalesDocType, { ItemCode:ItemStorageVal.ItemCode,
            filter: {
                Text:{ eq: ItemStorageVal.Text },
                Plant:{eq: data.Plant},
                PlantName:{eq: data.PlantName},
                StorageLoc: {eq:data.StorageLoc},
                StorageLocText:{eq: data.StorageLocText},
                SalesDocType:{eq: data.SalesDocType},
                SalesGroup:{eq:data.SalesGroup} ,
                SalesGroupName:{eq: data.SalesGroupName},
                UsageCategory:{eq: data.UsageCategory}
              }}));
        if (StgLocationInfo.data.queryMaterialStgLocsSalesDocType !== null && StgLocationInfo.data.queryMaterialStgLocsSalesDocType["items"].length > 0) {
            StgLocationlist = StgLocationInfo.data.queryMaterialStgLocsSalesDocType.items
            // let nextToken = StgLocationInfo.data.queryMaterialStgLocsSalesDocType.nextToken;
            // while (nextToken !== null) {
            //     let InvDataSec = await API.graphql(
            //         graphqlOperation(listMaterialStgLocs, {
            //             limit: 10000,
            //             nextToken: nextToken,
            //             filter: {ItemCode:{eq:data.ItemCode},
            //             Text:{eq:data.Text} ,
            //             Plant:{eq: data.Plant},
            //             PlantName:{eq: data.PlantName},
            //             StorageLoc: {eq:data.StorageLoc},
            //             StorageLocText:{eq: data.StorageLocText},
            //             SalesDocType:{eq: data.SalesDocType},
            //             SalesGroup:{eq:data.SalesGroup} ,
            //             SalesGroupName:{eq: data.SalesGroupName},}
            //         }))
            //     if (InvDataSec.data.listMaterialStgLocs !== null) {
            //         nextToken = InvDataSec.data.listMaterialStgLocs.nextToken;
            //         for (
            //             let i = 0;
            //             i < InvDataSec.data.listMaterialStgLocs.items.length;
            //             i++
            //         ) {
            //             StgLocationlist.push(InvDataSec.data.listMaterialStgLocs.items[i]);
            //         }
            //     }
            // }
        }
        return StgLocationlist
    }
    const submitfun = async () => {
        setSearchProgress(true)
        if (!ItemStorageVal.ItemCode) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, ItemCode: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, ItemCode: false })
        }
        if (!ItemStorageVal.Text) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, Text: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, Text: false })
        }
        if (!ItemStorageVal.Plant) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, Plant: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, Plant: false })
        }
        if (!ItemStorageVal.PlantName) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, PlantName: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, PlantName: false })
        }
        if (!ItemStorageVal.StorageLoc) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, StorageLoc: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, StorageLoc: false })
        }
        if (!ItemStorageVal.StorageLocText) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, StorageLocText: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, StorageLocText: false })
        }
        if (!ItemStorageVal.SalesDocType) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, SalesDocType: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, SalesDocType: false })
        }
        if (!ItemStorageVal.SalesGroup) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, SalesGroup: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, SalesGroup: false })
        }
        if (!ItemStorageVal.SalesGroupName) {
            handleSetErrorcode("エラーコード：1004");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("未入力エラー。未入力項目を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, SalesGroupName: true })
            setSearchProgress(false)
            return;
        } else {
            setIsValid({ ...isValid, SalesGroupName: false })
        }
         let checkedList = []
        try {
            checkedList = await inputCheckinfo(ItemStorageVal);
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        if(checkedList.length > 0){
            handleSetErrorcode("エラーコード：1007");
            handleSetMsg1Text("登録エラー");
            handleSetMsg2Text("入力された情報は既に品目保管場所マスタに登録されています");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, ItemCode: true,Text: true,Plant: true ,PlantName: true ,SalesDocType: true ,SalesGroup: true ,
                SalesGroupName: true ,StorageLoc: true ,StorageLocText:true })
            setSearchProgress(false)
            return;
        }
        if(isOk){
            await API.graphql(graphqlOperation(updateMaterialStgLoc, {
                input: {
                    ItemCode: ItemStorageVal.ItemCode,
                    Text: ItemStorageVal.Text,
                    Plant: ItemStorageVal.Plant,
                    PlantName: ItemStorageVal.PlantName,
                    SalesDocType: ItemStorageVal.SalesDocType,
                    SalesGroup: ItemStorageVal.SalesGroup,
                    SalesGroupName: ItemStorageVal.SalesGroupName,
                    StorageLoc: ItemStorageVal.StorageLoc,
                    StorageLocText: ItemStorageVal.StorageLocText,
                    id:ItemStorageVal.id,
                    UsageCategory:ItemStorageVal.UsageCategory
                }
            }));
        }else{
            await API.graphql(graphqlOperation(createMaterialStgLoc, {
                input: {
                    ItemCode: ItemStorageVal.ItemCode,
                    Text: ItemStorageVal.Text,
                    Plant: ItemStorageVal.Plant,
                    PlantName: ItemStorageVal.PlantName,
                    SalesDocType: ItemStorageVal.SalesDocType,
                    SalesGroup: ItemStorageVal.SalesGroup,
                    SalesGroupName: ItemStorageVal.SalesGroupName,
                    StorageLoc: ItemStorageVal.StorageLoc,
                    StorageLocText: ItemStorageVal.StorageLocText,
                    UsageCategory:ItemStorageVal.UsageCategory
                }
            }));
        }
            setSearchProgress(false)
            param.closeDialogstorageDetill()
    }
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };
    useEffect(async () => {
        setSearchProgress(true)
        let user = ""
        try {
            user = await Auth.currentUserInfo();
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        const agencyPriceGroup = await API.graphql(
            graphqlOperation(customerByeMail, {
                Agency_Email: user.attributes.email,
            })
        );
        const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
        if (agentinfo.length > 0) {
            const Permission = agentinfo[0].Permission
            console.log(Permission)
        }
        setSearchProgress(false)
    }, [])
    return (
        <div style={{ width: "1150px" }}>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "150px" }}>
                            <span className={classes.textlabelSpanDialog}>品目</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "260px", height: "25px", borderRadius: "4px", borderColor: isValid.ItemCode ? "pink" : "#707070" }}
                                disabled
                                value={ItemStorageVal.ItemCode}
                                name="ItemCode"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "120px" }}>
                            <span className={classes.textlabelSpanDialog}>品目名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "520px", height: "25px", borderRadius: "4px" , borderColor: isValid.Text ? "pink" : "#707070"}}
                                disabled
                                value={ItemStorageVal.Text}
                                name="Text"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "150px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>プラント</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "90px", height: "25px", borderRadius: "4px", borderColor: isValid.Plant ? "pink" : "#707070"}}
                                value={ItemStorageVal.Plant}
                                name="Plant"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "120px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>プラント名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "90px", height: "25px", borderRadius: "4px" , borderColor: isValid.PlantName ? "pink" : "#707070"}}
                                value={ItemStorageVal.PlantName}
                                name="PlantName"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "150px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>保管場所</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "130px", height: "25px", borderRadius: "4px" , borderColor: isValid.StorageLoc ? "pink" : "#707070"}}
                                value={ItemStorageVal.StorageLoc}
                                name="StorageLoc"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "80px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>保管場所名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "510px", height: "25px", borderRadius: "4px", borderColor: isValid.StorageLocText ? "pink" : "#707070" }}
                                value={ItemStorageVal.StorageLocText}
                                name="StorageLocText"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "60px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "150px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>販売伝票タイプ</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "90px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesDocType ? "pink" : "#707070" }}
                                value={ItemStorageVal.SalesDocType}
                                name="SalesDocType"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "120px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>営業Grp</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "90px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesGroup ? "pink" : "#707070" }}
                                value={ItemStorageVal.SalesGroup}
                                name="SalesGroup"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "170px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>営業Grp名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "90px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesGroupName ? "pink" : "#707070" }}
                                value={ItemStorageVal.SalesGroupName}
                                name="SalesGroupName"
                                onChange={(e) => onChangeVal(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "120px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "150px" }}>
                            <span className={classes.textlabelSpanDialog}>使用区分</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                disabled={true}
                                style={{ width: "90px", height: "25px", borderRadius: "4px" , borderColor:"#707070"}}
                                value={ItemStorageVal.UsageCategory}
                                name="UsageCategory"
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ position: "fixed", left: "50%", top: "50%", display: searchProgress === true ? "block" : "none" }}>
                    <CircularProgress />
                </div>
            <div style={{ textAlign: "right" }}>
                <Button className="btn" onClick={()=>param.closeDialogstorageDetill()} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>戻&nbsp;&nbsp;&nbsp;る
                </Button>
                <Button className="btn" disabled={disabledBtn} onClick={() => submitfun()} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "red", marginRight: '80px', borderRadius: '8px', border: "1px solid #000"}}>登録
                </Button>
            </div>
            <Dialog
                onClose={handleConfirmClose}
                aria-labelledby="customized-dialog-title"
                open={openConfirm}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        width: "400px",
                        height: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "20px" }} className={classes.textFieldDialog}>
                        {errorcode}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                        {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                        {msg2Text}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleConfirmOK();
                    }} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}