
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  OrderConfirmation.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary OrderConfirmation画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
import userAuth from "../components/userAuth";
import Amplify , { Auth }from "aws-amplify";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { updateArOrder, updateArAgencyOrderInfo,createArPriceInfo,updateArPriceInfo} from "../graphql/mutations";
import { createArOrder, createArAgencyOrderInfo } from "../graphql/mutations";
import { deleteArAgencyOrderInfo } from "../graphql/mutations";
import {queryEndUserSpecialPricingsByEndUserCodeIndex,queryArPriceInfosByQueryIDIndex3,listArcalendars,queryArPriceInfosByQueryIDIndex4,queryArPriceInfosByQueryIDIndex,queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex} from "../graphql/queries";
import { useLocation } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import BootstrapTable from "react-bootstrap-table-next";
import qs from 'qs';
import {
  orderByQuoteNumber,
  listArAgencyOrderInfos,
  listArAgencyOrderInfoByDetailsNumber,
  customerByeMail
  // getArAgencyByAgencyid,

} from "../graphql/queries";
import { updateArInventory } from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import { TextField } from '@material-ui/core';
import InputBase from "@material-ui/core/InputBase";
import ArToast from "../components/ArToast";
import "../components/ArGlobal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Select, MenuItem, InputLabel, TableHead } from "@material-ui/core";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { Storage } from "aws-amplify";
const TAX_RATE = 0.1;
let searchQuoteNumber = "";
let userAuths = ""
let searchDetailsNumber = "";
let userName = "";
let assigneeCode = "";
let useGroupClassification = ''
let usageSystem = ""
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  rootl: {
    maxWidth: "100%",
  },
  card1: {
    maxWidth: "100%",
  },
  card2: {
    maxWidth: "100%",
  },
  table: {
    minWidth: "auto",
    display: "flex",
    justifyContent: "end",
    border: "none",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
  },
  noframeTableCell: {
    border: "none",
    paddingRight:'0px',
    paddingBottom:'5px'


  },
  textlabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "16ch",
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
    fontWeight: "bold",
  },
  textlabel1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: "5ch",
    background: "transparent",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  // textlabelEnd: {
  //   //marginLeft: theme.spacing(1),
  //   //marginRight: theme.spacing(1),
  //   width: "14ch",
  //   background: "transparent",
  //   borderBottom: "1px dashed #707070",
  //   borderLeft: "4px solid #804F29",
  //   paddingLeft: "5px",
  //   border: "none",
  //   borderRadius: "1px",
  //   color: "black",
  //   fontWeight: "bold",
  // },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "20ch",
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  textMemoField: {
    background: "transparent",
    borderBottom: "1px dashed #707070",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldOrderQuantity: {
    margin: theme.spacing(0),
    position: "relative",
    height: "100%",
  },
  textFieldDialog: {
    margin: theme.spacing(0),
    alignItems: "center",
    textAlign: "center",
    color: "white",
  },
  textField2Dialog: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    'font-size': '12px',
    color: "white",
  },
  Dialog: {
    // margin: theme.spacing(0),
    // alignItems: "center",
    // textAlign: "center",
    // color: "#ecb445",
    "background-color": "#ecb445",
    // backgroundColor: "#ecb445",
    // borderColor: "#ecb445",
  },
  DialogButton: {
    margin: theme.spacing(0),
    alignItems: "center",
    textAlign: "center",
  },
  resize: {
    height: "100%",
  },
}));

// function getSteps() {
//   return ["受注受付", "受付処理中", "納期確定", "受注キャンセル"];
// }
let Status = "1";
let OutputFlgTemp = "";
let orderID = "";
let invoiceSubtotal = 0;
let invoiceFax = 0;
let invoiceTotal = 0
let CampaignPrice = 0
let quantityPrice = 0
let invoicePayTotal = 0
let histotyOrder = [];
let historyDate;
let AgencyEmails = [];
let disableBtn = false
let remandBtn = false
const OrderConfirmation = () => {
  function toPoint(percent) {
    let str = percent.replace("%", "");
    str = str / 100;
    return str;
  }

  const headerStyle = {
    backgroundColor: "white",
    borderBottom: "3px solid #804F29",
  };
  const columns = [
    { dataField: "id", text: "ID", hidden: true },
    {
      dataField: "Product_Code",
      text: "品目",
      sort: true,
      editable: false,
      style: { margin: "0px", width: "200px" },
      headerStyle,
    },
    { dataField: "Product_Name_j", text: "品名", headerStyle, style: { margin: "0px", width: "700px" }, },
    {
      dataField: "biko",
      text: "備考",
      sort: true,
      editable: false,
      style: { margin: "0px", width: "150px" },
      headerStyle,
      formatter: (cellContent, row) => {
        if (row.Wholes_Rate !== "-") {
          return <span></span>;
        } else {
          return <span>{row.Wholes_Price}</span>;
        }
      },
    },
    // { dataField: "Campaign_price", text: "定価", align: "right", headerStyle },
    // { dataField: "Wholes_Rate", text: "仕切率", align: "right", headerStyle },
    {
      dataField: "OrderQuantity",
      text: "数量 ",
      align: "right",
      type: "number",
      headerStyle,
      style: { margin: "0px", width: "100px" },
      formatter: (cellContent, row) => {
        if (row.id !== null) {
          return (
            <InputBase
              id={row.id}
              color="primary"
              type="number"
              defaultValue={row.OrderQuantity}
              name="count"
              variant="outlined"
              readOnly={!orderApprovalFlag}
              className={classes.textFieldOrderQuantity}
              onBlur={(e) => {
                handleSetCount(row, e.target.value, row.Quantity);
              }}
              onChange={(e) => {
                handleSetMax(row.id, row.Quantity, e.target.value);
              }}
            />
          );
        }
      }
    }, {
      dataField: "Wholes_Price",
      text: "御注文金額",
      sort: true,
      align: "right",
      editable: false,
      style: { margin: "0px", width: "160px" },
      headerStyle,
      formatter: (cellContent, row) => {
        if (row.Wholes_Rate === "-") {
          return <span>-</span>;
        } else {
          return (
            <span>
              {row.Wholes_Price}
            </span>
          );
        }
      },
    },
    {
      dataField: "Total",
      text: "小計 ",
      align: "right",
      type: "number",
      hidden: true,
      headerStyle,
      formatter: (cellContent, row) => {
        return (
          <span>
            {numtomoney(
              Math.round(
                parseFloat(row.OrderQuantity) *
                parseFloat(moneytonum(row.Campaign_price)) *
                parseFloat(toPoint(row.Wholes_Rate))
              )
            )}
          </span>
        );
      },
    },
    // { dataField: "Wholes_Price", text: "提供価格", hidden: true },
  ];
  const classes = useStyles();
  // const steps = getSteps();
  const history = useHistory();
  // const [displayFlg, setDisplayFlg] = React.useState(Object);
  const [orderApprovalFlag, setOrderApprovalFlag] = React.useState(false);
  const [orderlifeApprovalFlag, setOrderlifeApprovalFlag] = React.useState(false);
  const [treated, setTreated] = React.useState(false);
  const [orderDataText, setOrderData] = React.useState(Object);
  const [toast, setToast] = React.useState({
    open: false,
    message: "send Email success",
    severity: "success",
  });
  // const handleSetAuthFlg = (Object) => {
  //   // setDisplayFlg(Object);
  // };
  const [orderinfo, setOrderinfo] = useState([]);
  const [orderaddressinfo, setOrderaddressinfo] = useState(Object);
  const [checkedA, setStateA] = React.useState(false);
  const [checkedB, setStateB] = React.useState(false);
  const [checkedC, setStateC] = React.useState(false);
  const [mitumoriNoText, setMitumoriNo] = React.useState(Object);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [searchProgress, setSearchProgress] = useState(false);
  const handleCompanMemoChange = (event, item)=>{
    if (item) {
      item.CompanMemo = event.target.value;
    }
  }
  const handleClickConfirmOpen = () => {
    //電子材料部門,ダイアログは表示されません
    if(electronicMaterialsDepartment === true){
      setOpenConfirm(false);
      handleConfirmOK();
      return;
    }
    setOpenConfirm(true);
  };
  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };
  const handleConfirmOK = async() => {
    setSearchProgress(true)
    await updateArOrders();
    // numberKu();
    // a1 : 発注， a6 canceld order; a5：saler 承認
    //シリコーンの場合、メールを送信しない

    if(userAuths !== "4" && userAuths !== "3" && userAuths !== "2"){
      let templteType = "a1"; // agency cancled order
      for (let i in orderinfo) {
        if (orderinfo[i].OrderQuantity !== 0) {
          templteType = "a1";
        }
      }
      if (templteType === "a1") {
        let msgurls = [];
        orderinfo.forEach(item => {
          msgurls.push(global.ArGlob.OrderConfirmationAddress + searchQuoteNumber + "&OrderDetailsNumber=" + item.OrderDetailsNumber);
        })
        const body = {
          templete: "a1",
          subject: "ARBOS受注登録完了_" + orderaddressinfo.AgencyName + "_" + orderaddressinfo.GroupName + "_" + searchQuoteNumber,
          msgUrl: global.ArGlob.HostIpAddress + searchQuoteNumber,
          // linkUrls:msgurls,
          txtEnd: "",
          receiveUser: "",
          message:
          "営業アシスタント様<br><br>【" +
          searchQuoteNumber +
          " 】代理店様受注登録が行われました。　<br>内容確認と後続処理をお願いします。",
        };
        sendEmail(body, "Saler");
        sendAgencyEmail(templteType);
      }
    }
    if(userAuths === "4" || userAuths === "3" || userAuths === "2"){
      history.push({ pathname: "/InventorySearchEmd"});
    }else{
      history.push({ pathname: "/InventorySearch" });
    }
    setSearchProgress(false)
  };
  const handleSetMitumoriNoText = (Object) => {
    setMitumoriNo(Object);
  };

  // function txtChangedMitumoriNo(e) {
  //   handleSetMitumoriNoText(e.target.value);
  // }
  const handleChangeA = (event) => {
    setStateA(event.target.checked);
  };
  const handleChangeB = (event) => {
    setStateB(event.target.checked);
  };
  const handleChangeC = (event) => {
    setStateC(event.target.checked);
  };
  // const [dataAll, SetdataAll] = useState()
  function orderClick() {
    handleClickConfirmOpen();
  }
  // Email the agency
  async function sendAgencyEmail(emailtemplete) {
    if (emailtemplete === 'a6') {
      return;
    }
    let bodyAgency;
    if (emailtemplete === "a1") {
      let msgurls = [];
      orderinfo.forEach(item => {
        msgurls.push(global.ArGlob.OrderConfirmationAddress + searchQuoteNumber + "&OrderDetailsNumber=" + item.OrderDetailsNumber);
      })
      bodyAgency = {
        templete: "a1",
        //subject: "【" + searchQuoteNumber + " 】ご受注登録ありがとうございます。",
        subject: "エア・ブラウンARBOS_受注登録完了_" + searchQuoteNumber,
        msgUrl: global.ArGlob.OrderConfirmationAddress + searchQuoteNumber,
        // linkUrls:msgurls,
        txtEnd: "",
        receiveUser: "Agency",
        toAgencyEmails: AgencyEmails,
        message:
          "代理店御中<br><br>【" +
          searchQuoteNumber +
          " 】注文登録頂きありがとうございました。　<br>内容は下記よりご確認ください。",
      };
    } else if (emailtemplete === "a6") {
      let messageInfo =
        "*******御中<br>" +
        "<pre>                                            エア・ブラウン株式会社<pre><br>" +
        "<pre>                                            エア・ブラウン株式会社<pre><br><br>" +
        "いつもお世話になっております。<br>" +
        "弊社Web受注システムにご注文いただきました" + "【" + searchQuoteNumber + " 】についてキャンセル操作が行われキャンセルが確定いたしましたのでご連絡いたします。<br>" +
        "Web受注登録番号：" +
        searchQuoteNumber +
        "<br>" +
        "なお、ご不明点等ございましたらお問い合わせをお願いします。<br><br>" +
        "以上、よろしくお願いいたします。<br>";

      bodyAgency = {
        templete: "a6",
        subject:
          "エア・ブラウン　Web受注キャンセルのご報告　【" + searchQuoteNumber + "】",
        msgUrl: "",
        receiveUser: "Agency",
        message: messageInfo,
        toAgencyEmails: AgencyEmails,
      };
    }
    sendEmail(bodyAgency, "Agency");
  }
  // 仮在庫数を変更
  async function numberKu() {
    for (let index = 0; index < orderinfo.length; index++) {
      let Quantity = orderinfo[index].Quantity - orderinfo[index].OrderQuantity;
      await API.graphql(
        graphqlOperation(updateArInventory, {
          input: {
            id: orderinfo[index].Product_id,
            Product_Code: orderinfo[index].Product_Code,
            TempInventoryQuantity: Quantity,
          },
        })
      );
    }
  }
  async function remand(item){
    setSearchProgress(true)
    try{
      const result = await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
        input: {
          id: item.id,
          QuoteNumber: item.QuoteNumber,
          Agency_id: item.Agency_id,
          OrderStatus:"1",
          Usage_System:usageSystem
      }}))
    }catch(error){
      if(error.message === 'No current user'){
        // ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        // ログイン画面に戻る
        signOut();
        }else {
        // 他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
      setSearchProgress(false)
      history.push({ pathname: "/OrderList" });
  }
  //使输出的格式为双数
  function addZero(n) {
    return n < 10 ? "0" + n : n + "";
  }
  const reduceDays = async (date, days) => {
    let holidays = [];
    const listArcalendarsList = await API.graphql(
      graphqlOperation(listArcalendars, {
        filter: { IsWorkday: { eq: "0" } },
        limit: 10000,
      }))
    if (listArcalendarsList.data.listArcalendars !== null) {
      let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
      holidays = listArcalendarsList.data.listArcalendars.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(listArcalendars, {
            filter: { IsWorkday: { eq: "0" } },
            limit: 10000,
            nextToken: nextToken,
          }))
        nextToken = InvDataSec.data.listArcalendars.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArcalendars.items.length;
          i++
        ) {
          holidays.push(InvDataSec.data.listArcalendars.items[i]);
        }
      }
    }
    let tempDate = new Date(date);
    for (let i = 0; i < days; i++) {
      tempDate.setDate(tempDate.getDate() - 1);
      if (holidays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === tempDate.getTime())) {
        tempDate.setDate(tempDate.getDate() - 1);
        i--
      }
    }
    return tempDate;
  }
  const PriceTypePurchaseUp = async (input, dataAll, SalesGroupInfo) => {
    let dataInfo = [];
    const InvData = await API.graphql(
      graphqlOperation(queryArPriceInfosByQueryIDIndex4, {
        QueryId: "P",
        limit: 10000,
        filter: {
          ItemCode: { eq: input.ItemCode },
          OrderReceiptCode: { eq: input.OrderReceiptCode },
          EUARBmgmtCode: { eq: input.EUARBmgmtCode },
          ActualFlag:{eq:"0"},
          UseGroupClassification:{eq: useGroupClassification}
        }
      })
    );
    if (InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
      let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
      dataInfo = InvData.data.queryArPriceInfosByQueryIDIndex.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(queryArPriceInfosByQueryIDIndex4, {
            QueryId: "P",
            limit: 10000,
            nextToken: nextToken,
            filter: {
              ItemCode: { eq: input.ItemCode },
              OrderReceiptCode: { eq: input.OrderReceiptCode },
              EUARBmgmtCode: { eq: input.EUARBmgmtCode },
              ActualFlag:{eq:"0"},
              UseGroupClassification:{eq: useGroupClassification}
            }
          }
          ))
        nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
          i++
        ) {
          dataInfo.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
        }
      }
    }
    let arr = [];
    dataAll.forEach((element) => {
      if (element.OrderReceiptCode === input.OrderReceiptCode &&
        element.ShipmentCode === input.ShipmentCode &&
        element.EUARBmgmtCode === input.EUARBmgmtCode) {
        arr.push(element)
      }
    })
    if (arr.length > 0) {
      input.PriceTypeSales = "EU"
      if ((input.SuppName).includes("ダウ・東レ_BOOK") || (input.SuppName).includes("ダウ・東レ_特価")) {
        dataInfo.forEach((items1) => {
          if (items1.TorayEndUser) {
            input.TorayEndUser = items1.TorayEndUser
            input.TorayEndUserName = items1.TorayEndUserName
          }
        })
      }
      return;
    } else {
      dataAll.forEach((element) => {
        if (element.OrderReceiptCode === input.OrderReceiptCode && element.EUARBmgmtCode === input.EUARBmgmtCode) {
          arr.push(element)
        }
      })
    }
    if (arr.length > 0) {
      input.PriceTypeSales = "EU"
      if ((input.SuppName).includes("ダウ・東レ_BOOK") || (input.SuppName).includes("ダウ・東レ_特価")) {
        dataInfo.forEach((items1) => {
          if (items1.TorayEndUser) {
            input.TorayEndUser = items1.TorayEndUser
            input.TorayEndUserName = items1.TorayEndUserName
          }
        })
      }
      return;
    } else {
        dataAll.forEach((element) => {
          if (element.PriceTypePurchase === "EU" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
            arr.push(element)
          }
        })
    }
    if (arr.length > 0) {
      input.PriceTypeSales = "EU"
      if ((input.SuppName).includes("ダウ・東レ_BOOK") || (input.SuppName).includes("ダウ・東レ_特価")) {
        input.TorayEndUser = "1060238"
        input.TorayEndUserName = "エア・ブラウン株式会社 大阪支店"
      }
      return;
    } else {
        dataAll.forEach((element) => {
          if (element.PriceTypePurchase === "代" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
            arr.push(element)
          }
        })
    }
    if (arr.length > 0) {
      input.PriceTypeSales = "EU"
      if ((input.SuppName).includes("ダウ・東レ_BOOK") || (input.SuppName).includes("ダウ・東レ_特価")) {
        input.TorayEndUser = "1060238"
        input.TorayEndUserName = "エア・ブラウン株式会社 大阪支店"
      }
      return;
    } else {
      let flag = false
      let type = ""
      const InvData = await API.graphql(
        graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
          OrderReceiptCode: input.OrderReceiptCode,
        })
      );
      if (InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0) {
        type = InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items[0].AgencyType
      }
      if (input.PriceTypePurchase === "B") {
        if ((input.OrderName).includes("KISCO") || (input.OrderName).includes("東和電気")) {
          if (input.SuppGroup === "B10") {
            flag = true
          }
        } else {
          if (SalesGroupInfo !== "162" && SalesGroupInfo !== "181" && SalesGroupInfo !== "141") {
            flag = true
          } else if ((SalesGroupInfo === "162" || SalesGroupInfo === "181") && (type === "B10" || type === "B20" || type === "B15")) {
            flag = true
          }else{
            if(type === "B5" || type === "B10" || type === "B20" || type === "B15"){
              flag = true
            }
          }
        }
      }
      if (flag === true) {
        arr.push(input)
        input.PriceTypeSales = "B"
        if ((input.SuppName).includes("ダウ・東レ_BOOK") || (input.SuppName).includes("ダウ・東レ_特価")) {
          dataInfo.forEach((items1) => {
            if (items1.TorayEndUser) {
              input.TorayEndUser = items1.TorayEndUser
              input.TorayEndUserName = items1.TorayEndUserName
            }
          })
        }
      }
    }
    if (arr.length <= 0) {
      let type = ""
      const InvData = await API.graphql(
        graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
          OrderReceiptCode: input.OrderReceiptCode,
        })
      );
      if (InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0) {
        type = InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items[0].AgencyType
      }
      let flag = false
      if (input.PriceTypePurchase === "A") {
        if ((input.OrderName).includes("KISCO") || (input.OrderName).includes("東和電気")) {
          if (input.SuppGroup === "B0") {
            flag = true
          }
        } else {
          if (SalesGroupInfo !== "162" && SalesGroupInfo !== "181" && SalesGroupInfo !== "141") {
            flag = true
          } else if ((SalesGroupInfo === "162" || SalesGroupInfo === "181") && (type === "B0" || type === "B10" || type === "B20" || type === "B15")) {
            flag = true
          }else{
            if(type === "B0" || type === "B5" || type === "B10" || type === "B20" || type === "B15"){
              flag = true
            }
          }
        }
        if (flag === true) {
          input.PriceTypeSales = "A"
        }
      }
    }
  }
  const UnitPriceScaleFun = async(arr,item)=>{
    let UnitPriceArr = []
    let SalesData = []
    let PurchData = []
    let SalesData2 = []
    let PurchData2 = []
    for (let ele = 0; ele < arr.length; ele++) {
      let flag1 = false,flag2 = false,flag3 = false,flag4 = false;
      if (arr[ele].Expired) {
        if (arr[ele].RecordSalesApplicable === "出荷日") {
          let leadTime = isNaN(parseInt(item.FukuiSeinoLT)) ? 0 : parseInt(item.FukuiSeinoLT)
          let shipDate = await reduceDays((moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
           if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag1 = true
            flag3 = true
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   flag3 = true
            // }
          }
        } else if (arr[ele].RecordSalesApplicable === "納品日") {
          if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag1 = true
            flag3 = true
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   flag3 = true
            // }
          }
        } else if (arr[ele].RecordSalesApplicable === "受注日") {
         if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
          flag1 = true
          flag3 = true
          // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
          //   flag3 = true
          //   }
          }
        }
      } else {
        if (arr[ele].RecordSalesApplicable === "出荷日") {
          let leadTime = isNaN(parseInt(item.FukuiSeinoLT)) ? 0 : parseInt(item.FukuiSeinoLT)
          let shipDate = await reduceDays((moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
          if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag1 = true
            flag3 = true
          }
        } else if (arr[ele].RecordSalesApplicable === "納品日") {
         if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD'))) {
          flag1 = true
          flag3 = true
          }
        } else if (arr[ele].RecordSalesApplicable === "受注日") {
          if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            flag1 = true
            flag3 = true
          }
        }
      }
      if (arr[ele].Expired) {
        if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
          let leadTime = isNaN(parseInt(item.FukuiSeinoLT)) ? 0 : parseInt(item.FukuiSeinoLT)
          let shipDate = await reduceDays((moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
          if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag2 = true
            flag4 = true
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   flag4 = true
            // }
          }
        } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
          if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag2 = true
            flag4 = true
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   flag4 = true
            // }
          }
        } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
          if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            flag2 = true
            flag4 = true
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            //   flag4 = true
            // }
          }
        }
      } else {
        if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
          let leadTime = isNaN(parseInt(item.FukuiSeinoLT)) ? 0 : parseInt(item.FukuiSeinoLT)
          let shipDate = await reduceDays((moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
          if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag2 = true
            flag4 = true
          }
        } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
          if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY/MM/DD'))) {
            flag2 = true
            flag4 = true
          }
        } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
          if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            flag2 = true
            flag4 = true
          }
        }
      }

      if(flag1 && flag2 && flag3 && flag4){
        UnitPriceArr.push(arr[ele])
      }
      if((flag1 && (!flag2)) || (flag1 && flag2 && flag3 && (!flag4))){
        SalesData.push(arr[ele])
      }
      if((!flag1 && flag2) || (flag1 && flag2 && flag4 && (!flag3))){
        PurchData.push(arr[ele])
      }

      if((flag1 && flag3) && (!flag2)){
        SalesData2.push(arr[ele])
      }
      if((flag2 && flag4) && (!flag1)){
        PurchData2.push(arr[ele])
      }
    }
    if(SalesData.length > 1){
      SalesData = SalesData2
    }
    if(PurchData.length > 1){
      PurchData = PurchData2
    }
    return {UnitPriceArr:UnitPriceArr,SalesData:SalesData,PurchData:PurchData}
  }
  //承认click
  async function orderApprovalClick(item, index) {
    setSearchProgress(true)
    try{
    let QuoteNumber = "";
    let ShippingBlock = "";
    let OutputFlag = "";
    let count = 1;
    if (item.checkedA && item.checkedB) {
      ShippingBlock = "";
      OutputFlag = "0";
    } else if (item.checkedA && !item.checkedB) {
      ShippingBlock = "";
      OutputFlag = "1";
    } else if (!item.checkedA && item.checkedB) {
      ShippingBlock = "Z1";
      OutputFlag = "0";
    } else {
      ShippingBlock = "Z1";
      OutputFlag = "1";
    }
  if(remandBtn === true && OutputFlag === "0"){
    if (!(item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) {
      let infoArr = []
      const InvData = await API.graphql(
        graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
          QueryId: "P",
          limit: 10000
        })
      );
      if (InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
        let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
        infoArr = InvData.data.queryArPriceInfosByQueryIDIndex.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(
            graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
              QueryId: "P",
              limit: 10000,
              nextToken: nextToken
            }
            ))
          nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
          for (
            let i = 0;
            i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
            i++
          ) {
            infoArr.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
          }
        }
      }
      let infos = [];
      let ArrFilter = []
      let number = 0
      for (let i = 0; i < infoArr.length; i++) {
        if (Number(infoArr[i].SeqNo) > number) {
          number = Number(infoArr[i].SeqNo)
        }
        if(infoArr[i].ItemCode === item.Product_Code && infoArr[i].UseGroupClassification === useGroupClassification && infoArr[i].ActualFlag === "0"){
         ArrFilter.push(infoArr[i])
        }
        if (infoArr[i].ItemCode === item.Product_Code &&
          infoArr[i].OrderReceiptCode === item.SelectContractor &&
          infoArr[i].ShipmentCode === item.ShippingDestination &&
          infoArr[i].EUARBmgmtCode === item.EndUserId && infoArr[i].ActualFlag === "0" &&
          infoArr[i].UseGroupClassification === useGroupClassification) {
          infos.push(infoArr[i])
        }
      }
      let infosData = await UnitPriceScaleFun(infos,item)
      let info = infosData.UnitPriceArr
      let SalesData = infosData.SalesData
      let PurchData = infosData.PurchData
      if (info.length === 1) {
        let result = info.map(async (items1) => {
          let hh = addZero(new Date().getHours());
          let mm = addZero(new Date().getMinutes());
          let ss = addZero(new Date().getSeconds())
        const result = await API.graphql(graphqlOperation(updateArPriceInfo, {
            input: {
              id: items1.id,
              LastOrderDate: moment(new Date).utcOffset(9).format('YYYYMMDD'),
              WebOrderNumber: item.OrderDetailsNumber ? item.OrderDetailsNumber : "",
              Remarks: item.InHouseRe ? item.InHouseRe : "",
              SalespersonCode: item.Salesperson,
              UpDailyPayment: moment(new Date).utcOffset(9).format('YYYYMMDD'),
              UpTime: hh + mm + ss
            }
          }))
        });
        let res = await Promise.all(result)
      }else if(info.length === 0 && SalesData.length > 0 && PurchData.length > 0){
        let obj = {}
        obj.Sales = SalesData[0]
        obj.purch = PurchData[0]
        if (obj.Sales && obj.purch) {
          let historynum = ""
          if (obj.Sales.HistoryNum > obj.purch.HistoryNum) {
            historynum = obj.Sales.HistoryNum
          } else {
            historynum = obj.purch.HistoryNum
          }
          let RecordSalesDate = ""
          if(obj.Sales.RecordSalesDate >= obj.purch.PurchaseAccountDate){
            RecordSalesDate = obj.Sales.RecordSalesDate
          }else{
            RecordSalesDate = obj.purch.PurchaseAccountDate
          }
          let input = obj.Sales;
          input.PurchUnitPriceScale1 = obj.purch.PurchUnitPriceScale1
          input.PurchUnitPriceScale2 = obj.purch.PurchUnitPriceScale2
          input.PurchUnitPriceScale3 = obj.purch.PurchUnitPriceScale3
          input.PurchUnitPriceScale4 = obj.purch.PurchUnitPriceScale4
          input.PurchUnitPriceScale5 = obj.purch.PurchUnitPriceScale5
          input.PurchUnitPriceUnitScale1 = obj.purch.PurchUnitPriceUnitScale1
          input.PurchUnitPriceUnitScale2 = obj.purch.PurchUnitPriceUnitScale2
          input.PurchUnitPriceUnitScale3 = obj.purch.PurchUnitPriceUnitScale3
          input.PurchUnitPriceUnitScale4 = obj.purch.PurchUnitPriceUnitScale4
          input.PurchUnitPriceUnitScale5 = obj.purch.PurchUnitPriceUnitScale5
          input.RecordSalesDate = RecordSalesDate
          input.RecordSalesApplicable = obj.Sales.RecordSalesApplicable
          input.PurchaseAccountDate = RecordSalesDate
          input.AcctForPurchasesApplicableble = obj.purch.AcctForPurchasesApplicableble
          input.HistoryNum = String(Number(historynum) - 0.5)
          input.Remarks = item.InHouseRe ? item.InHouseRe : "",
          input.SalespersonCode = item.Salesperson,
          input.UseGroupClassification = useGroupClassification,
          input.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD'),
            input.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
            input.Expired = RecordSalesDate
          input.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
          await PriceTypePurchaseUp(input, ArrFilter, item.SalesGroup)
          item.EndUserIdDirectDelivery = input.TorayEndUser
          delete input["id"];
         const creatResults = await API.graphql(graphqlOperation(createArPriceInfo, {
            input: {
              ...input,
              QueryId: "P"
            }
          }));
        }
      }
    if(info.length === 0 && SalesData.length === 0 && PurchData.length === 0) {
        let info1 = [];
        for (let i = 0; i < infoArr.length; i++) {
          if (infoArr[i].id === item.Product_id && infoArr[i].UseGroupClassification === useGroupClassification && infoArr[i].ActualFlag === "0") {
            info1.push(infoArr[i])
          }
        }
        if (info1.length > 0) {
          let inputInfo = JSON.parse(JSON.stringify(info1[0]));
          inputInfo.ItemCode = item.Product_Code,
            inputInfo.ItemName = item.Product_Name_j,
            inputInfo.OrderReceiptCode = item.SelectContractor,
            inputInfo.OrderName = item.SelectContractorNm,
            inputInfo.ShipmentCode = item.ShippingDestination,
            inputInfo.DestName = item.ShippingDestinationNm,
            inputInfo.EUARBmgmtCode = item.EndUserId,
            inputInfo.EndUserName = item.EndUserNm,
            inputInfo.SalesUnitPriceScale1 = item.SalesUnitPriceScale
            inputInfo.LastOrderDate = moment(new Date).utcOffset(9).format('YYYYMMDD'),
            inputInfo.WebOrderNumber = item.OrderDetailsNumber ? item.OrderDetailsNumber : "",
            inputInfo.Remarks = item.InHouseRe ? item.InHouseRe : "",
            inputInfo.SalespersonCode = item.Salesperson
            inputInfo.UseGroupClassification = useGroupClassification
           inputInfo.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD'),
            inputInfo.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
            inputInfo.Expired = ""
          inputInfo.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
          delete inputInfo["id"];
          await PriceTypePurchaseUp(inputInfo, ArrFilter, item.SalesGroup)
          item.EndUserIdDirectDelivery = inputInfo.TorayEndUser
          const creatResults = await API.graphql(graphqlOperation(createArPriceInfo, {
            input: {
              ...inputInfo,
              QueryId: "P"
            }
          }));
        }
      }
    }
  }
    if(userAuths !== "4" && userAuths !== "3" && userAuths !== "2"){
      await API.graphql(
        graphqlOperation(updateArOrder, {
          input: {
            id: orderID,
            ShippingBlock: ShippingBlock,
            QuoteNumber: searchQuoteNumber,
            OutputFlag: OutputFlag
          },
        })
      );
    }
    item.OutputFlag = OutputFlag;
    if(userAuths !== "4" && userAuths !== "3" && userAuths !== "2"){
      const result = await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
        input: {
          id: item.id,
          QuoteNumber: item.QuoteNumber,
          Agency_id: item.Agency_id,
          ShippingBlock: ShippingBlock,
          OutputFlag: OutputFlag,
          Product_Code: item.Product_Code,
          delflg:electronicMaterialsDepartment === true ? (checkedC === true ? "Z2" : "") : "",
          EndUserIdDirectDelivery:item.EndUserIdDirectDelivery ? item.EndUserIdDirectDelivery : "",
          User_Name:userName,
          AssigneeCode:assigneeCode,
          CompanMemo:item.CompanMemo
        }
      }))
    }else{
    const result = await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
      input: {
        id: item.id,
        QuoteNumber: item.QuoteNumber,
        Agency_id: item.Agency_id,
        ShippingBlock: ShippingBlock,
        OutputFlag: OutputFlag,
        Product_Code: item.Product_Code,
        delflg:electronicMaterialsDepartment === true ? (checkedC === true ? "Z2" : "") : "",
        EndUserIdDirectDelivery:item.EndUserIdDirectDelivery ? item.EndUserIdDirectDelivery : ""
      }
    }))
  }
  }catch(error){
    if(error.message === 'No current user'){
      // ユーザープールに障害が発生した場合
      console.log('User pool is invalid.Please reauthenticate.');
      // ログイン画面に戻る
      signOut();
      }else {
      // 他のエラーを処理する
      console.log('An error occurred:',error);
      }
   }finally{
    setSearchProgress(false)
   }
    orderinfo[index] = item;
    setOrderinfo(orderinfo);
    history.push({ pathname: "/OrderList" });
  }
  function sendEmail(bodyContent, type) {
    const body = JSON.stringify(bodyContent);
    const requestOptions = {
      method: "POST",
      body,
    };

    fetch(global.ArGlob.EmailEndPoint, requestOptions)
      .then((response) => {
        console.log("response", response);
        if (response.ok) {
          setToast({
            open: false,
            message: "send " + type + " Email success",
            severity: "success",
          });
          setToast({
            open: true,
            message: "send " + type + " Email success",
            severity: "success",
          });
        } else {
          setToast(
            {
              open: false,
              message: "send " + type + " Email error",
              severity: "error",
            },
            {
              open: true,
              message: "send " + type + " Email error",
              severity: "error",
            }
          );
        }
        return response.json();
      })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log("失敗", error);
      });
  }
  const signOut = async () => {
    await Auth.signOut();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    document.location.reload();
  };
  const updateArOrders = async () => {
    if(userAuths !== "4" && userAuths !== "2" && userAuths !== "3"){
    await API.graphql(
      graphqlOperation(updateArOrder, {
        input: {
          id: orderID,
          ShippingBlock: "Z1",
          OrderStatus: 3,
          QuoteNumber: searchQuoteNumber,
        },
      })
    );
  }
    for (let j = 0; j < orderinfo.length; j++) {
      const item = orderinfo[j];
      try{
      const result = await API.graphql(
        graphqlOperation(updateArAgencyOrderInfo, {
          input: {
            id: item.id,
            Agency_id: item.Agency_id,
            QuoteNumber: item.QuoteNumber,
            Product_Code: item.Product_Code,
            ShippingBlock: "Z1",
            OrderStatus: 3,
            RegistrationDate: new Date(),
          },
        })
      );
    }catch(error){
      if(error.message === 'No current user'){
        // ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        // ログイン画面に戻る
        signOut();
        }else {
        // 他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
    }
    if(userAuths === "4" || userAuths === "3" || userAuths === "2"){
      history.push({ pathname: "/InventorySearchEmd"});
    }else{
      history.push({ pathname: "/InventorySearch" });
    }
  };
  function moneytonum(val) {
    let num = val ? val.trim() : "";
    let ss = num.toString();
    if (ss.length === 0) {
      return "0";
    }
    return ss.replace(/,/g, "");
  }
  function numtomoney(number, toFixed) {
    let str = [];
    if (!number) return 0;
    if (typeof number === "number") number = String(number);
    let tempNumber = number.split(".");
    number = tempNumber[0];
    if (number.length <= 3) {
      if (tempNumber[1] && toFixed !== 0) {
        number += "." + tempNumber[1].slice(0, toFixed);
      }
      return number;
    }
    number
      .split("")
      .reverse()
      .forEach((item, index) => {
        if (index !== 0 && index % 3 === 0) {
          str.push(",", item);
        } else {
          str.push(item);
        }
      });
    number = str.reverse().join("");
    if (tempNumber[1] && toFixed !== 0) {
      number += "." + tempNumber[1].slice(0, toFixed);
    }
    return number;
  }
  const handleSetMax = (id, Quantity, Object) => {
    let idTemp = document.getElementById(id);
    if (Object <= 0) {
      idTemp.value = 0;
      setQ(id, 0);
      return;
    }
    // if (Object > Quantity) {
    //   idTemp.value = Quantity;
    //   setQ(id, Quantity);
    //   return;
    // }
    setQ(id, idTemp.value);
  };
  function setQ(id, num) {
    for (let j = 0; j < orderinfo.length; j++) {
      if (orderinfo[j].id === id) {
        orderinfo[j].OrderQuantity = num;
        break;
      }
    }
  }
  const [flg, setInvoiceSubtotal] = React.useState(Object);
  const handleSetflg = (Object) => {
    setInvoiceSubtotal(Object);
  };
  const roundingUp = function(num){
    let str = num.toString()
    let pointNum = str.split(".")[1] ? str.split(".")[1][0] : 0
    if(pointNum < 5 && pointNum > 0){
      return parseInt(num + 1)
    }else{
      return parseInt(num)
    }
  }
  const handleSetCount = (row, Object, Quantity) => {
    if (Object < 0) {
      return;
    }
    if (Object > Quantity) {
      // return;
    }
    for (let j = 0; j < orderinfo.length; j++) {
      if (orderinfo[j].id === row.id) {
        orderinfo[j].OrderQuantity = Object;
        break;
      }
    }
    let postageSum = 0;
    for (let j = 0; j < orderinfo.length; j++) {
      if (
        parseFloat(orderinfo[j].OrderQuantity) *
        parseFloat(moneytonum(orderinfo[j].Wholes_Price)) > 0 &&
        parseFloat(orderinfo[j].OrderQuantity) *
        parseFloat(moneytonum(orderinfo[j].Wholes_Price)) <=
        7000
      ) {
        postageSum += 1200;
      }
    }
    let sum = 0;
    for (let j = 0; j < orderinfo.length; j++) {
      sum =
        sum +
        parseFloat(orderinfo[j].OrderQuantity) *
        parseFloat(moneytonum(orderinfo[j].Wholes_Price));
    }
    invoiceSubtotal = numtomoney(sum, 0);
        invoiceFax = numtomoney(Math.round((sum + postageSum) * TAX_RATE), 0);
        invoiceTotal = numtomoney(postageSum, 0);
        invoicePayTotal = numtomoney(
          Math.round(sum + (sum + postageSum) * 0 + postageSum),
          0
        );
    handleSetflg(invoicePayTotal);
  };

  function txtChangedOrderData(e) {
    setOrderData(e.target.value);
  }
  const [electronicMaterialsDepartment,setElectronicMaterialsDepartment] = useState(false)
  const location = useLocation();
  useEffect(() => {
    const { QuoteNumber, OrderDetailsNumber } = qs.parse(location.search.replace(/^\?/, ''));
    searchQuoteNumber = QuoteNumber;
    searchDetailsNumber = OrderDetailsNumber;
    if (location.state === undefined && QuoteNumber === '') {
      history.push({ pathname: "/" });
      return;
    }
    if (location.state !== undefined) {
      searchQuoteNumber = location.state.QuoteNumber;
      searchDetailsNumber = location.state.OrderDetailsNumber;
    }
    async function listInvData() {
    setSearchProgress(true)
       userAuths = await userAuth();
       useGroupClassification = userAuths
      let listArAgencyOrderInfosArr = []
      let QuoteNumber = searchQuoteNumber;
      let listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfos, { QuoteNumber: QuoteNumber }));
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos.items.length === 0) {
          alert("web登録番号が存在しません");
          history.push({ pathname: "/" });
          return;
        }
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
          listArAgencyOrderInfosArr = listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];}

      handleSetMitumoriNoText(QuoteNumber);
      if(listArAgencyOrderInfosArr.length > 0 && listArAgencyOrderInfosArr[0].UserAuth !== "2"){
        const orderByQuoteNumberIngo = await API.graphql(
          graphqlOperation(orderByQuoteNumber, { QuoteNumber: QuoteNumber })
        );
        if (orderByQuoteNumberIngo.data.orderByQuoteNumber.items.length === 0) {
          alert("web登録番号が存在しません");
          history.push({ pathname: "/" });
          return;
        }
        if (orderByQuoteNumberIngo.data.orderByQuoteNumber !== null) {
          const orderInfo =
            orderByQuoteNumberIngo.data.orderByQuoteNumber["items"];
          if (orderInfo.length > 0) {
            Status = orderInfo[0].OrderStatus;
            OutputFlgTemp = orderInfo[0].OutputFlag;
            if (userAuths === "1") {
              if (Status === "2") {
                //handleSetAuthFlg(false);
              } else {
                //handleSetAuthFlg(true);
              }
              if (Status !== "1" && Status !== "2") {
                setTreated(true);
              } else { setTreated(false); }
            }else if(userAuths === "4" || userAuths === "3" || userAuths === "2"){
              if (Status !== "1" && Status !== "2") {
                if (OutputFlgTemp !== "" && OutputFlgTemp !== null) {
                  //true
                  setTreated(true);
                } else {
                  setTreated(false);
                }
              setOrderApprovalFlag(true);
              } else {
                setTreated(false);
              }
              //判断電子材料部門
              setElectronicMaterialsDepartment(true)
            } else {
              // handleSetAuthFlg(true);
              if (OutputFlgTemp !== "" && OutputFlgTemp !== null) {
                //true
                setTreated(true);
              } else {
                setTreated(false);
              }
              setOrderApprovalFlag(true);
              setOrderlifeApprovalFlag(true);
            }
            orderID = orderInfo[0].id;
            AgencyEmails = [];
            AgencyEmails.push(orderInfo[0].AgencyEmail)
            if (
              orderInfo[0].RegistrationDate !== null &&
              orderInfo[0].RegistrationDate !== ""
            ) {
              orderInfo[0].RegistrationDate = moment(
                orderInfo[0].RegistrationDate
              )
                .utcOffset(9)
                .format("YYYY/MM/DD");
            }
            if (
              orderInfo[0].DesiredDeliveryDate !== null &&
              orderInfo[0].DesiredDeliveryDate !== ""
            ) {
              orderInfo[0].DesiredDeliveryDate = moment(
                orderInfo[0].DesiredDeliveryDate
              )
                .utcOffset(9)
                .format("YYYY-MM-DD");
            }
            historyDate = orderInfo[0].DesiredDeliveryDate;
            setOrderData(orderInfo[0].DesiredDeliveryDate);
            setOrderaddressinfo(orderInfo[0]);
          }
        }
      }else if(listArAgencyOrderInfosArr.length > 0 && listArAgencyOrderInfosArr[0].UserAuth === "2"){
        let listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfos, { QuoteNumber: QuoteNumber }));
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos.items.length === 0) {
          alert("web登録番号が存在しません");
          history.push({ pathname: "/" });
          return;
        }
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
         const orderInfo = listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];
          if (orderInfo.length > 0) {
            Status = orderInfo[0].OrderStatus;
            OutputFlgTemp = orderInfo[0].OutputFlag;
            if (userAuths === "1") {
              if (Status === "2") {
                //handleSetAuthFlg(false);
              } else {
                //handleSetAuthFlg(true);
              }
              if (Status !== "1" && Status !== "2") {
                setTreated(true);
              } else { setTreated(false); }
            }else if(userAuths === "4" || userAuths === "3" || userAuths === "2"){
              if (Status !== "1" && Status !== "2") {
                if (OutputFlgTemp !== "" && OutputFlgTemp !== null) {
                  //true
                  setTreated(true);
                } else {
                  setTreated(false);
                }
              setOrderApprovalFlag(true);
              } else {
                setTreated(false);
              }
              //判断電子材料部門
              setElectronicMaterialsDepartment(true)
            } else {
              // handleSetAuthFlg(true);
              if (OutputFlgTemp !== "" && OutputFlgTemp !== null) {
                //true
                setTreated(true);
              } else {
                setTreated(false);
              }
              setOrderApprovalFlag(true);
              setOrderlifeApprovalFlag(true);
            }
            orderID = orderInfo[0].id;
            AgencyEmails = [];
            AgencyEmails.push(orderInfo[0].AgencyEmail)
            if (
              orderInfo[0].RegistrationDate !== null &&
              orderInfo[0].RegistrationDate !== ""
            ) {
              orderInfo[0].RegistrationDate = moment(
                orderInfo[0].RegistrationDate
              )
                .utcOffset(9)
                .format("YYYY/MM/DD");
            }
            if (
              orderInfo[0].DesiredDeliveryDate !== null &&
              orderInfo[0].DesiredDeliveryDate !== ""
            ) {
              orderInfo[0].DesiredDeliveryDate = moment(
                orderInfo[0].DesiredDeliveryDate
              )
                .utcOffset(9)
                .format("YYYY-MM-DD");
            }
            historyDate = orderInfo[0].DesiredDeliveryDate;
            setOrderData(orderInfo[0].DesiredDeliveryDate);
            setOrderaddressinfo(orderInfo[0]);
          }
        }
      }
      let order = null;
      if (searchDetailsNumber) {
        const listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfoByDetailsNumber, { OrderDetailsNumber: searchDetailsNumber }));
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber !== null) {
          order =
            listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber["items"];
        }
      } else {
        const listArAgencyOrderInfo = await API.graphql(
          graphqlOperation(listArAgencyOrderInfos, { QuoteNumber: QuoteNumber })
        );
        if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
          order = listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];
        }
      }
      if (order) {
        if(order.length > 0){
          const user = await Auth.currentUserInfo();
         const agencyPriceGroup = await API.graphql(
           graphqlOperation(customerByeMail, {
             Agency_Email: user.attributes.email,
           })
         );
         const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
         if (agentinfo.length > 0) {
          userName = agentinfo[0].User_Name;
          assigneeCode = agentinfo[0].AssigneeCode;
           usageSystem = agentinfo[0].UsageSystem
         }
          setOrderaddressinfo(order[0])
        }
        for (let i = 0; i < order.length - 1; i++) {
          for (let j = 0; j < order.length - 1 - i; j++) {
            if (order[j].OrderDetailsNumber > order[j + 1].OrderDetailsNumber) {
              let temp = order[j];
              order[j] = order[j + 1];
              order[j + 1] = temp;
            }
          }
        }
        for (let item in order) {
          if (order[item].ShippingBlock === "" && order[item].OutputFlag === "0") {
            order[item].checkedA = true;
            order[item].checkedB = true;
          }
          else if (order[item].ShippingBlock === "" && order[item].OutputFlag === "1") {
            order[item].checkedA = true;
            order[item].checkedB = false;
          }
          else if (order[item].ShippingBlock === "Z1" && order[item].OutputFlag === "0") {
            order[item].checkedA = false;
            order[item].checkedB = true;
          }
          if(order[item].delflg === 'Z2'){
            order[item].checkedC = true;
            setStateC(true)
          }

          let historyItem = {};
          historyItem.id = order[item].id;
          historyItem.OrderQuantity = order[item].OrderQuantity;
          histotyOrder.push(historyItem);
        }
        invoiceFax = 0
        invoicePayTotal = 0
        invoiceSubtotal = 0
        CampaignPrice = 0
        quantityPrice = 0
        invoiceTotal = 0
        for(let i = 0;i < order.length;i++){
          if(userAuths !== "4" && userAuths !== "3" && userAuths !== "2"){
            if(order[i].UserAuth !== "1"){
              disableBtn = true
              remandBtn = false
            }else{
              disableBtn = false
              remandBtn = false
            }
          }else{
            if(order[i].UserAuth !== "2"){
              disableBtn = true
              remandBtn = false
            }else{
              disableBtn = false
              remandBtn = true
            }
          }
          invoiceFax += Number(moneytonum(order[i].invoiceFax));
          invoicePayTotal += Number(moneytonum(order[i].invoicePayTotal))
          invoiceSubtotal += Number(moneytonum(order[i].invoiceSubtotal))
          CampaignPrice += Number(moneytonum(order[i].campaignPriceTotal))
          quantityPrice += Number(moneytonum(order[i].quantityPriceTotal))
          invoiceTotal += Number(moneytonum(order[i].invoiceTotal))
        }
        CampaignPrice = roundingUp(CampaignPrice)
        quantityPrice = roundingUp(quantityPrice)
        invoicePayTotal = Math.round(invoicePayTotal)
        invoiceSubtotal = Math.round(invoiceSubtotal)
        invoiceFax = Math.round(invoiceFax)
        setOrderinfo(order);
      }
      setSearchProgress(false)
    }
    listInvData();
  }, []);
  const goback = ()=>{
    if(userAuths !== "4" && userAuths !== "3" && userAuths !== "2"){
      if(searchDetailsNumber){
        history.push({pathname: "/order",state: {QuoteNumber:searchQuoteNumber,OrderDetailsNumber:searchDetailsNumber}})
        return;
      }
      if(searchQuoteNumber){
        history.push({pathname: "/order",state: { QuoteNumber:searchQuoteNumber}})
        return;
      }
  }else{
    if(searchDetailsNumber){
      history.push({pathname: "/orderEmd",state: {QuoteNumber:searchQuoteNumber,OrderDetailsNumber:searchDetailsNumber}})
      return;
    }
    if(searchQuoteNumber){
      history.push({pathname: "/orderEmd",state: { QuoteNumber:searchQuoteNumber}})
      return;
    }
  }
}
  return (
    <Container
      style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
    >
      <Row>
        <h3>注文確定</h3>
      </Row>
      <Grid style={{ backgroundColor: "white", padding: "1em", border: '3px solid #804F29' }}>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text className={classes.textlabel} id="basic-name">
                登録番号
              </InputGroup.Text>
              <FormControl
                autocomplate="off"
                placeholder="納品請求書の宛名にご指定がある場合ご入力下さい"
                aria-label=""
                aria-describedby="basic-name"
                id="mitumoriName"
                className={classes.textField}
                value={mitumoriNoText}
                readOnly={true}
                maxLength="20"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row >
              <Col>
                <Row>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        className={classes.textlabel}
                        id="basic-name"
                      >
                        発注先名
                      </InputGroup.Text>
                      <FormControl
                        aria-label=""
                        aria-describedby="basic-name"
                        id="Name1Dialog"
                        className={classes.textField}
                        value={orderaddressinfo.UserAuth === "1" ? orderaddressinfo.ContractorNm + (orderaddressinfo.GroupName !== null && orderaddressinfo.GroupName !== undefined && orderaddressinfo.GroupName ? " " + orderaddressinfo.GroupName : "") : orderaddressinfo.ContractorNm}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        className={classes.textlabel}
                        id="basic-name"
                      >担当者名
                      </InputGroup.Text>
                      <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="Name1Dialog"
                        className={classes.textField}
                        value={orderaddressinfo.OrderChargeName}
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                </Row>

              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
      <Grid style={{ marginTop: '30px', backgroundColor: "white" }} id="pdf-oder">
        {
          orderinfo.map((item, index) => {
            return (
              <Row key={item.id} style={{ padding: '1em' }}>
                <Col xs={orderApprovalFlag ? 10 : 12} style={{ padding: '0' }}>
                  <TableContainer key={item.id} >
                    <Table style={{ border: '3px solid #804F29' }}>
                      <TableHead>
                        <TableRow style={{ border: '1px dashed #804F29' }}>
                          <TableCell style={{ border: '1px dashed #804F29', width: '15%' }} align="center">品目</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="center" >品名</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="center" >数量</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="center" >価格</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{ borderBottom: '3px solid #804F29' }}>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="center" >{item.Product_Code}</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }}>{item.Product_Name_j}</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="right" >{item.OrderQuantity}</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} align="right" >{numtomoney(moneytonum(item.Wholes_Price))}</TableCell>
                        </TableRow>
                        <TableRow style={{ border: '1px dashed #804F29' }}>
                          <TableCell style={{ border: '1px dashed #804F29' }}>受注番号</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>{item.OrderDetailsNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{ border: '1px dashed #804F29' }}>発注先注文番号</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }}>{item.OrderNumber}</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }}>発注先備考</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }}>{item.OrderRemarks}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: '1px dashed #804F29' }}>出荷先</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                          <div style={{ display: 'flex' }}> <span style={{ minWidth: '30%' }}>{item.ShippingDestinationNm}</span>
                              <span >出荷先担当者</span>
                              <span style={{ marginLeft: '50px' }}>{item.OrderShipContactNm}</span></div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: '1px dashed #804F29' }}>出荷先住所</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                            <div style={{ display: 'flex' }}>
                              <span style={{ minWidth: '50%' }}> {item.OrderAddress}</span>
                              <span style={{ marginLeft: '100px' }}> 電話番号</span>
                              <span style={{ minWidth: '20%', marginLeft: '50px' }}> {item.OrderTelNo}</span>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: '1px dashed #804F29' }}> 希望納期</TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                            {moment(item.DesiredDeliveryDate).format("YYYY/MM/DD")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: '1px dashed #804F29' }}>
                            出荷先注文番号
                          </TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                            <div style={{ display: 'flex' }}>
                              <span style={{ minWidth: '30%' }}> {item.ShippingOrderNumber}</span>
                              <span >  出荷備考</span>
                              <span style={{ minWidth: '20%', marginLeft: '50px' }}> {item.OrderShippingNotes}</span>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ border: '1px dashed #804F29' }}>
                          <TableCell style={{ border: '1px dashed #804F29' }}>
                            エンドユーザー
                          </TableCell>
                          <TableCell style={{ border: '1px dashed #804F29' }} colSpan={3}>
                            {item.EndUserNm}
                          </TableCell>
                        </TableRow>
                        {orderlifeApprovalFlag && (<TableRow style={{ border: '1px dashed #804F29' }}>
                        <TableCell style={{ border: '1px dashed #804F29' }}>社内メモ</TableCell>
                        <TableCell colSpan={3}><FormControl
                          autocomplate="off"
                          placeholder=""
                          aria-label=""
                          aria-describedby="basic-name"
                          id="CompanMemo"
                          className={classes.textMemoField}
                          style={{ width: '100%' }}
                          value={item.CompanMemo}
                          onChange={(e) => {
                            handleCompanMemoChange(e, item);
                          }}
                          maxLength="50"
                        /></TableCell>
                      </TableRow>
                      )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col >
                <Col xs style={{ width: '100px',border: '3px solid #804F29' ,borderLeftWidth:'0'}} hidden={!orderApprovalFlag}>
                  <div style={{ position: 'relative', bottom: '0', height: '100%' ,}}>
                    <div style={{ position: 'absolute', bottom: '100px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checkedA}
                            onChange={(e) => {
                              item.checkedA = e.target.checked;
                              setStateA(!checkedA);
                              // handleChangeA(e)
                            }}
                            name="checkedA"
                            color="primary"
                            disabled={item.OutputFlag === "0"}
                          />
                        }
                        label="出荷ブロック解除"
                      />
                       <FormControlLabel style={{display:userAuths !== "1" ? "block" : "none"}}
                        control={
                          <Checkbox
                          checked={item.checkedC}
                            name="checkedC"
                            color="primary"
                            onChange={(e) => {
                              item.checkedC = e.target.checked;
                              setStateC(!checkedC);
                              // handleChangeC(e)
                            }}
                          />
                        }
                        label="拒否理由(みなし)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checkedB}
                            onChange={(e) => {
                              item.checkedB = e.target.checked;
                              handleChangeB(e)
                            }}
                            name="checkedB"
                            color="primary"
                            disabled={item.OutputFlag === "0"}
                          />
                        }
                        label="受注確認完了"
                      />
                      <div style={{textAlign:'center',}}>
                        <Button
                          variant="contained" color="primary"
                          style={{width:"90px"}}
                          onClick={() => {
                            item.OutputFlag = "0";
                            orderApprovalClick(item, index);
                            setStateA(!checkedA);
                          }}
                          disabled={disableBtn === true ? true : item.OutputFlag === "0"}
                        >
                          承認
                        </Button>
                        <Button
                          variant="contained" color="primary"
                          onClick={()=>{
                            remand(item)
                          }}
                          style={{display:remandBtn === true ? "block" : "none",width:"90px",margin:"4px auto",whiteSpace:"nowrap"}}>
                          差し戻し
                        </Button>
                      </div>
                    </div>

                  </div>
                </Col>
              </Row>
            )
          })
        }
      </Grid>
      <Row style={{ marginBottom: "2rem", marginTop: '30px',padding:'1em' , backgroundColor: "white",}}>
        <Col
          style={{
            padding: "1rem",
          }}
        >
       {orderinfo.length > 0 && orderinfo[0].CreateFrom ? <div>
        <TableContainer>
        <TableBody >
          <TableRow >
            <TableCell className={classes.noframeTableCell}>指定LOTNo</TableCell>
            <TableCell className={classes.noframeTableCell} >：{orderinfo[0]['f_SpecifiedLotNo']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>同一Lot区分</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_SameLotClassification'] === '1' ? '同一LOT指定' : orderinfo[0]['f_SameLotClassification']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>指定納期</TableCell>
            <TableCell className={classes.noframeTableCell}>：{moment(orderinfo[0].DesiredDeliveryDate).format("YYYY-MM-DD")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>特価管理No</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_SpecialpPriceManagementNo']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>発注備考</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_OrderNotes']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>連絡事項</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_Notifications']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先郵便番号</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryPostCode']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先住所</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DropShippingAddress']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先電話番号</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryTel']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先FAX番号</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryFax']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先受取人</TableCell>
            <TableCell className={classes.noframeTableCell}> ：{orderinfo[0]['f_DropShippingRecipient']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noframeTableCell}>直送先名</TableCell>
            <TableCell className={classes.noframeTableCell}>：{orderinfo[0]['f_DirectDeliveryName']}</TableCell>
          </TableRow>

        </TableBody>

        </TableContainer>

        {/* <p>指定LOTNo    :{orderinfo[0]['f_SpecifiedLotNo']}</p>
        <p>同一Lot区分  :{orderinfo[0]['f_SameLotClassification'] === '1' ? '同一LOT指定' : orderinfo[0]['f_SameLotClassification']}</p>
        <p>指定納期     ：{moment(orderinfo[0].DesiredDeliveryDate).format("YYYY-MM-DD")}</p>
        <p>特価管理No   ：{orderinfo[0]['f_SpecialpPriceManagementNo']}</p>
        <p>発注備考     ：{orderinfo[0]['f_OrderNotes']}</p>
        <p>連絡事項     ：{orderinfo[0]['f_Notifications']}</p>
        <p>直送先郵便番号：{orderinfo[0]['f_DirectDeliveryPostCode']}</p>
        <p>直送先住所   ：{orderinfo[0]['f_DropShippingAddress']}</p>
        <p>直送先電話番号：{orderinfo[0]['f_DirectDeliveryTel']}</p>
        <p>直送先FAX番号：{orderinfo[0]['f_DirectDeliveryFax']}</p>
        <p>直送先受取人 ：{orderinfo[0]['f_DropShippingRecipient']}</p>
        <p>直送先名     ：{orderinfo[0]['f_DirectDeliveryName']}</p> */}
       </div> : <></>}

      </Col>

        <Col>


          <TableContainer>
            <Table className={classes.table} aria-label="spanning table">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                  >
                    製品合計（税抜）
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                    }}
                    colSpan={2}
                    align="right"
                  >
                    {numtomoney(invoiceSubtotal)}円
                  </TableCell>
                </TableRow>
                <TableRow style={{display:CampaignPrice !== 0 ? "table-row" : "none"}}>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                       キャンペーン特価
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          color:"red"
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {CampaignPrice !== 0 ? "-" + numtomoney(CampaignPrice) : CampaignPrice}円
                      </TableCell>
                    </TableRow>
                    <TableRow style={{display:quantityPrice !== 0 ? "table-row" : "none"}}>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                      数量特価
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "1px dashed gray",
                          color:"red"
                        }}
                        colSpan={2}
                        align="right"
                      >
                        {quantityPrice !== 0 ? "-" + numtomoney(quantityPrice) : quantityPrice}円
                      </TableCell>
                    </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                  >
                    送料／手数料（税抜）
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                    }}
                    colSpan={2}
                    align="right"
                  >
                    {numtomoney(invoiceTotal)}円
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                  >
                    消費税（10%）
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px dashed gray",
                    }}
                    colSpan={2}
                    align="right"
                  >
                    {numtomoney(invoiceFax)}円
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "1px solid gray",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                  >
                    注文合計（税抜）
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px solid gray",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                    align="right"
                  >
                    {numtomoney(invoicePayTotal)}円
                  </TableCell>
                </TableRow>
                <TableRow>
                      <TableCell
                        style={{
                          borderBottom: "none",
                        }}
                        colSpan={4}
                      >
                        消費税については請求書にてご確認ください。
                      </TableCell>
                    </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      <div
        className={classes.rootl}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "1em",
        }}
      >
        <div className={classes.card1}>
          <Row>
            {" "}
            <Col></Col>
            <Col align="left" style={{ width: 560 }}>
              {orderApprovalFlag === true ? (
                <div>
                </div>
              ) : (
                <div style={{ backgroundColor: "white" }}>
                  <Button
                    size="large"
                    // hidden={displayFlg}
                    className="btn btn-primary"
                    style={{ float: "right", width: "100px" }}
                    onClick={() => {
                      orderClick();
                      // downloadPdf();
                    }}
                    disabled={treated}
                  >
                    注文確定
                  </Button>
                  <Button
                    // hidden={displayFlg}
                    className="btn btn-dark"
                    size="large"
                    style={{
                      float: "right",
                      marginRight: "1rem",
                      color: 'white',
                    }}
                    onClick={
                      () =>goback()
                    }
                  >
                    注文登録へ戻る
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div hidden={flg}></div>
      <ArToast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        handleClose={() => {
          toast.open = false;
          setToast(toast);
        }}
      />
      <Dialog onClose={handleConfirmClose} aria-labelledby="customized-dialog-title" open={openConfirm} PaperProps={{
        style: {
          backgroundColor: '#FFCC00',
          boxShadow: 'none',
        },
      }}>
        <DialogContent dividers>
          <Typography gutterBottom variant="h6" className={classes.textFieldDialog}>
            ご注文ありがとうございました。
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.textFieldDialog}>
            ARBOS登録番号 {searchQuoteNumber}
          </Typography>
          <Typography gutterBottom className={classes.textField2Dialog}>
            注文受付後に、商品担当部署ごとにARBOS登録番号が
          </Typography>
          <Typography gutterBottom className={classes.textField2Dialog}>
            別の番号へ振分けられる可能性がございます。
          </Typography>
          <Typography gutterBottom className={classes.textField2Dialog}>
            注文一覧にてご確認をお願い致します。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            handleConfirmOK();
          }} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{position: 'fixed',top: '50%', left: '50%', display: searchProgress === true ? "block" : "none" }}>
            <CircularProgress style={{margin:"auto"}}/>
      </div>
    </Container >
  );
};

export default OrderConfirmation;
